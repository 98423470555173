const { default: styled } = require("styled-components");
const { Box, Grid, Button, Flex } = require("ui");

const FlexStyled = styled(Grid)`
  grid-template-columns: 40px 5fr 3fr;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding: 15px 20px;
  margin: 0;
  border-bottom: 2px solid ${(p) => p.theme.color.gray200};
  width: 100%;
  @media only screen and (max-width: 768px) {
    grid-template-columns: 40px auto;
    grid-template-rows:auto auto;
    justify-content: flex-start;
    };
`;
  const SecondRowItem = styled.div`
    @media only screen and (max-width: 768px) {
    margin-left: 50px;
    grid-column-start: 1;
    grid-column-end: 3;

    }
  `;
const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;

  p:first-of-type {
    font-size: 16px;
    font-weight: 600;
    color: ${(props) => props.theme.color.black};
    line-height: 24px;
  }
  p:nth-of-type(2) {
    font-size: 12px;
    color: ${(props) => props.theme.color.black};
    line-height: 20px;
  }
`;
const AlignedButton = styled(Button)`
  align-self: flex-end;
  width: fit-content;
  margin: 20px;
`;
const HeadingFlex = styled(Flex)`
  gap: 20px;
  background-color: ${(props) => props.theme.color.primary + "11"};
  border-radius: 6px;
  padding: 15px;
  margin: 20px 0px;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
`;
const IconBakground = styled(Flex)`
  height: 45px;
  width: 50px;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.color.primary + "22"};
  border-radius: 4px;
`;

export {
    FlexStyled,
    StyledBox,
    AlignedButton,
    HeadingFlex,
    IconBakground,
    SecondRowItem
  };