import { Type } from "ui";
import { FlexStyled, StyledBox } from "../../../Components";

function Hcaptcha() {
  return (
    <>
      <FlexStyled>
        <span />
        <StyledBox>
          <Type>hCaptcha sitekey</Type>
          <Type variant="subtitle">
            Paste your hCaptcha Secret Key to protect your form
          </Type>
        </StyledBox>
      </FlexStyled>
      <FlexStyled>
        <span />
        <StyledBox>
          <Type>hCaptcha secret</Type>
          <Type variant="subtitle">
            The domain your form is on. Example: www.formhnndes.com
          </Type>
        </StyledBox>
      </FlexStyled>
    </>
  );
}
export default Hcaptcha;
