import styled, { css } from "styled-components";

const Input = styled.input`
  outline: ${(props) => props.theme.color.gray300} 1px solid;
  background-color: ${(props) => props.theme.color.transparent};
  border-radius: 4px;
  height: 52px;
  padding: 0 15px 0 15px;
  font-size: 20px;
  ::placeholder {
    color: ${(p) => p.theme.color.gray500};
    font-size: 20px;
  }
  ${(props) => {
    if (props.$size === "medium")
      return css`
        height: 38px;
        padding: ${(props) => (props.prefix ? "0 70px" : "0 5px 0 10px")};
        font-size: 16px;
        &::placeholder {
          font-size: 16px;
          color: ${(p) => p.theme.color.gray500};
        }
      `;
    else if (props.$size === "small")
      return css`
        height: 34px;
        padding: ${(props) => (props.prefix ? "0 70px" : "0 5px 0 14px")};
        font-size: 14px;
        &::placeholder {
          font-size: 14px;
          color: ${(p) => p.theme.color.gray500};
        }
      `;
  }}
  width: 100%;
  border: none;
  color: ${(p) => p.theme.color.gray800};
  transition: all 0.15s cubic-bezier(0.4, 0, 1, 1);
  &:focus,
  &:active {
    outline: ${(p) => p.theme.color.darkBlue} 1px solid;
    background-color: ${(p) => p.theme.color.transparent};
  }
  &:read-only,
  &:disabled {
    outline: ${(p) => p.theme.color.gray200} 1px solid;
    background-color: ${(p) => p.theme.color.primary}0d;
  }
  ${(props) =>
    props.type === "textarea" &&
    css`
      height: 100px;
      padding: 15px;
      resize: none;
      font-size: 14px;
      &::placeholder {
      font-size: 14px;
      color: ${(p) => p.theme.color.gray500};
    }
    `}
`;

export default Input;
