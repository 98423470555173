import React from "react";
import { Flex, Type } from "ui";
import googleIcon from "../../assests/google_icon.svg";
// import facebookIcon from "../../assests/facebook_icon.svg";
function ConnectedAccounts() {
  return (
    <>
      <Type var="h4" sx={{ mb: 30 }}>
        Connected Accounts
      </Type>

      <Flex gap={20} jc="flex-start" sx={{dir:["_","_","column","_"]}}>
        <Flex
          gap={10}
          style={{
            padding: "10px",
            backgroundColor: "#F4F7FE",
            width: "100%",
            borderRadius: "5px",
          }}
        >
          <img
            style={{ width: "20px", height: "20px" }}
            src={googleIcon}
            alt=""
          />

          <Type var="h5">Continue With Google</Type>
        </Flex>
        {/* <Flex
          gap={7}
          style={{
            padding: "10px",
            backgroundColor: "#F4F7FE",
            width: "100%",
            borderRadius: "5px",
          }}
        >
          <img
            style={{ width: "20px", height: "20px" }}
            src={facebookIcon}
            alt=""
          />
          <Type var="h5">Continue With Facebook</Type>
        </Flex> */}
      </Flex>
    </>
  );
}

export default ConnectedAccounts;
