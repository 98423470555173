var apiUrl;

if (process.env.REACT_APP_ENV === "production")
  apiUrl = "https://api.forms-easy.com";
else if (process.env.REACT_APP_ENV === "sandbox")
  apiUrl = "https://api.forms-easy.com";
else {
  apiUrl = "https://api.forms-easy.com";
}

const GOOGLE_APT_KEY = "xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx";


export { apiUrl, GOOGLE_APT_KEY };
