import Layout from "layout/Layout";
import { Outlet } from "react-router-dom";
import { Box, Flex, Icon, Type } from "ui";

export default function Api() {
  return (
    <Layout>
      <Box sx={{ px: [0, 0, 0, 10] }}>
        <Box sx={{ my: 30 }}>
          {/* <Icon name="code" w={28} circle c="gray500" /> */}
          <Type var="h4">API Keys</Type>
          <Type>Create credentials to access your APIs.</Type>
        </Box>
        <Outlet />
      </Box>
    </Layout>
  );
}
