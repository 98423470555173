import React, { useEffect, useState } from "react";

import { Box } from "ui";
import { ExpandedContent } from "./Components";
import GeneralSettings from "./GeneralSettings";
import SpamProtection from "./SpamProtection";
import Security from "./Security";
import Recaptcha from "./Recaptcha";
import Hcaptcha from "./Hcaptcha";
import Processing from "./Processing";
import DangerZone from "./DangerZone";
import Heading from "./Heading";
import { useSubmissionForm } from "../provider";
import { useUtils } from "services/utilsProvider";
import { useParams } from "react-router-dom";
import { useProject } from "views/project/provider";

const data = [
  {
    index: 1,
    iconName: "Gear",
    title: "General Settings",
    subTitle: "Data retention, limit number of responses, etc.",
    Content: GeneralSettings,
  },
  {
    index: 2,
    iconName: "WarningOctagon",
    title: "Spam Protection",
    subTitle: "Data retention, limit number of responses, etc.",
    Content: SpamProtection,
  },
  {
    index: 3,
    iconName: "Security",
    title: "Security",
    subTitle:
      "Only accept submissions from whitelisted domains. Will help prevent spam",
    Content: Security,
  },
  {
    index: 4,
    iconName: "Security",
    title: "reCAPTCHA",
    subTitle:
      "reCAPTCHA provides spam protection. Disabling it will remove the reCAPTCHA redirect.",
    Content: Recaptcha,
  },
  {
    index: 5,
    iconName: "Security",
    title: "hCaptcha",
    subTitle: " Paste your hCaptcha Secret Key to protect your form",
    Content: Hcaptcha,
  },
  {
    index: 6,
    iconName: "Gear",
    title: "Processing",
    subTitle: "Paste your hCaptcha Secret Key to protect your form",
    Content: Processing,
  },
  {
    index: 7,
    iconName: "trash2",
    title: "Danger Zone",
    subTitle: "Terminate your form with all of its data",
    iconColor: "darkBlue",
    Content: DangerZone,
  },
];

function FormSettings() {
  const [expandedRows, setExpandedRows] = useState([]);
  const {
    state: { selected },
    EditForm,
  } = useSubmissionForm();
  const { projectId } = useParams();
  const {
    state: { projectDetails },
    fetchProjectDetails,
  } = useProject();

  useEffect(() => {
    fetchProjectDetails(projectId);
  }, [fetchProjectDetails, projectId]);

  const { notify } = useUtils();

  const toggleExpansion = (index) => {
    if (expandedRows.includes(index)) {
      setExpandedRows(expandedRows.filter((rowIndex) => rowIndex !== index));
    } else {
      setExpandedRows([...expandedRows, index]);
    }
  };

  const edit = async (data) => {
    try {
      await EditForm(data, projectId, selected?.items?.id);
      notify(`form settings edited`, "success");
    } catch (e) {
      throw e;
    }
  };

  return (
    <Box sx={{ mb: "100px" }}>
      {data.map(({ Content, ...item }) => (
        <React.Fragment key={item.index}>
          <Heading
            toggleExpansion={toggleExpansion}
            index={item.index}
            iconName={item.iconName}
            title={item.title}
            subtitle={item.subTitle}
            iconColor={item.iconColor}
          />

          <ExpandedContent
            className={expandedRows.includes(item.index) ? "active" : ""}
          >
            <Content
              edit={edit}
              selectedForm={selected?.items}
              projectDetails={projectDetails}
              notify={notify}
            />
            {/* {React.isValidElement(item.content) && React.cloneElement(item.content,{ edit: edit ,selectedForm:selected?.items})} */}
          </ExpandedContent>
        </React.Fragment>
      ))}
    </Box>
  );
}
export default FormSettings;
