import { useEffect, useMemo } from "react";
import styled from "styled-components";
import { Box, Flex, Link, Pagination, Select, TableContainer, Type } from "ui";
import { useSubmissionForm } from "./provider";
import { format } from "date-fns";
import { useParams } from "react-router-dom";

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;

  p:first-of-type {
    font-size: 14px;
    font-weight: 600;
    color: ${(props) => props.theme.color.black};
    line-height: 24px;
  }
  p:nth-of-type(2),
  p.date {
    font-size: 12px;
    color: ${(props) => props.theme.color.black};
    line-height: 20px;
  }
`;
const New = styled(Type)`
  background-color: ${(props) => props.theme.color.babyBlue};
  padding: 0px 5px;
  font-size: 12px;
  text-transform: capitalize;
  border-radius: 4px;
  color: ${(props) => props.theme.color.black};
`;

function SubmisissionFormList({ selectedForm , setactiveTab, tabs}) {
  const {
    state: { submissionFormList, count, page, limit ,status},
    dispatch,
    fetchSubmissionList,
  } = useSubmissionForm();

  const { projectId } = useParams();

  useEffect(() => {
    if (selectedForm && projectId) {
      fetchSubmissionList(page, limit, projectId, selectedForm, null , "","","");
    }
  }, [projectId, selectedForm, fetchSubmissionList, page, limit]);

  const MemoizedSelect = useMemo(() => (
    <Select
      data={["5", "10", "20", "50", "100"]}
      search={false}
      size="small"
      style={{ width: 100 }}
      defaultValue={limit.toString()}
      onChange={({ value }) => dispatch({ type: "set_limit", payload: value })}
    />
  ), [limit,dispatch]);


  // all submission data
  const extractedDataArray = submissionFormList.map((item) => {
    const { attachments, ...submissionDataWithoutAttachments } = item.submissionData;

    const extractedData = {
        ...submissionDataWithoutAttachments,
        id: item.id,
        origin: item.origin,
    };
    if (item.hasAttachments) {
      if (Array.isArray(item.submissionData.attachments.file)) {
          item.submissionData.attachments.file.forEach((file, index) => {
              extractedData[`file${index + 1}`] = file;
          });
      } else {
          extractedData[`file1`] = item.submissionData.attachments.file;
      }
  }
  extractedData[`createDate`] = item.createDate;
    return extractedData;
});

// Find the object with the most keys
const objWithMostKeys = extractedDataArray.reduce((acc, obj) =>
  Object.keys(obj)?.length > Object.keys(acc).length ? obj : acc, {});

  const columns =
    extractedDataArray.length > 0
      ? Object.keys(objWithMostKeys)
          .filter((key) => key !== "id" && key !== "origin")
          .map((key) => ({
            label: key.charAt(0).toUpperCase() + key.slice(1),
            name: key,
            render: ({ [key]: value }) => {
              if (key === "createDate") {
                return (
                  <Flex gap={4}>
                  {isWithinTwoPastDays(value)&&<New>new</New>}  
                    
                    <StyledBox>
                      <Type className="date">
                        {format(value, "MMMM dd, yyyy hh:mm aa")}
                      </Type>
                    </StyledBox>
                  </Flex>
                )
              } else if (key.startsWith("file")) {
                const displayValue = value?.length > 20 ? `${value.slice(0, 20)}...` : value;
                return    <StyledBox>
                <Type>{key.charAt(0).toUpperCase() + key.slice(1)}</Type>
                <Link sx={{fw: 600, fs: 12 }} to={value}>{displayValue}</Link>
              </StyledBox>

              }else {
                return (
                  <StyledBox>
                    <Type>{key.charAt(0).toUpperCase() + key.slice(1)}</Type>
                    <Type>{value}</Type>
                  </StyledBox>
                );
              }
            },
          }))
      : [];


  //to check when to add NEW sign beside the create date
  function isWithinTwoPastDays(dateToCheck) {
    // Get the current date
    const currentDate = new Date();

    // Calculate the date two days ago
    const twoDaysAgo = new Date();
    twoDaysAgo.setDate(currentDate.getDate() - 2);

    // Parse the dateToCheck as a Date object
    const checkDate = new Date(dateToCheck);

    // Check if the dateToCheck is within the two past days
    return checkDate >= twoDaysAgo && checkDate <= currentDate;
}


console.log(columns);

  const handleChangePage = (newPage) =>
    dispatch({ type: "set_page", payload: newPage });

  return (
    <div>
      <TableContainer
        data={extractedDataArray}
        cols={columns}
        checkbox
        customHeader
        expandedContent
        empty={extractedDataArray.length > 0 ? false : true}
        setactiveTab={setactiveTab} 
        tabs={tabs}
        status={status}
      />
      <Box sx={{ my: 20 }}>
        <Flex jc="flex-start" gap={20}>
          <Type>Results</Type>
          {MemoizedSelect}
          <Pagination
            count={count}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={limit}
          />
        </Flex>
      </Box>
    </div>
  );
}
export default SubmisissionFormList;
