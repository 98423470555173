import { Icon, Type } from "ui";
import { StyledBox ,IconBakground, HeadingFlex} from "../../../Components"

function Heading ({index,iconName,title,subtitle,toggleExpansion,iconColor}) {
    return (
        <HeadingFlex
          style={{ marginBottom: "0px" }}
          onClick={() => toggleExpansion(index)}
        >
            <IconBakground>
                <Icon name={iconName} c={iconColor} w="22" />
            </IconBakground>
            <StyledBox>
                <Type>{title}</Type>
                <Type variant="subtitle">
                  {subtitle}
                </Type>
            </StyledBox>
        </HeadingFlex>
    );
}
export default Heading
