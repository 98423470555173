import Layout from "layout/Layout";
import Header from "./Header";
import FormsList from "./FormsList";


function Forms (){
    return (
        <Layout admin>
            <Header/>
            <FormsList/>
        </Layout>
    );
}
export default Forms 