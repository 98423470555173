import { yupResolver } from "@hookform/resolvers/yup";
import LayoutMasked from "layout/LayoutMasked";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useAuth } from "services/auth";
import { Box, Button, Checkbox, Flex, InputText, Type } from "ui";
import * as Yup from "yup";
import adminUsers from './AdminUsers';

const schema = Yup.object().shape({
  email: Yup.string().required("Required.").email("Invalid email."),
  password: Yup.string()
    .required("Required.")
    .min(4, "Too Short! at least 4 letters is required!"),
});

function LoginForm () {
    const [showPass, setShowPass] = useState(false);
    const [error, setError] = useState(false);
    let navigate = useNavigate();
    const auth = useAuth();
    const {
      register,
      handleSubmit,
      reset,
      formState: { errors, isSubmitting },
    } = useForm({
      resolver: yupResolver(schema),
    });
    useEffect(() => {
      if (auth.user) navigate("/adminDashboard");
    }, [auth, navigate]);
    const onSubmit = async (values) => {
      setError(null);
      console.log("values", values);
  
      try {
        if (adminUsers.includes(values.email)) {
        await auth.signin(values);
        reset();
        navigate("/adminDashboard");
         } else {
          setError(true);
        }
      } catch (e) {
        window.scrollTo(0, 0);
        console.log("error", e);
        if (e.status === 401) setError(true);
      }
    };
  

    return(
      <LayoutMasked
      title="Login to form easy"
      subtitle="Welcome back ! we missed you."
      >
         <Flex relative sx={{ ta: "center", mx: "auto", mb: 80 ,mt:"20px",w:"100%"}}>
            <Flex dir="column"sx={{w:"100%"}}>
              <Box
              as="form"
              sx={{
              w:[370,"_","_","90%"],
              ff:"secondary"
              }}
              onSubmit={handleSubmit(onSubmit)}
              noValidate
            >
              <Box
                sx={{
                  d: error ? "block" : "none",
                  p: 8,
                  bgc: "redBg",
                  // w: "100%",
                  border: ["red", 10, "solid", 5, "r"],
                  mb: 10,
                  ta: 'left'
                }}
              >
                That combination of username and password did not work, please try
                again.
              </Box>
              <InputText
                label="E-mail address"
                size="medium"
                error={!!errors.email}
                msg={errors.email?.message}
                inputProps={{
                  ...register("email"),
                  autoFocus: true,
                }}
              />
              <InputText
                label="Password"
                type={showPass ? "text" : "password"}
                size="medium"
                iconRight={[
                  showPass ? "eyeSlash" : "eye",
                  () => setShowPass((showPass) => !showPass),
                ]}
                error={!!errors.password}
                msg={errors.password?.message}
                inputProps={{
                  ...register("password"),
                  required: true,
                }}
              />

                <Flex 
                gap={10}
                sx={{py:"15px"}}>
                  <Checkbox 
                  size="small" 
                  label="Remember me"
                  id="RememberMe"
                  style={{margin:"0px"}}
                  />
                  <Type
                    id="1"
                    style={{ marginLeft: "auto", cursor: "pointer" }}
                    var={"h6"}
                    sx={{c:"darkBlue"}}
                    onClick={() => navigate("/forgot-password")}
                  >
                    Forget your Password?
                  </Type>
                </Flex>
    
              <Button
                type="submit"
                loading={isSubmitting}
                sx={{ mx: "auto", w: "100%" }}
              >
                Login
              </Button>
              </Box>
            </Flex>
         </Flex>
      </LayoutMasked>
    );
}
export default LoginForm