import { Box, Button, Flex, Type } from "ui";
import Dialog from "ui/Dialog";
import { useApi } from "./provider";

export default function DeleteDialog({ openDelete, setOpenDelete }) {
  const {
    state: { status },
    remove,
  } = useApi();

  const handleDelete = async (id) => {
    await remove(id);
    setOpenDelete(0);
  };

  return (
    <Dialog show={openDelete} onClose={() => setOpenDelete(0)}>
      <Box sx={{ p: 25, mxw: [600, 600, 600, "calc(100vw - 40px)"] }}>
        <Type sx={{ mb: 25 }} var="h4">
          Delete API Key
        </Type>
        <Type>
          This API key will be deleted immediately. Once deleted, it can no
          longer be used to make API requests.
        </Type>
        <Type sx={{ mt: 20, mb: 20 }}>
          Do you want to delete this credential?
        </Type>
      </Box>
      <Box sx={{ bgc: "primaryBg", px: 25, py: 15 }}>
        <Flex jc="flex-end">
          <Button
            size="small"
            variant="outlined"
            type="button"
            onClick={() => setOpenDelete(0)}
            sx={{ mr: 10 }}
            bg="black"
          >
            Cancel
          </Button>
          <Button
            size="small"
            type="button"
            loading={status === "deleting_" + openDelete}
            onClick={() => handleDelete(openDelete)}
          >
            Delete
          </Button>
        </Flex>
      </Box>
    </Dialog>
  );
}
