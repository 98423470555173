import Layout from "layout/Layout";
import SettingsWithBackground from "../../../assests/SettingsWithBackgound.svg";
import {
  Box,
  Flex,
  Icon,
  InputText,
  Link,
  Type,
  Button,
  Grid,
  Slider,
} from "ui";
import styled from "styled-components";
import { useCallback, useEffect, useState } from "react";
import { useProject } from "../provider";
import { useSubmissionForm } from "views/submitionForm/provider";
import { useParams } from "react-router-dom";
import { useUtils } from "services/utilsProvider";
import AllowedDomains from "./AllowedDomains";
import { FlexStyled } from "Components";
import FormCard from "views/submitionForm/formList/FormCard";
import DeleteFormDialog from "views/submitionForm/formList/DeleteFormDialog";
import DeleteProjectDialog from "../DeleteProjectDialog";

const StyledFlex = styled(Flex)`
  padding: 0px 8px;
  gap: 3px;
  color: ${(props) => props.theme.color.black};
  font-family: ${(props) => props.theme.fonts.primary};
  font-size: 12px;
  background-color: ${(props) => props.theme.color.gray300};
  border-radius: 20px;
  font-weight: 400;
  cursor: pointer;
`;
const CustomLink = styled(Link)`
  color: ${(props) => props.theme.color.gray600};
  font-size: 14px;
  font-family: ${(props) => props.theme.fonts.primary};
  text-decoration: none;
  font-weight: 600;
  padding: 5px;
  &:hover {
    color: ${(props) => props.theme.color.darkBlue};
    background-color: ${(props) => props.theme.color.gray300};
    border-radius: 6px;
  }
  &.selected {
    color: ${(props) => props.theme.color.darkBlue};
    background-color: ${(props) => props.theme.color.gray300};
    border-radius: 6px;
  }
`;
const IconBakground = styled(Flex)`
  height: 45px;
  width: 50px;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.color.gray300};
  border-radius: 4px;
`;
const HeadingFlex = styled(Flex)`
  gap: 20px;
  background-color: ${(props) => props.theme.color.babyBlue};
  border-radius: 6px;
  padding: 10px;
  margin: 20px 0px;
  align-items: center;
  justify-content: flex-start;
`;
const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  p:first-of-type {
    font-size: 16px;
    font-weight: 600;
    color: ${(props) => props.theme.color.black};
    line-height: 24px;
  }
  p:nth-of-type(2) {
    font-size: 12px;
    color: ${(props) => props.theme.color.black};
    line-height: 20px;
  }
`;

function ProjectDetails() {
  const [formsSelected, setFormsSelected] = useState(false);
  const { id } = useParams();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openDeleteProjectDialog, setOpenDeleteProjectDialog] = useState(false);
  const [error,setError] =useState("");
  const [PnameError,setPnameError] =useState("");

  const {
    state: { projectDetails },
    fetchProjectDetails,
    editProjectDetails,
    AddDomain,
    dispatch,
  } = useProject();

  const {
    state: { forms },
    fetchList,
  } = useSubmissionForm();


  const [inputDomainValue, setInputDomainValue] = useState("");
  const { notify } = useUtils();

  useEffect(() => {
    fetchProjectDetails(id);
    fetchList(0, 10, id);
  }, [fetchProjectDetails, id, fetchList]);

  const handleInputDomainChange = (event) => {
    setInputDomainValue(event.target.value);
  };

  const edit = async () => {
    try {
      await editProjectDetails({ name: projectDetails.name }, id);
      setPnameError("");
      notify(`Project has been saved successfully`, "success");
    } catch (error) {
      setPnameError(error.data.message);
    }
  };

  const handleInputNameChange = useCallback(
    (event) => {
      dispatch({
        type: "edit_project_details",
        payload: { ...projectDetails, name: event.target.value },
      });
    },
    [dispatch, projectDetails]
  );

  const Add_Domain = async (e) => {
    e.preventDefault();
    try {
      const data = {
        allowedDomain: inputDomainValue,
      };
      await AddDomain(data, id);
      setInputDomainValue("");
      setError("")
      notify(`domain added successfully`, "success");
    } catch (e) {
      console.log(e.data.message);
      setError(e.data.message)
    }
  };
  const copyToClipboard = (formUid) => {
    navigator.clipboard
      .writeText(formUid)
      .then(() => {
        notify('copied','success');
      })
      .catch((error) => {
        console.error("Error copying to clipboard:", error);
      });
  };
  if (!projectDetails) return null;
  return (
    <Layout>
      <Flex gap="10" ai="center" jc="flex-start" sx={{ p: "0px 20px" }}>
        <img src={SettingsWithBackground} alt="settings" />
        <Box>
          <Type var="h6" sx={{ fw: "700" }}>
            project details
          </Type>
          <StyledFlex onClick={()=>{copyToClipboard(projectDetails?.name)}}>
            Name: {projectDetails?.name}
            <Icon name="Copy2" w="16" />
          </StyledFlex>
        </Box>
      </Flex>
      <HeadingFlex
        gap={20}
        jc="flex-start"
        sx={{ bgc: "babyBlue", my: 20, p: 5, br: 6 }}
      >
        <CustomLink
          className={!formsSelected ? "selected" : undefined}
          onClick={() => {
            setFormsSelected(false);
          }}
        >
          Settings
        </CustomLink>
        <CustomLink
          className={formsSelected ? "selected" : undefined}
          onClick={() => {
            setFormsSelected(true);
          }}
        >
          Forms
        </CustomLink>
      </HeadingFlex>
      {
      !formsSelected ? (
        <>
          <HeadingFlex sx={{ mb: 0 }}>
            <IconBakground>
              <Icon name={"Gear"} w="22" />
            </IconBakground>
            <StyledBox>
              <Type>General Settings</Type>
              <Type variant="subtitle">
                Data retention,limit number of responses stc.
              </Type>
            </StyledBox>
          </HeadingFlex>

          <FlexStyled>
            <StyledBox sx={{ gc: ["2", "2", "2", "span 2"] }}>
              <Type>Project title</Type>
              <Type variant="subtitle">
                The project name won't be shown to your visitors.
              </Type>
            </StyledBox>
            <InputText
              error={PnameError}
              msg={PnameError}
              placeholder={projectDetails?.name}
              size="small"
              sx={{
                w: "250px",
              }}
              value={projectDetails?.name}
              onChange={handleInputNameChange}
            />
          </FlexStyled>

          <Grid
            cols={[".6fr .4fr", "_", "_", "auto"]}
            rows={["auto", "_", "_", "auto auto"]}
            gap={30}
            sx={{ mt: 20, mb: 50 }}
          >
            <StyledBox sx={{ ml: [80, 80, 80, 25], mxw: 500 }}>
              <Type>Restrict to Domain</Type>
              <Type variant="subtitle">
                Only submissions from this domain(and all subdomains) will be
                accepted.
              </Type>
            </StyledBox>

            <Box sx={{ ml: [0, 0, 0, "20px"] }}>
              <Flex
                as="form"
                jc="flex-end"
                gap={10}
                sx={{ pb: 20, m: "0px" }}
                onSubmit={Add_Domain}
              >
                <InputText
                  error={error}
                  msg={error}
                  placeholder="https://"
                  size="small"
                  sx={{ mb: 0 }}
                  value={inputDomainValue}
                  onChange={handleInputDomainChange}
                  inputProps={{
                    type: "url",
                    required: true,
                    pattern: "https?://.*",
                  }}
                />
                <Button size="small">Add</Button>
              </Flex>
              <AllowedDomains allowedDomains={projectDetails.allowedDomains || []} />
            </Box>
          </Grid>

          <HeadingFlex style={{ marginBottom: "0px", marginTop: "20px" }}>
            <IconBakground>
              <Icon name={"Trash2"} w="22" c="darkBlue" />
            </IconBakground>
            <StyledBox>
              <Type>DANGER ZONE</Type>
              <Type variant="subtitle">
                Terminate your Project with all of its data
              </Type>
            </StyledBox>
          </HeadingFlex>

          <FlexStyled>
            <StyledBox sx={{ gc: ["2", "2", "2", "span 2"] }}>
              <Type>Delete Project</Type>
              <Type variant="subtitle">
                Deleting the project will also delete all project forms and
                submissions.
              </Type>
            </StyledBox>
            <Box>
              <Icon
                onClick={() => {
                  setOpenDeleteProjectDialog(true);
                }}
                hover="redBg"
                name={"Trash2"}
                w="22"
                c="red"
              />
            </Box>
          </FlexStyled>

          <Flex
            dir="row"
            ai="center"
            gap="10"
            jc="flex-end"
            sx={{
              my: "20px",
            }}
          >
            <Button onClick={() => edit()}>Save</Button>
          </Flex>

          <DeleteProjectDialog   
          {...{
            openDeleteProjectDialog,
            setOpenDeleteProjectDialog,
            projectDetails
          }}
          />

        </>
      )
   
     : (
        <>
          <Box>
            {forms.list.length !== 0 ? (
              <>
                <Slider
                  slides={forms.list.map((form, i) => (
                    <FormCard
                      id={"card-" + i}
                      key={form.id}
                      {...{
                        setOpenDeleteDialog,
                        form,
                        projectId: forms.projectId,
                      }}
                    />
                  ))}
                  totalView={3}
                />

                {/* <a href="#card-4">2</a> */}
              </>
            ) : (
              <Flex sx={{ py: 60 }}>
                <Type var="subtitle" sx={{ c: "gray600", fs: 14 }}>
                  There is no forms in this project!
                </Type>
              </Flex>
            )}
          </Box>
          <DeleteFormDialog
            {...{
              setOpenDeleteDialog,
              openDeleteDialog,
              projectId: forms.projectId,
            }}
          />
        </>
      )}
    </Layout>
  );
}

export default ProjectDetails;
