// used to determine screen size, it return one of the strings 'xs', 'sm', 'md'
// the value change on window resizing.

import { useEffect, useState } from "react";

function useMedia() {
  const [media, setMedia] = useState(window.innerWidth);

  function checkWidth() {
      setMedia(window.innerWidth);
  }

  useEffect(() => {
    window.setInterval(checkWidth, 500)
    window.addEventListener("resize", checkWidth);
    return () => window.removeEventListener("resize", checkWidth);
  }, []);

  return media;
}

export default useMedia;
