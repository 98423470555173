import { Box, Button, Flex, Icon, InputText, Type } from "ui";
import { useApi } from "./provider";
import { useState } from "react";

export default function Add({ onSuccess }) {
  const [name, setName] = useState("");
  const [error, setError] = useState(false);

  const {
    state: { status },
    create,
  } = useApi();

  async function handleSubmit() {
    if (name === "") {
      setError(true);
      return;
    }
    await create(name);
    setName("")
    onSuccess();
  }

  return (
    <>
      <Box sx={{ p: 25 }}>
        <InputText
          size="medium"
          label="Name *"
          placeholder="Type a name for your API Key"
          value={name}
          onChange={(e) => {
            setError(e.target.value === "" ? true : false);
            setName(e.target.value);
          }}
          inputProps={{
            style: { width: 260 },
          }}
          error={error}
          msg={error && "Name is required"}
        />
      </Box>
      <Box sx={{ bgc: "primaryBg", px: 25, py: 15, ta: "right" }}>
        <Button
          type="button"
          size="small"
          loading={status === "creating"}
          onClick={handleSubmit}
        >
          Create
        </Button>
      </Box>
    </>
  );
}
