import Layout from "layout/Layout";
import React from "react";
import { Outlet } from "react-router-dom";
import { Box, Type } from "ui";

const nav = [
  { name: "Profile", path: "/account" },
  { name: "Password", path: "/account/password" },
  { name: "Connected accounts", path: "/account/connected-accounts" },
  { name: "Usage", path: "/account/Usage" },
];

const SectionHeader = () => (
  <>
    <Type var="supTitle" sx={{ fw: 800 }}>
      Account
    </Type>
    <Type var="subTitle">Edit your account details down below.</Type>
  </>
);

function Account() {
  return (
    <Layout sectionNav={nav} SectionHeader={SectionHeader}>
      <Box sx={{ mxw: 600, mx: "auto"}}>
        <Outlet />
      </Box>
    </Layout>
  );
}

export default Account;
