import { Box, Button, Flex, Type } from "ui";
import Dialog from "ui/Dialog";
import { useUtils } from "services/utilsProvider";
import { useParams } from "react-router-dom";
import { useSubmissionForm } from "views/submitionForm/provider";

export default function DeleteExportDialog({
  openDeleteExportDialog,
  setOpenDeleteExportDialog,
  exportObj
}) {

const { deleteExport } = useSubmissionForm();
const { notify } = useUtils();
const { formId, projectId } = useParams();


  const handleDelete = () => {
    deleteExport(projectId,formId,parseInt(exportObj?.id));
    notify("export deleted");
    setOpenDeleteExportDialog(false);
  };

  return (
    <Dialog
      show={openDeleteExportDialog}
      onClose={() => setOpenDeleteExportDialog(false)}
    >
      <Box sx={{p: 20}}>
        <Type
          var="h5"
          sx={{
            c: "type",
            fw: 700,
            lh: 30,
          }}
        >
          Delete {exportObj?.title} ?
        </Type>
        <Type var="subtitle" sx={{ c: "type", fs: "14px", ff: "secondary" }}>
          Are you sure about that? this can't be undone!
        </Type>
      </Box>

      <Flex
        pos={{ b: 0, r: 0 }}
        dir="row"
        gap={10}
        jc="flex-end"
        sx={{
          p: 10,
          w: "100%",
          bgc: "babyBlue",
          br: "0 0 4px 4px",
        }}
      >
        <Button variant="outlined" onClick={() => setOpenDeleteExportDialog(false)}>
          Cancel
        </Button>
        <Button onClick={handleDelete}>Approve</Button>
      </Flex>
    </Dialog>
  );
}
