import { useState } from "react";
import styled from "styled-components";
import { Box, Button, Flex, Icon, Type } from "ui";
import Dialog from "ui/Dialog";
import Modal from "ui/Modal";
import { useSubmissionForm } from "./provider";
import { useParams } from 'react-router-dom';
import { useUtils } from "services/utilsProvider";

const CustomFlex = styled(Flex)`
    width: 90px;
    height: 90px;
    border: 1px solid  ${props => props.theme.color.babyBlue};
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1px;
    &:focus,
    &:hover,
    &:active,
    &.active{
        cursor: pointer;
        background-color:  ${props => props.theme.color.babyBlue};
    }
`;
const CustomConditionFlex = styled(Flex)`
    border: 1px solid  ${props => props.theme.color.babyBlue};
    border-radius: 4px;
    background-color: ${props => props.theme.color.transparent};
    color:  ${props => props.theme.color.black};
    font-size: 12px;
    font-weight: 500;
    padding: 8px;
    align-items: center;
    justify-content: center;
    &:focus,
    &:hover,
    &:active,
    &.active{
        cursor: pointer;
        background-color:  ${props => props.theme.color.babyBlue};
    }
`;
function ExportForm ({ setOpenExportForm, openExportForm,setactiveTab,tabs }){
    const [selectedFormatOption, setSelectedFormatOption] = useState(null);
    const [selectedConditionOption, setSelectedConditionOption] = useState(null);
    const [switchModal, setswitchModal] = useState(false);
    const { 
        CreateFormSubmissionsExport
        ,state: {submissionFormList,
        selected:selectedForm}} = useSubmissionForm();

    const { notify } = useUtils();
    const { formId, projectId } = useParams();

    const handleCustomFlexClick = (option) => {
        setSelectedFormatOption(option);
    };
    const handleCustomConditionFlexClick = (option) => {
        setSelectedConditionOption(option);
    };

    const handleExport = async (e) => {
        e.preventDefault();
        try {
            if (selectedFormatOption !== null) {
            const data = {
                "title": selectedForm?.items?.name,
                "type": selectedFormatOption,
              }
              if(submissionFormList.length!==0){
                await CreateFormSubmissionsExport(data,projectId,formId);
                setswitchModal(true);
              }else{
                notify(`no submittions yet!`);
              }  
            }else{
                notify(`you must choose !`);
            }
          } catch (e) {
            console.log(e);
          }
    }
    return(
        <>
        {!switchModal?(
        <Modal onClose={() => setOpenExportForm(false)} show={openExportForm} >
        <Icon name="ArrowLeft" w="20" onClick={()=>setOpenExportForm(false)}/>
        <Box as="form" noValidate >
        <Type var="h5" sx={{c:"type",fw:"700",ff:"secondary",text:"none",lh:"30px"}}>Export Form</Type>
        <Type var="subtitle" sx={{ c: "type", fs: "14px", ff: "secondary" }}>
            Create an export of your form submissions in CSV or JSON format.
        </Type>
        <Type var="h6" sx={{ c: "black", fs: "14px", ff: "secondary" }}>
             Choose file format
        </Type>
        <Flex gap={10} jc="flex-start" sx={{mb:"40px"}}>
            <CustomFlex
            className={selectedFormatOption === "csv" ? "active" : ""}
            onClick={() => handleCustomFlexClick("csv")}
            >
                <Icon name="File"/>
                <Type var="subtitle" sx={{ c: "type", fs: "14px", ff: "secondary",fw:"600" ,m:"0px",text:"uppercase"}}>
                csv
                </Type>
            </CustomFlex>
            <CustomFlex
            className={selectedFormatOption === "json" ? "active" : ""}
            onClick={() => handleCustomFlexClick("json")}
            >
                <Icon name="File"/>
                <Type var="subtitle" sx={{ c: "type", fs: "14px", ff: "secondary",fw:"600" ,m:"0px",text:"uppercase"}}>
                JSON
                </Type>
            </CustomFlex>
        </Flex>
  
        <Button
        onClick={(e) => { handleExport(e); }}
        sx={{
            w: "calc(100% - 40px)",
            pos: "absolute",
            bottom: "10px",
            marginLeft: "-20px",
            marginRight: "-20px",
        }}
        >
           Export Form
        </Button>
    </Box> 
       </Modal>):(
       <Dialog padding={"20px 30px 60px 30px"} show={switchModal} onClose = {() => setswitchModal (false)}>
       <Type
           var="h5"
           sx={{
           c: "type",
           fw: "700",
           ff: "secondary",
           text: "none",
           lh: "30px",
           }}
        >
          Export Form
       </Type>
       <Type var="subtitle" sx={{ c: "type", fs: "14px", ff: "secondary" }}>
            Create an export of your form submissions in CSV or JSON format.
       </Type>
       <Type var="subtitle" sx={{ c: "type", fs: "14px", ff: "secondary" }}>
            Preparing your export,visit Exports to download it
       </Type>
       <Button
        onClick={()=>{setOpenExportForm(false);setactiveTab(tabs[4])}}
        sx={{
            w: "calc(100% - 60px)",
            pos: "absolute",
            bottom: "10px",
            marginLeft: "-30px",
            marginRight: "-30px",
        }}
        >
           Go to Exports
        </Button>
    </Dialog>)}
       </>
    );
}
export default ExportForm