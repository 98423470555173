import React, { useState, useRef, useEffect } from "react";
import { useCallback } from "react";
import styled from "styled-components";
import { getPoint, position } from "../../utilities/relativePosition";
import Portal from "./Portal";
import isPropValid from "@emotion/is-prop-valid";

const StyledDropdown = styled.span
  .withConfig({
    shouldForwardProp: (prop) => isPropValid(prop),
  })
  .attrs((p) => ({
    delay: p.delay || 0.05,
  }))`
  position: fixed;
  top: ${(p) => p.posRef.current.y}px;
  left: ${(p) => p.posRef.current.x}px;
  pointer-events: ${(p) => (p.show ? "all" : "none")};
  z-index: 99999;
  display: inline-block;
  white-space: nowrap;
  opacity: ${(p) => p.show};
  padding: 10px;
  border-radius: 10px;
  transition-property: transform, opacity !important;
  transition-duration: 0.06s !important;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1) !important;
  transition-delay: ${(p) => (p.show ? p.delay : 0.02)}s !important;

  transform-origin: ${(p) =>
    p.animateFrom ? p.animateFrom : position(p.placement).negate()};
  transform: scale(${(p) => (p.show ? 1 : 0.7)});
`;

function Dropdown({
  content,
  show,
  setShow = () => {},
  placement = "bottom",
  space = 15,
  children,
  disabled = 0,
  delay,
  click = true,
  onOpen = () => {},
  onClose = () => {},
  animateFrom,
}) {
  const [show2, setShow2] = useState(0);
  const posRef = useRef({ x: 0, y: 0 });
  const dropdownRef = useRef();
  const trigerRef = useRef();

  const handleMOver = (e) => {
    setShow2(1);
    posRef.current = getPoint(
      e.currentTarget,
      dropdownRef.current,
      placement,
      space
    );
    // console.log(e.currentTarget)
  };
  const handleMOut = () => setShow2(0);

  const handleClose = useCallback(() => {
    setShow(0);
    setShow2(0);
    onClose();
  }, [onClose, setShow]);

  const handleOpen = useCallback(() => {
    setShow(1);
    setShow2(1);
    onOpen();
  }, [onOpen, setShow]);

  let handleOutsideClick = useCallback(
    (e) => {
      if (!click) return;
      var isClickInsideElement =
        dropdownRef.current.contains(e.target) ||
        trigerRef.current.contains(e.target);
      if (!isClickInsideElement) {
        //Do something click is outside specified element
        handleClose();
      }
    },
    [handleClose, click]
  );

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    document.addEventListener("scroll", handleClose);
    window.addEventListener("resize", handleClose);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
      document.removeEventListener("scroll", handleClose);
      window.addEventListener("resize", handleClose);
    };
  }, [handleClose, handleOutsideClick]);

  const handleClick = (e) => {
    // e.stopPropagation();
    if (show === 0 || show2 === 0) {
      handleOpen();
      posRef.current = getPoint(
        e.currentTarget, // or trigerRef.current
        dropdownRef.current,
        placement,
        space
      );
    } else {
      handleClose();
    }
  };

  const cloneProps = {};
  if (click) {
    cloneProps.onClick = handleClick;
    cloneProps.ref = trigerRef;
  } else {
    cloneProps.onMouseOver = handleMOver;
    cloneProps.onMouseOut = handleMOut;
  }
  cloneProps.className = show2 ? "active" : "";

  return (
    <>
      {disabled ? children : React.cloneElement(children, cloneProps)}
      {disabled || (
        <Portal>
          <StyledDropdown
            delay={delay}
            ref={dropdownRef}
            posRef={posRef}
            show={typeof show !== "undefined" ? show : show2}
            placement={placement}
            animateFrom={animateFrom}
          >
            {content}
          </StyledDropdown>
        </Portal>
      )}
    </>
  );
}

export default Dropdown;
