import { Flex, Icon, TableContainer, Type } from "ui";
import { useSubmissionForm } from "views/submitionForm/provider";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DeleteExportDialog from "./DeleteExportDialog";


function Exports() {
  const {
    state: { ExportsList },
    FetchFormSubmissionExports,
  } = useSubmissionForm();
  const { formId, projectId } = useParams();
  const [openDeleteExportDialog,setOpenDeleteExportDialog] = useState(false);
  const [exportObj,setExportObj] = useState({});
  const columns = [
    {
      label: "title",
      name: "title",
      render: ({ title }) => (
        <Flex gap={2}>
          <Type
            style={{
              color: "darkBlue",
              textAlign: "center",
              fontWeight: "600",
              fontSize: "12px",
            }}
          >
            {title}
          </Type>
        </Flex>
      ),
    },
    {
      label: "createDate",
      name: "createDate",
      render: ({ createDate }) => (
        <Type
          style={{ textAlign: "center", fontWeight: "600", fontSize: "12px" }}
        >
          {formatDate(createDate, convertToCustomFormat)}
        </Type>
      ),
    },
    {
      label: "type",
      name: "type",
      style: { textAlign: "center", fontWeight: "600", fontSize: "12px" },
    },
    {
      label: "Download",
      name: "Download",
      render: ({ title, exportFileUrl }) => (
        <Flex gap={4} onClick={() => handleDownload(exportFileUrl)} style={{cursor:'pointer'}}>
          <Icon name={"download"} w="12" />
          <Type
            style={{
              textAlign: "ce4nter",
              fontWeight: "600",
              fontSize: "12px",
            }}
          >
            {title}
          </Type>
        </Flex>
      ),
    },
    {
      label: "",
      name: "Trash",
      render: ({ id ,title }) => (
        <Flex>
          <Icon
          name="trash2"
          w={20}
          c="red"
          hover="gray100"
          onClick={() => {
            setOpenDeleteExportDialog(true);
            setExportObj({
              title: title,
              id: id
            })
          }}
          />
        </Flex>
      ),
    },
  ];

  useEffect(() => {
    if (formId && projectId){
      FetchFormSubmissionExports(0, 10, projectId,formId);
    }
  }, [projectId, formId, FetchFormSubmissionExports]);

  const handleDownload = (url) => {
    window.location.href = url;
  };
  const formatDate = (dateStr, conversionFunction) => {
    const date = new Date(dateStr);
    return conversionFunction(date);
  };
  const convertToCustomFormat = (date) => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
  };

  return (
    <>
      <Type var="h4" sx={{ fw: "700", pt: "10px" }}>
        Form Exports
      </Type>
      <Type var="subtitle" sx={{ c: "type", fs: "14px", ff: "secondary" }}>
        You can download your form exports from this page. You can create
        exports from the submission tab of your form
      </Type>
      <TableContainer cols={columns} data={ExportsList} numberOfRows />
      <DeleteExportDialog
      {...{
      setOpenDeleteExportDialog,
      openDeleteExportDialog,
      exportObj
      }}/>
      </>
  );
}
export default Exports;
