import styled from "styled-components";
import Flex from "./Flex";
import Loading from "./Loading";
import Mask from "./Mask";
import Type from "./Type";
import stylesBlocks from "./core/stylesBuilder";

const Wrap = styled.div.attrs((p) => ({
  elevation: Number.isInteger(p.elevation) ? p.elevation : 1,
}))`
  ${(p) => p.shadow && `
    box-shadow: 0px 1px 2px 2px ${p.theme.color.black + "11"},
    0px ${p.elevation * 3}px ${p.elevation * 3}px 0px ${p.theme.color.black + "22"};
  `}
  background-color: ${(p) => p.theme.color[p.bg || "white"]};
  border-radius: ${(p) => (p.rad ? p.rad : "4")}px;
  /* border: ${(p) => p.theme.color.gray100} 1px solid; */
  position: relative;

  ${stylesBlocks}
`;

const Head = styled.div`
  padding: ${(p) => p.padding || "20px 30px"};
  /* border-bottom: ${(props) => props.theme.color.gray300} 1px solid; */
`;

const Body = styled.div`
  padding: ${(p) => p.padding || "17px 20px"};
  position: relative;
  overflow :"hidden";
  @media only print {
    width: 90vw;
  }
`;

function Card({ title, children, onClose, loading = null, padding, ...props }) {
  return (
    <Wrap {...props}>
      {Boolean(title) && (
        <Head padding={padding}>
          <Flex jc="space-between">
            <Type var="h3" as="h3" sx={{ m: 0 }}>
              {title}
            </Type>
          </Flex>
        </Head>
      )}
      <Body padding={padding}>{children}</Body>
      {Boolean(loading !== null) && (
        <Mask relative show={loading} style={{ borderRadius: "15px" }}>
          <Loading />
        </Mask>
      )}
    </Wrap>
  );
}

export default Card;
