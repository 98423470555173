import styled from "styled-components";
import { Box, Button, Card, Flex, Icon, Type } from "ui";

const StyledType = styled(Type)`
  padding: 0px 5px;
  color: ${(props) => props.theme.color.black};
  font-family: ${(props) => props.theme.fonts.primary};
  font-size: 12px;
  background-color: ${(props) => props.theme.color.gray300};
  border-radius: 20px;
  font-weight: 400;
`;
const StyledBox = styled(Box)`
  text-align: center;
  p:first-of-type {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 2px;
    color: ${(props) => props.theme.color.gray500};
  }
  p:nth-of-type(2) {
    font-size: 12px;
    color: ${(props) => props.theme.color.gray400};
  }
`;
function FormsList () {
    return(
        <Box w="100%" sx={{ p: "20px" }}>
        {/* <Type var="h6">forms by user alice</Type> */}
        {/* {forms.list.length !== 0 && */}
          <Flex gap="10" style={{ flexWrap: "wrap" }}>
            {/* {forms.list.map((form) => ( */}
              <Card
                shadow={"true"}
                // key={form.id}
                sx={{
                  pos: "relative",
                  minw: "fit-content",
                  grow:[0,0,0,1]
                }}
              >
                <Icon
                  onClick={() => {
                    // setOpenDeleteDialog(true);
                    // setFormId(form.id);
                    // setFormName(form.name);
                  }}
                  name={"Trash"}
                  w="12"
                  c="darkBlue"
                  style={{
                    position: "absolute",
                    top: "25px",
                    right: "20px",
                  }}
                />
                <Flex
                  dir="column"
                  gap="5px"
                  ai="flex-start"
                  style={{
                    paddingBottom: "10px",
                    borderBottom: "1px solid red",
                    borderImage:
                      " linear-gradient(to right, transparent, #E0E5F2, transparent) 1",
                  }}
                >
                  <Flex gap="5">
                    <Type var="h6" sx={{ fw: "700" }}>
                      {/* {form.name} */}
                    </Type>
                    <StyledType>
                        {/* ID {form.uniqueId} */}
                    </StyledType>
                  </Flex>
                  <Type sx={{ c: "gray500", fs: "12px" }}>
                    {/* Form Created at {FormatDate(form.createDate)} */}
                  </Type>
                </Flex>
                <Type var="h5" sx={{ mt: "10px" }}>
                  Submissions
                </Type>
                <Flex gap="25px" sx={{ m: "15px 0px" }}>
                  <StyledBox>
                    <Type var="display">0</Type>
                    <Type>Monthly</Type>
                  </StyledBox>
                  <StyledBox>
                    <Type var="display">0</Type>
                    <Type>Last Monthly</Type>
                  </StyledBox>
                  <StyledBox>
                    <Type var="display">0</Type>
                    <Type>All Time</Type>
                  </StyledBox>
                </Flex>
                <Button
                  sx={{ w: "100%" }}
                  onClick={() => {
                    // handleSeeResponses(form.id, form);
                  }}
                >
                 View details
                </Button>
              </Card>
            {/* ))} */}
          </Flex>
        
      </Box>
    )
}
export default FormsList