import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useAuth } from "services/auth";
import { Box, Button, Flex, InputText, Type } from "ui";
import * as Yup from "yup";
import { useUtils } from "services/utilsProvider";

const schema = Yup.object().shape({
  currentPassword: Yup.string()
    .required("Required.")
  ,
  newPassword: Yup.string()
    .required().matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*.])(?=.{8,})/,"Must Contain 8 Characters,One Uppercase,one Lowercase,One number,and  one special case character"), 
 
  cPassword: Yup
  .string()
  .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
});

function Password() {

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  let auth = useAuth();
  const { notify } = useUtils();
  const [error, setError] = useState([]);

  const onSubmit = async (values) => {
      try {
        await auth.changePass(getValues("currentPassword"),getValues("newPassword"));
        setError([])
        notify('Password Updated !','success')
      } catch (e) {
        window.scrollTo(0, 0);
        console.log("error", e);
        setError(e.data.message);
      }
  };

  const [showPass, setShowPass] = useState(false);
  const [showPass1, setShowPass1] = useState(false);
  const [showPass2, setShowPass2] = useState(false);
  
  return (
    <>
      <Box as="form" onSubmit={handleSubmit(onSubmit)} noValidate>
        <Flex
          role="alert"
          sx={{
            d: error.length ? "block" : "none",
            p: 5,
            bgc: "redBg",
            w: "100%",
            border: ["red", 10, "solid", 5, "r"],
            mb: 10,
          }}
        >
         {Array.isArray(error) && error.length ? (
            error.map((err) => <Type>{err}</Type>)
          ) : (
            <Type>{error}</Type>
          )}
        </Flex>
        <InputText
        label="Current Password"
        data-testid="currentPassword"
        error={!!errors["currentPassword"]}
        msg={errors["currentPassword"]?.message}
        type={showPass ? "text" : "password"}
        iconRight={[
          showPass ? "eyeSlash" : "eye",
          () => setShowPass((showPass) => !showPass),
        ]}
          inputProps={{
            ...register("currentPassword"),
            "aria-label": "currentPassword",
          }}
        />
        <InputText
        label="New Password"
        data-testid="newPassword"
        error={!!errors["newPassword"]}
        msg={errors["newPassword"]?.message}
        type={showPass1 ? "text" : "password"}
        iconRight={[
          showPass1 ? "eyeSlash" : "eye",
          () => setShowPass1((showPass1) => !showPass1),
        ]}
        inputProps={{
          ...register("newPassword"),
          "aria-label": "newPassword",
        }}
        />
  
        <InputText
        label="Confirm New Password"
        data-testid="cPassword"
        error={!!errors["cPassword"]}
        msg={errors["cPassword"]?.message}
        type={showPass2 ? "text" : "password"}
        iconRight={[
          showPass2 ? "eyeSlash" : "eye",
          () => setShowPass2((showPass2) => !showPass2),
        ]}

        inputProps={{
          ...register("cPassword"),
       
          role: "textbox",
          required: true,
        }}
      />
     
        <Button
          type="submit"
          loading={isSubmitting}
          size="large"
          style={{ color: "white", backgroundColor: "#061492" }}
          sx={{ mx: "auto", mt: 20, w: "100%" }}
        >
          Change Password
        </Button>
      </Box>
    </>
  );
}

export default Password;
