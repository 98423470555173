import { memo, useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useUtils } from "services/utilsProvider";
import { useSubmissionForm } from "./provider";
import { useForm } from "react-hook-form";
import { Box, Button, Flex, InputText, Select, Type } from "ui";
import ProjectForm from "../project/form";
import { useProject } from "views/project/provider";

const schema = Yup.object().shape({
  name: Yup.string()
    .min(1, "The form name must be at least 1 character")
    .max(50, "The form name cannot exceed 50 characters")
    .required("The form name field is required"),
  ChooseProject: Yup.string().required("The select field is required"),
  description: Yup.string(),
});

function SubmissionForm({ onSuccess, onCancel }) {
  const [error, setError] = useState([]);
  const { notify } = useUtils();
  const [createProject, setcreateProject] = useState(false);

  const { create } = useSubmissionForm();

  const {
    state: { list: projects },
    // fetchList: fetchProjects,
  } = useProject();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  // useEffect(() => {
  //   !projects.length && fetchProjects();
  // }, [fetchProjects, projects]);

  const onSubmit = async (values) => {
    try {
      const data = {
        name: values.name,
      };
      if (values.description) {
        data.description = values.description;
      }
      await create(data, Number(values.ChooseProject));
      reset();
      notify(`Form created successfully`);
      onSuccess();
    } catch (e) {
      console.log("error", e);
      if (e.status === 400) setError([e.data.message]);
    }
  };

  return (
    <>
      {!createProject ? (
        <Box as="form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <Type var="h4" sx={{ mb: 20 }}>
            Create new form
          </Type>
          
          <Flex
            role="alert"
            sx={{
              d: error.length ? "block" : "none",
              p: 5,
              bgc: "redBg",
              w: "100%",
              border: ["red", 10, "solid", 5, "r"],
              my: 10,
            }}
          >
            {error.map((err) => (
              <Type>{err}</Type>
            ))}
          </Flex>

          <InputText
            label="Form name"
            error={!!errors.name}
            msg={
              errors.name?.message ??
              "Give your form a name so you can easily identify it. This can always be changed later"
            }
            size="small"
            inputProps={{
              ...register("name"),
              autoFocus: true,
              required: true,
            }}
            sx={{ maxw: "100%" }}
          />
          <Flex dir="row" ai="center" gap={10} jc="flex-start" sx={{ mb: 15 }}>
            <Select
              data={projects}
              mapData={{ value: "id", label: "name" }}
              onChange={({ data }) => setValue("ChooseProject", data.id)}
              label="Choose a project"
              size="small"
              error={!!errors.ChooseProject}
              msg={
                errors.ChooseProject?.message && errors.ChooseProject.message
              }
              inputProps={{
                ...register("ChooseProject"),
              }}
            />
            <Box sx={{ pt: 35, minw: "fit-content" }}>
              <Button size="small" onClick={() => setcreateProject(true)}>
                Create a project
              </Button>
            </Box>
          </Flex>

          <InputText
            label="Descripition"
            error={!!errors.description}
            type="textarea"
            msg={errors.description?.message}
            inputProps={{
              ...register("description"),
            }}
            sx={{ maxw: "100%" }}
          />
          <Box sx={{ fl: "right", mt: 20 }}>
            <Button
              type="button"
              onClick={onCancel}
              variant="outlined"
              sx={{ mr: 15 }}
            >
              Cancel
            </Button>
            <Button loading={isSubmitting}>Create a form</Button>
          </Box>
        </Box>
      ) : (
        <ProjectForm
          onSuccess={() => setcreateProject(false)}
          onBack={() => setcreateProject(false)}
        />
      )}
    </>
  );
}

export default memo(SubmissionForm);
