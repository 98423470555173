import {
    useContext,
    useReducer,
    createContext,
    useCallback,
  } from "react";
  import { useRequest } from "../../services/request";
import Integrations from "./Integrations";
  
  const Context = createContext();
  
  export function useIntegrations() {
    return useContext(Context);
  }
  
  const initState = {
    itegrations: [],
    status: "idle",
  };
  
  const reducer = (state, action) => {
    switch (action.type) {
      case "setIntegrationslist":
        return {
          ...state,
          itegrations: action.payload
        };
      case "AddIntegration":
        const newIntegrationList = [{ ...action.payload }, ...state.itegrations];
        return { ...state, itegrations: [...newIntegrationList]};
      case "deleteIntegration":
        const idsToDelete = action.payload;
        const updatedItegrationsList = state.itegrations.filter(
          (item) => item.id !== idsToDelete
        );
        return { ...state, itegrations: [...updatedItegrationsList] };
      case "editIntegration":
        const idsToEdit = action.payload.id;
        const AfterEditList = state.itegrations.map((item) => {
          if (item.id === idsToEdit) {
            // If the id matches, replace the item with the new data (payload.resdata)
            return action.payload.resData;
          }
          // Otherwise, keep the item unchanged
          return item;
        });      
        return { ...state, itegrations: [...AfterEditList] };
      case "status":
        return { ...state, status: action.payload };
      default:
        throw new Error(`Invalid dispatch type: ${action.type}`);
    }
  };
  
  export default function IntegrationsProvider({ children }) {
    const [state, dispatch] = useReducer(reducer, initState);
    const req = useRequest();
  
    //telegram integration
    const createTelegramIntegration = useCallback(
      async (data, projectId ,formId) => {
        return new Promise(async (resolve, reject) => {
          try {
            dispatch({ type: "status", payload: `creating` });
            const resData = await req(
             `i/project/${projectId}/form/${formId}/telegram`,
              data,
              { method: "POST" },
              true
            );
            dispatch({ type: "AddIntegration", payload:  resData  });
            resolve(resData);
          } catch (e) {
            reject(e);
          } finally {
            dispatch({ type: "status", payload: `idle` });
          }
        });
      },
      [req]
    );

    //discord integration
    const createDiscordIntegration = useCallback(
      async (data, projectId ,formId) => {
        return new Promise(async (resolve, reject) => {
          try {
            dispatch({ type: "status", payload: `creating` });
            const resData = await req(
             `i/project/${projectId}/form/${formId}/discord`,
              data,
              { method: "POST" },
              true
            );
            dispatch({ type: "AddIntegration", payload:  resData  });
            resolve(resData);
            console.log(resData);
            
          } catch (e) {
            reject(e);
          } finally {
            dispatch({ type: "status", payload: `idle` });
          }
        });
      },
      [req]
    );

    //discord integration
    const editDiscordIntegration = useCallback(
      async (data, projectId ,formId,id) => {
        return new Promise(async (resolve, reject) => {
          try {
            dispatch({ type: "status", payload: `editing` });
            const resData = await req(
              `i/project/${projectId}/form/${formId}/discord`,
              data,
              { method: "PATCH" },
              true
            );
            dispatch({ type: "editIntegration", payload: { resData ,id}  });
            resolve(resData);
          } catch (e) {
            reject(e);
          } finally {
            dispatch({ type: "status", payload: `idle` });
          }
        });
      },
      [req]
    );
    //fetch integration list 
    const fetchIntegrationList = useCallback(
      async (projectId , formId) => {
        return new Promise(async (resolve, reject) => {
          dispatch({ type: "status", payload: "fetching" });
          try {
            const resData = await req(
              `i/project/${projectId}/form/${formId}/integration`,
              null,
              {},
              true
            );
            dispatch({
              type: "setIntegrationslist",
              payload: resData ,
            });
            resolve(resData);        
          } catch (error) {
            reject(error);
            console.log(error);
          } finally {
            dispatch({ type: "status", payload: `idle` });
          }
        });
      },
      [req]
    );
    //delete integration
    const deleteIntegration= useCallback(
      async (projectId, formId ,id) => {
        return new Promise(async (resolve, reject) => {
          try {
            dispatch({ type: "status", payload: `deleting` });
            const resData = await req(
              `i/project/${projectId}/form/${formId}/integration/${id}`,
               null,
              { method: "DELETE" },
              true
            );
            dispatch({ type: "deleteIntegration", payload:id});
            resolve(resData);   
          } catch (e) {
            reject(e);
          } finally {
            dispatch({ type: "status", payload: `idle` });
          }
        });
      },
      [req]
    );
    //google sheet integration
    const createGoogleSheetIntegration = useCallback(
      async (data, projectId ,formId) => {
        return new Promise(async (resolve, reject) => {
          try {
            dispatch({ type: "status", payload: `creating` });
            const resData = await req(
              `i/project/${projectId}/form/${formId}/g-sheets`,
              data,
              { method: "POST" },
              true
            );
            dispatch({ type: "AddIntegration", payload:  resData  });
            resolve(resData);
            console.log(resData);
            
          } catch (e) {
            reject(e);
          } finally {
            dispatch({ type: "status", payload: `idle` });
          }
        });
      },
      [req]
    );
    //edit google sheet integration
    const editGoogleSheetIntegration = useCallback(
      async (data, projectId ,formId,id) => {
        return new Promise(async (resolve, reject) => {
          try {
            dispatch({ type: "status", payload: `editing` });
            const resData = await req(
              `i/project/${projectId}/form/${formId}/g-sheets`,
              data,
              { method: "PATCH" },
              true
            );
            dispatch({ type: "editIntegration", payload: { resData ,id}  });
            resolve(resData);
          } catch (e) {
            reject(e);
          } finally {
            dispatch({ type: "status", payload: `idle` });
          }
        });
      },
      [req]
    );
    //edit telegram integration
    const editTelegramIntegration = useCallback(
      async (data, projectId ,formId,id) => {
        return new Promise(async (resolve, reject) => {
          try {
            dispatch({ type: "status", payload: `editing` });
            const resData = await req(
              `i/project/${projectId}/form/${formId}/telegram`,
              data,
              { method: "PATCH" },
              true
            );
            dispatch({ type: "editIntegration", payload: { resData ,id}  });
            resolve(resData);
          } catch (e) {
            reject(e);
          } finally {
            dispatch({ type: "status", payload: `idle` });
          }
        });
      },
      [req]
    );


    return (
      <Context.Provider
        value={{
          state,
          dispatch,
          createTelegramIntegration,
          createDiscordIntegration,
          fetchIntegrationList,
          deleteIntegration,
          editDiscordIntegration,
          createGoogleSheetIntegration,
          editGoogleSheetIntegration,
          editTelegramIntegration
        }}
      >
        {children}
      </Context.Provider>
    );
  }
  