import ResendEmail from "./ResendEmail";
import LayoutMasked from "layout/LayoutMasked";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useAuth } from "services/auth";
import { Type, Link, Box, Icon } from "ui";

function renderMessage(msg) {
  switch (msg) {
    case "verified":
      return (
        <Box sx={{ ta: "center", pb: 10 }}>
          <Icon name="checkCircle" size={60} c="green" />
          <Type var="h5" sx={{ c: "green", py: 5 }}>
            Email Verified
          </Type>
          <Type sx={{ c: "green" }}>
            Your email address was successfully verified.
          </Type>
        </Box>
      );
    case "already":
      return (
        <Type var="h5" sx={{ py: 10 }}>
          Your e-mail has already been verificatied.
        </Type>
      );
    default:
      return (
        <Box sx={{ ta: "center", py: 15 }}>
          <Icon name="xmark" w={18} c="red" circle />
          <Type var="h5" sx={{ c: "red", py: 5 }}>
            An error has occured while trying to verify your email.
          </Type>
        </Box>
      );
  }
}

export default function Verify() {
  const [status, setStatus] = useState("Verifying email in progress");
  const [searchParams, _] = useSearchParams();
  const { verify, signout } = useAuth();

  useEffect(() => {
    (async () => {
      try {
        await verify(
          searchParams.get("email"),
          searchParams.get("verifyToken")
        );
        signout();
        setStatus("verified");
      } catch (error) {
        console.log(error);
        if (error.status === 409) {
          setStatus("already");
          signout();
        } else setStatus("error");
      }
    })();
  }, [searchParams, verify, signout]);

  return (
    <LayoutMasked title="Email Verification">
      <Type sx={{ mt: 40, c: "gray600" }}>{renderMessage(status)}</Type>
      {status === "verified" || status === "already" ? (
        <Box sx={{ mt: 20 }}>
          <Link to="/login">Login to your Account.</Link>
        </Box>
      ) : (
        <Box sx={{ ta: "center" }}>
          <ResendEmail initCounter={0} />
        </Box>
      )}
    </LayoutMasked>
  );
}
