import { Box, Button, Flex, Grid, Loading, Type } from "ui";
import loginLogo from "../../assests/loginLogo.svg";
import { useEffect, useState } from "react";
import SubmissionForm from "views/submitionForm/form";
import Modal from "ui/Modal";
import Layout from "layout/Layout";
import FormsList from "views/submitionForm/FormList";
import { useProject } from "views/project/provider";
import SubmissionChart from "./SubmissionChart";
import submissionLetterImg from "assests/submission-letter.png";
import Avatar from "ui/Avatar";
import { useProfile } from "views/Account/provider";
import { useSubmission } from "views/submissions/provider";

function Dashboard() {
  const [openForm, setOpenForm] = useState(false);

  const {
    state: { list: projects, status },
    fetchList: fetchProjects,
  } = useProject();

  const {
    state: { selected: profile },
  } = useProfile();

  const {
    state: { statistics },
  } = useSubmission();

  useEffect(() => {
    fetchProjects();
  }, [fetchProjects]);

  // if (status === "fetching")
  //   return (
  //     <Flex>
  //       <Loading />
  //     </Flex>
  //   );

  return (
    <Layout>
      <Modal
        onCancel={() => {
          setOpenForm(false);
        }}
        onClose={() => {
          setOpenForm(false);
        }}
        onSuccess={() => {
          window.scrollTo(500, 0);
          setOpenForm(false);
        }}
        show={openForm}
      >
        <SubmissionForm
          projects={projects}
          setOpenForm={setOpenForm}
          openForm={openForm}
        />
      </Modal>
      {status === "fetching" ? (
        <Flex>
          <Loading />
        </Flex>
      ) : (
        <Box>
          {projects.length ? (
            <Box>
              <Box sx={{ mb: 40 }}>
                <Flex
                  jc={["flex-start", "_", "_", "center"]}
                  gap={20}
                  sx={{ my: 40 }}
                >
                  <Avatar size={82}>
                    <img
                      src="https://robohash.org/93.126.179.105.png?set=set1"
                      alt="profile"
                    />
                  </Avatar>
                  <Box>
                    <Type var="h2">Hello, {profile?.firstName}</Type>
                    <Type>
                      Here is forms and stats of {profile?.firstName}’s{" "}
                    </Type>
                  </Box>
                </Flex>
                <Grid
                  cols={[".2fr .8fr", ".2fr .8fr", "1fr", "100%"]}
                  gap={0}
                  sx={{
                    border: ["gray300", 1, "solid", 6, "tlrb"],
                    p: [30, 30, 30, 20],
                  }}
                >
                  <Box
                    sx={{
                      w: "100%",
                      as: "center",
                      d: ["block", "_", "_", "flex"],
                      gap: [0, 0, 0, 20],
                      mt: [0, 0, 0, 30],
                      mb: [0, 0, 40, 80],
                    }}
                  >
                    <img src={submissionLetterImg} alt="submission" />
                    <Box>
                      <Type var="h3" sx={{ fw: 700, c: "gray600", mt: 20 }}>
                        Submissions
                      </Type>
                      <Type var="h3" sx={{ fw: 700, mt: 10 }}>
                        {statistics.reduce(
                          (totals, stat) =>
                            totals + stat.spam_count + stat.not_spam_count,
                          0
                        )}
                      </Type>
                    </Box>
                  </Box>
                  <Box>
                    <SubmissionChart />
                  </Box>
                </Grid>
              </Box>
              <FormsList />
            </Box>
          ) : (
            <Flex dir="column" gap={5} sx={{ h: "calc(100vh - 270px)" }}>
              <Box sx={{ bgc: "transparent", p: "10px" }}>
                <img
                  style={{ width: "70px", height: "40px" }}
                  alt="logo"
                  src={loginLogo}
                />
              </Box>
              <Type
                var="h1"
                sx={{
                  c: "type",
                  fw: "600",
                  ff: "secondary",
                  text: "none",
                  lh: "30px",
                }}
              >
                Let's create your first model!
              </Type>
              <Type
                var="subtitle"
                sx={{ c: "type", fs: "14px", ff: "secondary" }}
              >
                Time to collect some submissions
              </Type>
              <Button onClick={() => setOpenForm(true)} iconName="CreateForm">
                Create a form
              </Button>
            </Flex>
          )}
        </Box>
      )}
    </Layout>
  );
}
export default Dashboard;
