import { Route } from "react-router-dom";
import { RequireAuth } from "../../services/auth";

import Account from "./Account";
import Profile from "./Profile";
import Password from "./Password";
import Usage from "./Usage/Usage";
import ConnectedAccounts from "./ConnectedAccounts";

export default (
  <Route
    path="account"
    element={
      <RequireAuth>
        <Account />
      </RequireAuth>
    }
  >
    <Route
      index
      element={
        <RequireAuth>
          <Profile />
        </RequireAuth>
      }
    />
    <Route
      path="password"
      element={
        <RequireAuth>
          <Password />
        </RequireAuth>
      }
    />
    <Route
      path="usage"
      element={
        <RequireAuth>
          <Usage />
        </RequireAuth>
      }
    />
    <Route
      path="connected-accounts"
      element={
        <RequireAuth>
          <ConnectedAccounts />
        </RequireAuth>
      }
    />
  </Route>
);
