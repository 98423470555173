import styled from "styled-components";
import stylesBlocks from "./core/stylesBuilder";

export default styled.div`
  width: ${(p) => p.size || 40}px;
  height: ${(p) => p.size || 40}px;
  border-radius: 999px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.color[props.c || "gray200"]};
  transition: all 0.1s ease;
  outline: ${(p) =>
      p.var === "dark" ? p.theme.color.black : p.theme.color.white}22
    0px solid;
  border: ${(props) => props.theme.color[props.c || "gray200"]} 3px solid;
  img {
    width: 100%;
  }
  &:hover {
    outline: ${(p) =>
        p.var === "dark" ? p.theme.color.black : p.theme.color.white}11
      3px solid;
  }
  ${stylesBlocks}
`;
