import { useEffect, useState } from "react";
import { Flex, Icon, TableContainer, Type } from "ui";
import { useProject } from "../provider";
import { useUtils } from "services/utilsProvider";
import { useParams } from "react-router-dom";

function AllowedDomains({ allowedDomains }) {
  const {id} = useParams();
  const { deleteDomain } = useProject();
  const { notify } = useUtils();
  const [convertedDomains, setConvertedDomains] = useState([]);

  useEffect(() => {
    const newConvertedDomains = allowedDomains?.map((domain) => ({
      allowedDomain: domain,
    }));

    setConvertedDomains(newConvertedDomains);
  }, [allowedDomains]);

  const DeleteDomain = (domainName) => {
    console.log(domainName);
    deleteDomain(id, encodeURIComponent(domainName));
    notify("Domain deleted!", 'success');
  };
  const columns = [
    {
      label: "Allowed Domains",
      name: "allowedDomain",
      render: ({ allowedDomain }) => (
        <Flex ai="center" jc="space-between">
          <Type
            style={{ textAlign: "center", fontWeight: "600", fontSize: "14px" }}
          >
            {allowedDomain}
          </Type>
          <Icon
            onClick={() => {
              DeleteDomain(allowedDomain);
            }}
            hover="redBg"
            name={"trash2"}
            c="red"
            w="18"
            style={{ cursor: "pointer" }}
          />
        </Flex>
      ),
    },
  ];
  return (
    <TableContainer
      empty={!convertedDomains.length}
      compact
      style={{ maxHeight: "300px" }}
      data={convertedDomains}
      cols={columns}
    />
  );
}
export default AllowedDomains;
