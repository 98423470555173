import LayoutMasked from "layout/LayoutMasked";
import ResendEmail from "./ResendEmail";

function AcceptEmail() {
  return (
    <LayoutMasked
      title="Email Verification"
      subtitle="A verification email has been sent to your email address"
    >
      <ResendEmail />
    </LayoutMasked>
  );
}

export default AcceptEmail;
