import { InputRadio, InputText, Link, Type } from "ui";
import { AlignedButton, FlexStyled, SecondRowItem, StyledBox } from "../../../Components";
import { useCallback, useState } from "react";

// const secretKey = '6Ldc-QAqAAAAAM9I9inD5VbUKlwdTIWt6iC7ms3W';
// const siteKey = '6Ldc-QAqAAAAAFKSmM5SgDpd_fQGgUz1-PWdPVPM';

function Recaptcha({ edit ,selectedForm ,notify}) {
  const [enableCaptcha, setEnableCaptcha] = useState(selectedForm?.enableRecaptcha);
  const [recaptchaKey, setRecaptchaKey] = useState(selectedForm?.recaptchaKey);

  const handleRecaptchaKeyChange = useCallback((event) => {
    setRecaptchaKey(event.target.value);
  }, []);


  const handleEdit = (e) => {
    e.preventDefault();
    if (enableCaptcha&&!recaptchaKey){
      notify('you must enter your secret key','info')
    }else{
      const data = {
        enableRecaptcha : enableCaptcha,
        recaptchaKey:enableCaptcha?recaptchaKey:null
      };
      edit(data);
      if (!enableCaptcha){
        setRecaptchaKey('')
      }
    }
  };
  return (
    <>
      <FlexStyled style={{ justifyContent: "flex-start" }}>
        <InputRadio
          toggleButton={true}
          checked={enableCaptcha}
          inputProps={{
            checked: enableCaptcha,
            onChange: () => setEnableCaptcha(!enableCaptcha),
          }}
        />
        <StyledBox>
          <Type>Enable CAPTCHA</Type>
        </StyledBox>
      </FlexStyled>
      {enableCaptcha && (
          <FlexStyled>
            <span />
            <StyledBox>
              <Type>ReCAPTCHA secret key</Type>
              <Type variant="subtitle">
                Create a new ReCAPTCHA on <Link to="https://developers.google.com/recaptcha">https://developers.google.com/recaptcha</Link>
              </Type>
            </StyledBox>
            <SecondRowItem>
            <InputText
              placeholder={selectedForm?.recaptchaKey?selectedForm?.recaptchaKey:'enter recaptcha key'}
              size="medium"
              sx={{
                w: "250px",
                mb: "0px",
              }}
              value={recaptchaKey}
              onChange={handleRecaptchaKeyChange}
            />
            </SecondRowItem>
        </FlexStyled>
        )}
      <AlignedButton onClick={(e) => handleEdit(e)}>save</AlignedButton>
    </>
  );
}
export default Recaptcha;
