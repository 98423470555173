import React, { useEffect, useMemo, useState } from "react";
import {Flex, Type } from "ui";
import discord from "../../assests/discord.svg";
import telegram from "../../assests/telegram.svg";
import googleSheet from "../../assests/google-sheets.svg";
import "./integration.css";
import IntegrationCard from "./IntegrationCard";
import { useIntegrations } from "./provider";
import { useParams } from "react-router-dom";


function Integrations() {

  const {
    state : {itegrations},
    fetchIntegrationList,
  } = useIntegrations();

  const { formId, projectId } = useParams();

  const Integrations = useMemo(() => [
    { img: discord, 
      title: "Discord", 
      description: "Integrate your form with Discord to automatically send form submissions to your text channels or friends" 
    },
    { img: telegram, 
      title: "Telegram", 
      description: "Integrate your form with Telegram to effortlessly forward form submissions to your channels or contacts seamlessly." 
    },
    {
      img: googleSheet, 
      title: "google-sheets", 
      description: "integrate your form with Google Sheets, allowing you to export and organize form submissions into a spreadsheet."
    }
  ], []);

  const [finalIntegrations, setFinalIntegrations] = useState([]);

  //fetch integrations
  useEffect(()=>{
    fetchIntegrationList(projectId,formId);
  },[fetchIntegrationList,projectId,formId])

  // Update finalIntegrations based on dynamic and static integrations
  useEffect(() => {
    
    const mergedIntegrations = [];
    const integrationOrder = ['discord', 'telegram', 'google sheet'];
    // Loop through dynamic array
    itegrations.forEach(dynamicIntegration => {
      const staticIntegration = Integrations.find(integration => integration.title.toLowerCase() === dynamicIntegration.type);

      // Merge dynamic data with img and description from static
      if (staticIntegration) {
        mergedIntegrations.push({
          ...dynamicIntegration,
          img: staticIntegration.img,
          description: staticIntegration.description
        });
      }
    });

    // Add missing static integrations (with null for dynamic properties)
    Integrations.forEach(staticIntegration => {
      const isInDynamic = itegrations.some(dynamicIntegration => dynamicIntegration.type === staticIntegration.title.toLowerCase());

      // If it's not in the dynamic array, add it with null for dynamic properties
      if (!isInDynamic) {
        mergedIntegrations.push({
          createDate: null,
          data: null,
          id: null,
          isEnabled: null,
          type: staticIntegration.title.toLowerCase(),
          img: staticIntegration.img,
          description: staticIntegration.description
        });
      }
      
    });
    const sortedIntegrations = mergedIntegrations.sort((a, b) => {
      return integrationOrder.indexOf(a.type) - integrationOrder.indexOf(b.type);
    });
  
    console.log("merged", sortedIntegrations);
  
    // Set the finalIntegrations state
    setFinalIntegrations(sortedIntegrations);
  }, [Integrations, itegrations]);




  return (
    <>
      <Type var="h4" sx={{ fw: "700", py: "20px", fs: "24px" }}>
        Integrations
      </Type>
      <Type
        var="subtitle"
        sx={{ c: "type", fs: "20px", ff: "secondary", m: "0px" }}
      >
        This page allows you to integrate your form with third-party services.
        You can find information like your API key and provide a linking URL on
        the web.
      </Type>
        <Flex
        ai="center"
        jc="center"
        dir="column"
        gap = "10px"
        sx={{
          w:"100%",
          my:"10px"
        }}>
           {finalIntegrations.map((item,i) => (
              <IntegrationCard 
              key={i} 
              id={item.id} 
              img={item.img} 
              title={item.type} 
              description={item.description}
              isEnabled={item.isEnabled}
              data={item.data}
              />
          ))}
        </Flex>
    </>
  );
}

export default Integrations;
