import { Box, Button, Flex, Type } from "ui";
import Dialog from "ui/Dialog";
import { useUtils } from "services/utilsProvider";
import { useProject } from "./provider";
import { useNavigate } from "react-router-dom";

export default function DeleteProjectDialog({
  openDeleteProjectDialog,
  setOpenDeleteProjectDialog,
  projectDetails
}) {

  const { notify } = useUtils();
  const {
    deleteProject,
  } = useProject();
  const navigate = useNavigate();

  const handleDelete = () => {
    deleteProject(projectDetails.id);
    notify("project deleted");
    setOpenDeleteProjectDialog(false);
    navigate("/projects");
  };

  return (
    <Dialog
      show={openDeleteProjectDialog}
      onClose={() => setOpenDeleteProjectDialog(false)}
    >
      <Box sx={{p: 20}}>
        <Type
          var="h5"
          sx={{
            c: "type",
            fw: 700,
            lh: 30,
          }}
        >
          Delete {projectDetails?.name} ?
        </Type>
        <Type var="subtitle" sx={{ c: "type", fs: "14px", ff: "secondary" }}>
          Are you sure about that? this can't be undone!
        </Type>
      </Box>

      <Flex
        pos={{ b: 0, r: 0 }}
        dir="row"
        gap={10}
        jc="flex-end"
        sx={{
          p: 10,
          w: "100%",
          bgc: "babyBlue",
          br: "0 0 4px 4px",
        }}
      >
        <Button variant="outlined" onClick={() => setOpenDeleteProjectDialog(false)}>
          Cancel
        </Button>
        <Button onClick={handleDelete}>Approve</Button>
      </Flex>
    </Dialog>
  );
}
