import styled, { css } from "styled-components";
import Mask from "./Mask";
import isPropValid from "@emotion/is-prop-valid";

const Wrap = styled.div
  .withConfig({
    shouldForwardProp: (prop) => isPropValid(prop),
  })
  .attrs((p) => ({
    delay: p.delay || 0.1,
  }))`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: ${(p) => (p.show ? "all" : "none")};
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  .content {
    min-width: 600px;
    z-index: 99999;
    background-color: ${(p) => p.theme.color.white};
    border-radius: 6px;
    padding: 20px;
    ${(p) =>
      (p.x || p.y) &&
      css`
        position: absolute;
        top: ${(p) => p.y}px;
        left: ${(p) => p.x}px;
      `}

    transition-property: transform, opacity;
    transition-duration: 0.1s !important;
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
    transition-delay: ${(p) => (p.show ? p.delay : 0.02)}s;
    transform-origin: center;
    transform: scale(${(p) => (p.show ? 1 : 0.7)});
    opacity: ${(p) => Number(p.show)};
    @media only screen and (max-width: 768px) {
    min-width:80%
    };
  }
`;

function Modal({ children, show, onClose, className, ...props }) {
  return (
    <Wrap
      {...props}
      show={show}
      role="dialog"
      className={`${className} ${show ? "active" : ""}`}
    >
      <div className="content">{children}</div>
      <Mask show={show} onClick={onClose ? onClose : undefined} />
    </Wrap>
  );
}

export default Modal;
