const NO_UNIT_ATTR = [
  "grid-column-start",
  "grid-column-end",
  "grid-row",
  "grid-column",
  "opacity",
  "font-weight",
  "z-index",
];

const SIDES = {
  b: "bottom",
  t: "top",
  r: "right",
  l: "left",
};

function _appendUnit(v, attrName) {
  return typeof v === "number" && !NO_UNIT_ATTR.includes(attrName)
    ? v + "px"
    : v;
}

export function buildRule(attr, value = null, defaultValue = "inherit") {
  if (!value && value !== 0) return `${attr}: ${defaultValue};`;
  if (typeof value === "string" || typeof value === "number")
    return `${attr}: ${_appendUnit(value, attr)};`;
  // else, we consider it an array
  else
    return `
  ${attr}: ${
      typeof value[0] === "number" && !NO_UNIT_ATTR.includes(attr)
        ? value[0] + "px"
        : value[0]
    };
  @media only screen and (max-width: 1200px) {
    ${attr}: ${
      typeof value[1] === "number" && !NO_UNIT_ATTR.includes(attr)
        ? value[1] + "px"
        : value[1] === "_"
        ? value[0]
        : value[1]
    };
  }
  @media only screen and (max-width: 992px) {
    ${attr}: ${
      typeof value[2] === "number" && !NO_UNIT_ATTR.includes(attr)
        ? value[2] + "px"
        : value[2] === "_"
        ? value[1]
        : value[2]
    };
  }
  @media only screen and (max-width: 768px) {
    ${attr}: ${
      typeof value[3] === "number" && !NO_UNIT_ATTR.includes(attr)
        ? value[3] + "px"
        : value[3] === "_"
        ? value[2]
        : value[3]
    };
        }
    `;
}

// prettier-ignore
function stylesBlocks(props) {

  function checkColor(c) {
    if(typeof c !== 'string') return;
    if(!props.theme.color[c]) return c;
    else return props.theme.color[c]
  }

  let s = "";

  if (props.center) s += "margin: 0 auto;";
  if (props.relative) s += "position: relative;";
  if (props.abs) {
    s += buildRule("position", "absolute");
    if(props.abs?.t || typeof props.abs.t === "number") s += buildRule("top", props.abs.t);
    if(props.abs?.b || typeof props.abs.b === "number") s += buildRule("bottom", props.abs.b);
    if(props.abs?.l || typeof props.abs.l === "number") s += buildRule("left", props.abs.l);
    if(props.abs?.r || typeof props.abs.r === "number") s += buildRule("right", props.abs.r);
  }

  Object.keys(props.sx || {}).forEach((sx) => {
    if (sx ==="my")
      s +=
        buildRule("margin-top", props.sx.my) +
        buildRule("margin-bottom", props.sx.my);
  
    if (sx ==="mx")
      s +=
        buildRule("margin-left", props.sx.mx) +
        buildRule("margin-right", props.sx.mx);
  
    if (sx ==="mt") s += buildRule("margin-top", props.sx.mt);
    if (sx ==="mb") s += buildRule("margin-bottom", props.sx.mb);
    if (sx ==="ml") s += buildRule("margin-left", props.sx.ml);
    if (sx ==="mr") s += buildRule("margin-right", props.sx.mr);
    if (sx ==="m") s += buildRule("margin", props.sx.m);
  
    if (sx ==="py")
      s +=
        buildRule("padding-top", props.sx.py) +
        buildRule("padding-bottom", props.sx.py);
  
    if (sx ==="px")
      s +=
        buildRule("padding-left", props.sx.px) +
        buildRule("padding-right", props.sx.px);
  
    if (sx ==="pt") s += buildRule("padding-top", props.sx.pt);
    if (sx ==="pb") s += buildRule("padding-bottom", props.sx.pb);
    if (sx ==="pl") s += buildRule("padding-left", props.sx.pl);
    if (sx ==="pr") s += buildRule("padding-right", props.sx.pr);
    if (sx ==="p") s += buildRule("padding", props.sx.p);
    if (sx ==="ff") s += buildRule("font-family", props.theme?.fonts[props.sx.ff]);
    if (sx ==="fs") s += buildRule("font-size", props.sx.fs);
    if (sx ==="fw") s += buildRule("font-weight", props.sx.fw);
    if (sx ==="lh") s += buildRule("line-height", props.sx.lh);
    if (sx ==="fstyle") s += buildRule("font-style", props.sx.fstyle);
    if (sx ==="text") s += buildRule("text-transform", props.sx.text);
    if (sx ==="ta") s += buildRule("text-align", props.sx.ta);
    if (sx ==="c") s += buildRule("color", checkColor(props.sx.c));
    if (sx ==="bgc") s += buildRule("background-color", checkColor(props.sx.bgc));
    if (sx ==="bgsize") s += buildRule("background-size", props.sx.bgsize);
    if (sx ==="d") s += buildRule("display", props.sx.d);
    if (sx ==="fl") s += buildRule("float", props.sx.fl);
    if (sx ==="w") s += buildRule("width", props.sx.w);
    if (sx ==="gap") s += buildRule("gap", props.sx.gap);
    if (sx ==="grow") s += buildRule("flex-grow", props.sx.grow);
    if (sx ==="jc") s += buildRule('justify-content', props.sx.jc);
    if (sx ==="ai") s += buildRule('align-items', props.sx.ai);
    if (sx ==="sqr")
      s += buildRule("width", props.sx.sqr) + buildRule("height", props.sx.sqr);
  
    /** 
     * An alternative experimental syntax for handling screen size responsivity, only work on **`mw`** property. 
     * It reply on 2 dimentional array to allow user to specify the screen resolution in pixel.
     * e.g. **`sx={{mw: [[800, 100], [1024, 150]]}}`**
     * 
     * **Note**: that this property is deprecated and may be removed from feature updates, use **`mxw`**
    */
    // if (sx ==="mw" && sx ==="w === "object") {
    //   props.sx.mw.forEach((bp: number[]) => {
    //     s += `@media only screen and (max-width: ${bp[0]}px) { max-width: ${
    //       !isNaN(bp[1]) ? bp[1] + "px" : bp[1]
    //     } }`;
    //   });
  
    // } else 
    if (sx ==="mw") s += buildRule("max-width", props.sx.mw);
    if (sx ==="maxw") s += buildRule("max-width", props.sx.maxw);
    if (sx ==="mxw") s += buildRule("max-width", props.sx.mxw);
    if (sx ==="minw") s += buildRule("min-width", props.sx.minw);
    if (sx ==="mnw") s += buildRule("min-width", props.sx.mnw);
    if (sx ==="h") s += buildRule("height", props.sx.h);
    if (sx ==="mh") s += buildRule("max-height", props.sx.mh);
    if (sx ==="mnh") s += buildRule("min-height", props.sx.mnh);
    if (sx ==="minh") s += buildRule("min-height", props.sx.mnh);
    if (sx ==="mxh") s += buildRule("max-height", props.sx.mxh);
    if (sx ==="maxh") s += buildRule("max-height", props.sx.maxh);
    if (sx ==="cursor") s += buildRule('cursor', props.sx.cursor);
    if (sx ==="of") s += buildRule('overflow', props.sx.of);
    if (sx ==="ofx") s += buildRule('overflow-x', props.sx.ofx);
    if (sx ==="ofy") s += buildRule('overflow-y', props.sx.ofy);
    if (sx ==="tr") s += buildRule('transition', props.sx.tr);
    if (sx ==="transform") s += buildRule('transform', props.sx.transform);
    if (sx ==="bc") s += buildRule('border-color', checkColor(props.sx.bc));
    if (sx ==="bs") s += buildRule('border-style', props.sx.bs);
    if (sx ==="br") s += buildRule('border-radius', props.sx.br);
    if (sx ==="bw") s += buildRule('border-width', props.sx.bw);
    if (sx ==="border") {
      if(props.sx?.border.length === 5) {
        const sides = props.sx?.border[4].split('').map((side) => SIDES[side]);
  
        const a = sides.map(side => 
          buildRule(
            'border-' + side,
            `${props.theme?.color[props.sx.border[0]]} ${_appendUnit(props.sx?.border?.[1], 'border-width')} ${props.sx?.border?.[2]}`
          ))
  
        s +=  a.join('');
        s +=  buildRule('border-radius', props.sx.border[3] || 0);
      } else {
        s +=  buildRule(
          'border',
          `${props.theme?.color[props.sx.border[0]]} ${_appendUnit(props.sx.border[1], 'border-width')} ${props.sx.border[2]}`
        ) +
              buildRule('border-radius', props.sx.border[3] || 0)
      }
    }
      
    if (sx ==="op") s += buildRule('opacity', props.sx.op);
    if (sx ==="gtc") s += buildRule('grid-template-columns', props.sx.gtc);
    if (sx ==="gtr") s += buildRule('grid-template-rows', props.sx.gtr);
    if (sx ==="gcs") s += buildRule('grid-column-start', props.sx.gcs);
    if (sx ==="gce") s += buildRule('grid-column-end', props.sx.gce);
    if (sx ==="grs") s += buildRule('grid-row-start', props.sx.grs);
    if (sx ==="gre") s += buildRule('grid-row-end', props.sx.gre);
    if (sx ==="gr") s += buildRule('grid-row', props.sx.gr);
    if (sx ==="gc") s += buildRule('grid-column', props.sx.gc);
    if (sx ==="pos") s += buildRule('position', props.sx.pos);
    if (sx ==="left") s += buildRule('left', props.sx.left);
    if (sx ==="right") s += buildRule('right', props.sx.right);
    if (sx ==="top") s += buildRule('top', props.sx.top);
    if (sx ==="bottom") s += buildRule('bottom', props.sx.bottom);
    if (sx ==="z") s += buildRule('z-index', props.sx.z);
    if (sx ==="shadow") s += buildRule('box-shadow', props.sx.shadow);
    if (sx ===":hover") s += `&:hover { ${stylesBlocks({theme: props.theme, sx: props.sx[':hover']})} };`;
    if (sx ==="print") s += `@media print { ${stylesBlocks({theme: props.theme, sx: props.sx.print})} };`;
  })

  return s;
}

export default stylesBlocks;
