import { useCallback } from "react";

const { useNavigate } = require("react-router-dom");
const { Box, Select } = require("ui");
const { useProject } = require("views/project/provider");

export default function ProjectsList({label,width}) {
  const navigate = useNavigate();

  const {
    state: { list: projects, selected: project },
    dispatch,
  } = useProject();

  const handleSelectProject = useCallback(
    ({ value }) => {
      if (value === "all") return navigate(`/projects`);

      dispatch({
        type: "set_selected",
        payload: projects.find((p) => p.id === value),
      });
    },
    [dispatch, navigate, projects]
  );


  return (
    <Box as="ul" sx={{ m: 0, p: 0,w:"100%" }} gap={10} jc="flex-start">
      <Select
        label={label?label:""}
        size="small"
        data={projects}
        mapData={{ label: "name", value: "id" }}
        search={false}
        style={{ width: width?width:180 }}
        onChange={handleSelectProject}
        value={project?.id}
        defaultValue={project?.id}
      />
    </Box>
  );
}
