import { InputRadio, Type } from "ui";
import { AlignedButton, FlexStyled, StyledBox } from "../../../Components";
import { useState } from "react";

function SpamProtection({ edit, selectedForm })  {
  const [enableFiltration, setEnableFiltration] = useState(selectedForm?.enableFiltration);

  const handleEdit = (e) => {
    e.preventDefault();
    const data = {
      enableFiltration: enableFiltration,
    };
    edit(data);
  };

  return (
    <>
      <FlexStyled style={{ justifyContent: "flex-start" }}>
        <InputRadio
          toggleButton={true}
          checked={enableFiltration}
          inputProps={{
            checked: enableFiltration,
            onChange: () => setEnableFiltration(!enableFiltration),
          }}
        />
        <StyledBox>
          <Type>Formshield</Type>
          <Type variant="subtitle">
            Machine learning spam filtering that scans submissions for spam before
            they reach your inbox.  Adjust settings →
          </Type>
        </StyledBox>
      </FlexStyled>
      <AlignedButton onClick={(e) => handleEdit(e)}>Save</AlignedButton>
    </>
  );
}
export default SpamProtection;
