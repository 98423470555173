import {
  useContext,
  useReducer,
  createContext,
  useCallback,
  useState,
} from "react";
import { useRequest } from "../../services/request";

const Context = createContext();

export function useProject() {
  return useContext(Context);
}

const initState = {
  projectDetails: null,
  selected: null,
  list: [],
  status: "idle",
  error: null,
  formsCount:null,
};
const reducer = (state, action) => {
  switch (action.type) {
    case "add":
      const newList = [{ ...action.payload }, ...state.list];
      return { ...state, list: newList };
    case "set_list":
      return { ...state, list: [...action.payload] };
    case "set_project_details":
      return { ...state, projectDetails: action.payload };
    case "set_selected":
      return { ...state, selected: { ...action.payload } };
    case "edit_project_details":
      return { ...state, projectDetails: action.payload };
    case "delete":
      const updatedList = state.list.filter(
        (item) => item.id !== action.payload
      );
      return { ...state, list: updatedList };
    case "status":
      return { ...state, status: action.payload };
    case "set_forms_count":
      return { ...state, formsCount: [action.payload] };
    default:
      throw new Error(`Invalid dispatch type: ${action.type}`);
  }
};
export default function ProjectFormProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initState);
  const req = useRequest();
  const [selectedProject, setSelectedProject] = useState(null);

  const fetchList = useCallback(
    async (page = 0, limit = 10) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: "fetching" });
        try {
          const query = `page=${page + 1}&limit=${limit}`;
          const resData = await req(`project/?${query}`, null, {}, true);
          dispatch({ type: "set_list", payload: resData.items });
          resolve(null);
        } catch (error) {
          console.log(error);
          reject(error);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );

  const create = useCallback(
    async (data) => {
      return new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: "status", payload: `creating` });
          const resData = await req(`project`, data, { method: "POST" }, true);
          dispatch({ type: "add", payload: resData });
          resolve(resData);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );

  const fetchProjectDetails = useCallback(
    async (projectId) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: "fetching" });
        try {
          const resData = await req(`project/${projectId}`, null, {}, true);
          dispatch({ type: "set_project_details", payload: resData });
          resolve(null);
        } catch (error) {
          console.log(error);
          reject(error);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );
  const deleteProject = useCallback(
    async (projectId) => {
      return new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: "status", payload: `deleting` });
          const resData = await req(
            `project/${projectId}`,
            {},
            { method: "DELETE" },
            true
          );
          dispatch({ type: "delete", payload: projectId });
          resolve(resData);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );
  const editProjectDetails = useCallback(
    async (data, projectId) => {
      return new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: "status", payload: `editing` });
          const resData = await req(
            `project/${projectId}`,
            data,
            { method: "PATCH" },
            true
          );
          dispatch({ type: "edit_project_details", payload: resData });
          console.log(resData);
          resolve(resData);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );
  const deleteDomain = useCallback(
    async (projectId, domainName) => {
      return new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: "status", payload: `deleting` });
          const resData = await req(
            `project/${projectId}/${domainName}`,
            {},
            { method: "DELETE" },
            true
          );
          dispatch({ type: "edit_project_details", payload: resData });
          resolve(resData);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );
  const AddDomain = useCallback(
    async (data, projectId) => {
      return new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: "status", payload: `creating` });
          const resData = await req(
            `project/${projectId}/allowed-domain`,
            data,
            { method: "POST" },
            true
          );
          dispatch({ type: "edit_project_details", payload: resData });
          resolve(resData);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );
  const getFormsCount = useCallback(
    async (projectId) => {
      return new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: "status", payload: `fetching` });
          const resData = await req(
            `project/${projectId}/form/count`, null, {}, true
          );
          dispatch({ type: "set_forms_count", payload: resData });
          resolve(resData);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );
  return (
    <Context.Provider
      value={{
        state,
        dispatch,
        fetchList,
        create,
        selectedProject,
        setSelectedProject,
        fetchProjectDetails,
        deleteProject,
        editProjectDetails,
        deleteDomain,
        AddDomain,
        getFormsCount
      }}
    >
      {children}
    </Context.Provider>
  );
}
