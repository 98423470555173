import { useContext, useReducer, createContext, useCallback } from "react";
import { useRequest } from "../../services/request";

const Context = createContext();

export function useAdmin() {
  return useContext(Context);
}

const initState = {
  users: {
      count: null,
      since: ""
  },
  usersList: [], 
  status: "idle",
  settings : {},
};

const reducer = (state, action) => {
  switch (action.type) {
    case  "set_users":
    return { ...state,  users: { ...state.users, count: action.payload.count, since: action.payload.since }}; 
    case "status":
        return { ...state, status: action.payload };
    case "set_Users_List":
      return { ...state, usersList:  [...action.payload.items]  };  
    case "delete_User":
      const updatedList = state.usersList.filter(item => item.id !== action.payload);
      return { ...state, usersList:  updatedList };  
    case "set_settings":
      return { ...state, settings: { ...action.payload } };
    case "edit_settings":
      return { ...state, settings: { ...action.payload } };
    default:
      throw new Error(`Invalid dispatch type: ${action.type}`);
  }
};

export default function SubmissionFormsProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initState);
  const req = useRequest();

  const FetchUsersCount = useCallback(
    async (since = "" ) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: "fetching" });
        try {
          const query = `since=${since}`;
          const resData = await req(`user/count?${query}`, null, {}, true);
          dispatch({ type: "set_users", payload:{count: resData.count,since:since }});
          resolve(resData);
        } catch (error) {
          reject(error);
          console.log(error);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );
  const FetchUsersList = useCallback(
    async (page = 0 , limit = 10  ,order='desc') => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: "fetching" });
        try {
          const query = `page=${page + 1}&limit=${limit}&order=${order}`;
          const resData = await req(`user?${query}`, null, {}, true);
          dispatch({ type: "set_Users_List", payload:{ items: resData.items}});
          resolve(resData);
          console.log(resData);
        } catch (error) {
          reject(error);
          console.log(error);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );
  const deleteUser = useCallback(
    async (id) => {
      return new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: "status", payload: `deleting` });
          const resData = await req(
            `user/${id}`,
            {},
            { method: "DELETE" },
            true
          );
          dispatch({ type: "delete_User", payload: id });
          resolve(resData);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );
  const FetchSettings = useCallback(
    async ( ) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: "fetching" });
        try {
          const resData = await req(`settings`, null, {}, true);
          dispatch({ type: "set_settings", payload:{ items: resData}});
          resolve(resData);
        } catch (error) {
          reject(error);
          console.log(error);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );
  const EditSettings = useCallback(
    async (data) => {
      return new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: "status", payload: `editing` });
          const resData = await req(
            `settings`,
            data,
            { method: "PATCH" },
            true
          );
          dispatch({ type: "edit_settings", payload:{ items: resData} });;
          resolve(resData);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );
  return (
    <Context.Provider
      value={{
        state,
        dispatch,
        FetchUsersCount,
        FetchUsersList,
        deleteUser,
        FetchSettings,
        EditSettings
      }}
    >
      {children}
    </Context.Provider>
  );
}
