import styled, { css } from "styled-components";
import Input from "./Input";
import Label from "./Label";
import Type from "./Type";
import Icon from "./Icon";
import stylesBlocks from "./core/stylesBuilder";

const Wrapper = styled.div`
  margin-bottom: 10px;
  ${(p) =>
    p.space &&
    css`
      margin-bottom: ${(p) => p.space};
    `}
  .inputWrap {
    position: relative;
    &.icon-left {
      input {
        padding-left: 45px;
      }
    }
    &.icon-right {
      input {
        padding-right: 40px;
      }
    }
    > span {
      position: absolute;
      /* background-color: #ffffffcc; */
      height: 100%;
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      &.left {
        left: 0;
      }
      &.right {
        right: 0;
      }
      top: 50%;
      transform: translate(0, -50%);
      pointer-events: none;
      &.clickable {
        pointer-events: auto;
        cursor: pointer;
      }
      line-height: 0;
      svg {
        width: 16px;
        fill: ${(p) => p.theme.color.gray700};
      }
    }
  }
  .prefix {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    pointer-events: none;
  }
  ${stylesBlocks}
`;

function InputText({
  prefix,
  label,
  value,
  name,
  id,
  onChange,
  placeholder = "",
  type,
  msg,
  error = false,
  iconLeft,
  iconRight,
  size = "large",
  inputProps,
  ...props
}) {
  return (
    <Wrapper {...props} className={`${error && "error"} ${(iconLeft || iconRight) && 'icon'}`}>
      {label && <Label htmlFor={id || inputProps?.id}>{label}</Label>}
      <div
        className={`inputWrap ${iconLeft && "icon-left"} ${
          iconRight && "icon-right"
        }`}
      >
        {iconLeft && (
          <span
            className={`left ${typeof iconLeft !== "string" && "clickable"}`}
            onClick={typeof iconLeft === "string" ? undefined : iconLeft[1]}
          >
            <Icon
              name={typeof iconLeft === "string" ? iconLeft : iconLeft[0]}
            />
          </span>
        )}
        {iconRight && (
          <span
            className={`right ${typeof iconRight !== "string" && "clickable"}`}
            onClick={typeof iconRight === "string" ? undefined : iconRight[1]}
          >
            <Icon
              name={typeof iconRight === "string" ? iconRight : iconRight[0]}
            />
          </span>
        )}
        {prefix && <span className="prefix">{prefix}</span>}
        <Input
          as={type === "textarea" ? "textarea" : "input"}
          id={id || inputProps?.id}
          aria-label={id || inputProps?.id || label || placeholder}
          {...{
            prefix,
            value,
            type,
            name,
            onChange,
            placeholder,
            $size: size,
            ...inputProps,
          }}
        />
      </div>
      {msg && (
        <Type sx={{ c: error ? "red" : "gray600", mt: 2, fs: "12px" }}>
          {msg}
        </Type>
      )}
    </Wrapper>
  );
}

export default InputText;
