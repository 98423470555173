import { useSubmissionForm } from "./provider";
import { useEffect, useState } from "react";
import ProjectForm from "views/project/form";
import Modal from "ui/Modal";
import { Box, Flex, Icon, Slider, Tooltip, Type } from "ui";
import { useProject } from "views/project/provider";
import FormCard from "./formList/FormCard";
import ProjectsList from "./formList/ProjectsList";
import DeleteFormDialog from "./formList/DeleteFormDialog";
import Dropdown from "ui/core/Dropdown";

function FormsList() {
  const {
    state: { forms },
    fetchList: fetchForms,
  } = useSubmissionForm();

  const [createProject, setcreateProject] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const {
    state: { list: projects, selected: project },
    dispatch: dispatchProject,
  } = useProject();

  useEffect(() => {
    dispatchProject({ type: "set_selected", payload: projects[0] });
  }, [projects, dispatchProject]);

  useEffect(() => {
    if (!project) return;
    fetchForms(0, 10, project.id);
  }, [fetchForms, project]);

  if (!project) return;

  return (
    <>
      <Box sx={{ p: 20 }}>
        <Type var="h4" sx={{ d: ["none", "_", "_", "block"], mb: 20 }}>
          All Forms
        </Type>
        <Flex jc="space-between" gap={20} sx={{ mb: 30 }}>
          <Type var="h4" sx={{ d: ["block", "_", "_", "none"] }}>
            All Forms
          </Type>

          <Flex gap={15}>
            <ProjectsList />
            <Dropdown
              content={<Tooltip>Add New Project</Tooltip>}
              click={false}
              space={-6}
              delay={0.4}
            >
              <Box onClick={() => setcreateProject(true)} sx={{ lh: 0 }}>
                <Icon name="plus" hover="primary" w={18} rounded c="primary" />
              </Box>
            </Dropdown>
          </Flex>
          {/* <Button
            onClick={() => setcreateProject(true)}
            iconName={"Plus"}
            size="small"
            variant="outlined"
            sx={{
              bs: "dashed",
            }}
          >
            Add new project
          </Button> */}
        </Flex>

        {forms.list.length !== 0 ? (
          <>
            <Slider
              slides={forms.list.map((form, i) => (
                <FormCard
                  id={"card-" + i}
                  key={form.id}
                  {...{
                    setOpenDeleteDialog,
                    form,
                    projectId: forms.projectId,
                  }}
                />
              ))}
              totalView={3}
            />

            {/* <a href="#card-4">2</a> */}
          </>
        ) : (
          <Flex sx={{ py: 60 }}>
            <Type var="subtitle" sx={{ c: "gray600", fs: 14 }}>
              There is no forms in this project!
            </Type>
          </Flex>
        )}
      </Box>

      <Modal onClose={() => setcreateProject(false)} show={createProject}>
        <ProjectForm
          setcreateProject={setcreateProject}
          onBack={() => setcreateProject(false)}
          onSuccess={() => {
            window.scrollTo({
              top: 200,
              left: 0,
              behavior: "smooth",
            });
            setcreateProject(false);
          }}
        />
      </Modal>

      <DeleteFormDialog
        {...{
          setOpenDeleteDialog,
          openDeleteDialog,
          projectId: forms.projectId,
        }}
      />
    </>
  );
}

export default FormsList;
