import styled, { css } from "styled-components";

export default styled.div`
  height: 48px;
  ${(p) => {
    if (p.size === "medium")
      return css`
        height: 40px;
      `;
    else if (p.size === "small")
      return css`
        height: 34px;
      `;
  }}
  position: relative;
  background-color: ${(p) => p.theme.color.transparent};
  outline: ${(props) => props.theme.color.babyBlue} 1px solid;
  /* background-color: transparent; */
  /* border: ${(p) => p.theme.color.gray300} 1px solid; */
  border-radius: 4px;
  /* padding: 22px 40px 22px 18px; */
  transition: all 0.15s cubic-bezier(0.4, 0, 1, 1);
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  display: flex;
  align-items: center;
  transform-origin: center;
  font-size: ${(p) => p.size === "small" ? '14px' : '16px'};
  color: ${(props) => props.theme.color.gray800};
  .error & {
    border-color: ${(props) => props.theme.color.red};
  }
  &:hover {
    border-color: ${(props) => props.theme.color.primary};
  }
  &:focus,
  &:active {
    outline: ${(p) => p.theme.color.darkBlue} 1px solid;
    background-color: ${(p) => p.theme.color.transparent};
  }
  // arrow
  &::before {
    content: "";
    position: absolute;
    top: calc(50% - 3px);
    right: ${(p) => (p.size === "small" ? "14px" : "20px")};
    width: 6px;
    height: 6px;
    border-left: ${(p) => p.theme.color.gray900} 1px solid;
    border-bottom: ${(p) => p.theme.color.gray900} 1px solid;
    transition: all 0.15s cubic-bezier(0.4, 0, 1, 1);
    transform: rotate(-45deg);
    transform-origin: center;
    ${(props) =>
      props.open &&
      css`
        transform: rotateX(180deg) rotate(-45deg);
      `}
  }

  &::after {
    content: "";
    position: absolute;
    top: 20%;
    right: 60px;
    width: 1px;
    height: 60%;
    background-color: ${(props) => props.theme.color.gray300};
  }

  ${(props) =>
    props.noSeperator &&
    css`
      &::after {
        content: none;
      }
      padding-right: 20px;
    `}

  ${(props) =>
    props.isValStr &&
    css`
      span {
        display: inline-block;
        padding: 10px 18px;
      }
    `}
`;
