import {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from "react";
import { useLocation, Navigate } from "react-router-dom";
import { useRequest } from "./request";
import adminUsers from "views/Admin/AdminUsers";
// import { useProfile } from "views/Account/provider";

const AuthContext = createContext(null);

export function useAuth() {
  return useContext(AuthContext);
}

export default function AuthProvider({ children }) {
  let [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const req = useRequest();
  // let{editUserInfo,proUser:ProfileUser}=useProfile()

  useEffect(() => {
    if (user) return;
    const localUser = localStorage.getItem("user");
    if (localUser) setUser(JSON.parse(localStorage.getItem("user")));
  }, [user]);

  // function changeCurrentUser(user) {
  //   console.log(user, "user...1");
  //   user.user.permissions = user.user.roles?.reduce(
  //     (a, b) => [...a, ...Object.keys(b.permissions)],
  //     []
  //   );

  //   delete user.user.roles;
  //   window.localStorage.setItem("user", JSON.stringify(user));
  //   setUser(user);
  //   console.log(user, "user...1");
  // }

  let signin = useCallback(
    (data) => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await req(`auth/sign-in`, data);
          window.localStorage.setItem(
            "user",
            JSON.stringify({
              user: data.email,
              id: res.userId,
              accessToken: res.accessToken,
              refreshToken: res.refreshToken,
            })
          );

          setUser({
            user: data.email,
            id: res.userId,
            accessToken: res.accessToken,
            refreshToken: res.refreshToken,
            config: res.config,
          });

          // changeCurrentUser({user:data.email, accessToken:res.accessToken,refreshToken:res.refreshToken});
          resolve(res);
        } catch (error) {
          reject(error);
          console.log("error", error);
        }
      });
    },
    [req]
  );

  const [email, setEmail] = useState("");

  let createAccount = useCallback(
    (data) => {
      return new Promise(async (resolve, reject) => {
        try {
          const resData = await req(
            `auth/sign-up`,
            data,
            { method: "POST" },
            false
          );
          window.localStorage.setItem(
            "user",
            JSON.stringify({
              user: data.email,
              accessToken: resData.accessToken,
              refreshToken: resData.refreshToken,
            })
          );

          setUser({
            user: data.email,
            accessToken: resData.accessToken,
            refreshToken: resData.refreshToken,
            config: resData.config,
          });
          resolve(resData);

          setEmail(data.email);
        } catch (error) {
          reject(error);
          console.log(error);
        }
      });
    },
    [req]
  );

  let changePass = useCallback(
    (currentPassword, newPassword) => {
      //data is passwordNew and current
      return new Promise(async (resolve, reject) => {
        try {
          const forx = {
            currentPassword: currentPassword,
            newPassword: newPassword,
          };

          const resData = await req(
            `auth/change-password`,
            forx,
            { method: "POST" },
            true
          );

          resolve(resData);
        } catch (error) {
          reject(error);
        }
      });
    },
    [req]
  );

  let forgotPass = useCallback(
    (data) => {
      //data is email
      return new Promise(async (resolve, reject) => {
        try {
          const resData = await req(
            `auth/forgot-password`,
            data,
            { method: "POST" },
            false
          );

          resolve(resData);
        } catch (error) {
          reject(error);
        }
      });
    },
    [req]
  );

  // const resetPass = useCallback(
  //   (newPassword, token) => {
  //     //data is email
  //     return new Promise(async (resolve, reject) => {
  //       try {
  //         const resData = await req(
  //           `auth/reset-password`,
  //           { newPassword, token },
  //           { method: "POST" },
  //           false
  //         );

  //         resolve(resData);
  //       } catch (error) {
  //         reject(error);
  //       }
  //     });
  //   },
  //   [req]
  // );

  const verify = useCallback(
    (email, verifyToken) => {
      return new Promise(async (resolve, reject) => {
        try {
          await req(
            `auth/email/verify`,
            { email, verifyToken },
            { method: "POST" },
            false
          );
          resolve();
        } catch (error) {
          console.log(error);
          reject(error);
        }
      });
    },
    [req]
  );

  // const [profile, setProfile] = useState([]);

  let signinByGoogle = useCallback(
    (code) => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await req(
            `google-auth`,
            { code: code },
            { method: "POST" },
            false
          );
          window.localStorage.setItem(
            "user",
            JSON.stringify({
              id: res.userId,
              user: res.userId,
              accessToken: res.accessToken,
              refreshToken: res.refreshToken,
            })
          );

          setUser({
            id: res.userId,
            user: res.userId,
            accessToken: res.accessToken,
            refreshToken: res.refreshToken,
            config: res.config,
          });
          resolve(res);
        } catch (error) {
          reject(error);
          console.log("error", error);
        }
      });
    },
    [req]
  );

  const [resendStatus, setResendStatus] = useState("");
  const [resendMessage, setResendMessage] = useState("");

  let resendVerification = useCallback(
    (data) => {
      return new Promise(async (resolve, reject) => {
        let verificationEmail = { email: data };
        try {
          const res = await req(
            `auth/email/resend-verification`,
            verificationEmail,
            { method: "POST" },
            true
          );
          resolve(res);

          setResendStatus(res.status);
          setResendMessage(res.message);
        } catch (error) {
          reject(error);
        }
      });
    },
    [req]
  );
  const saveTokens = useCallback(
    (newTokens) => {
      const userTokens = {
        ...user,
        tokens: { ...user.refreshToken, ...newTokens },
      };
      window.localStorage.setItem("user", JSON.stringify(userTokens));
      setUser(userTokens);
    },
    [user]
  );

  let signout = useCallback((redirect = true) => {
    return new Promise((resolve) => {
      window.localStorage.removeItem("user");
      setUser(null);
      // if (redirect) window.location.href = "/";
      resolve();
    });
  }, []);
  let signout1 = useCallback((redirect = true) => {
    return new Promise((resolve) => {
      window.localStorage.removeItem("user");
      setUser(null);
       if (redirect) window.location.href = "/";
      resolve();
    });
  }, []);

  let value = {
    user,
    signin,
    forgotPass,
    signout,
    signout1,
    changePass,
    verify,
    saveTokens,
    createAccount,
    resendVerification,
    resendMessage,
    resendStatus,
    email,
    signinByGoogle,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

// export function RequireAuth({ children }) {
//   const location = useLocation();
//   let { user } = useAuth();

//   if (!user) return <Navigate to="/login" state={{ from: location }} replace />;

//   if (
//     user.user.manufacturer?.identity_address === "" &&
//     location.pathname !== "/wallet/create" &&
//     location.pathname !== "/wallet/unlock"
//   )
//     return <Navigate to="/wallet/create" replace />;

//   return <Navigate to="/no-access" replace />;
// }
export function RequireAuth({ children }) {
  const location = useLocation();
  let { user } = useAuth();

  if (!user?.accessToken)
    return <Navigate to="/login" state={{ from: location }} replace />;
  //return <Navigate to="/" replace />;
  return children;
}
export function RequireAdminAuth({ children }) {
  const location = useLocation();
  let { user } = useAuth();
  if (!user?.accessToken || !adminUsers.includes(user?.user))
    return <Navigate to="/adminLogin" state={{ from: location }} replace />;
  //return <Navigate to="/" replace />;
  return children;
}
