import styled from "styled-components";
import { Box, Button, Checkbox, Flex } from "ui";
import { useAdmin } from "../provider";

const StyledButton = styled(Button)`
  text-transform: uppercase;
  font-size: 12px;
  color: darkBlue;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  padding: 3px;
  &:hover,
  &:active ,
  &.active {
    color: white;
    background-color: darkBlue;
    svg path {
      fill: ${(props) => props.theme.color.white};
    }
    svg.export path {
      fill: ${(props) => props.theme.color.darkBlue};
      stroke: ${(props) => props.theme.color.white};
    }
  }
`;

const sortOptions = [
   {
      label: "Data (Newest first)",
      sortKey: "newestFirst",
    },
    {
      label: "Data (Oldest first)",
      sortKey: "oldestFirst",
    },
  ];

function TableHeader ({all,handleCheckAll,checkbox,cols}) {
  const {
    FetchUsersList,
  } = useAdmin();
    const handleSort = async (sortKey) => {
        if (!(sortKey==='spamFirst' || sortKey ==='spamLast')) {
          let order;
          if (sortKey === 'newestFirst'){
            order='desc'
          } else if (sortKey==='oldestFirst') {
            order='asc'
          }
          await FetchUsersList(0, 10 , order);
        }
      };
    return(
    <>
        <tr>
            <td
            colSpan={
                cols.length +
                (checkbox ? 1 : 0)
            }
            >
                <Box sx={{ br: "4px" }}>
                    <Flex
                    jc="space-between"
                    ai="center"
                    sx={{ p: "5px" }}
                    style={{ backgroundColor: "#f2f3f9" }}
                    >
                    <Checkbox
                        size="small"
                        label="select all"
                        id="all"
                        checked={all}
                        inputProps={{
                        checked: all,
                        onChange: handleCheckAll,
                        }}
                        style={{
                        margin: "0px",
                        backgroundColor: "white",
                        color: "darkBlue",
                        padding: "3px",
                        borderRadius: "4px",
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                        }}
                    />
                    <Flex gap="5">
                    
                        <StyledButton
                        size="small"
                        iconName="Sort"
                        className= "active"
                        >
                        Sort
                        </StyledButton>
                    </Flex>
                    </Flex>
                    <Flex
                    jc="flex-start"
                    gap="5"
                    sx={{ p: "5px" }}
                    style={{ backgroundColor: "#d7d9ed" }}
                    >
                            {sortOptions.map((option) => (
                            <StyledButton
                            key={option.sortKey}
                            size="small"
                            onClick={() => handleSort(option.sortKey)}
                            >
                            {option.label}
                            </StyledButton>
                        ))}

                    </Flex>
                </Box>
            </td>
        </tr>
        <tr style={{height:"20px"}}></tr>
        <tr>
            {checkbox && (
            <th></th>
            )}
            {cols.map((col) => (
            <th key={col.label} {...col.props}>
                {col.label}
            </th>
            ))}
        </tr>
    </>
    );
}
export default TableHeader