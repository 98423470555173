import Layout from "layout/Layout"
import { Flex, Icon , Type } from "ui"
import {  HeadingFlex, IconBakground, StyledBox } from "Components"
import Content from "./Content"

function Settings () {
    return(
        <Layout admin>
            <HeadingFlex
            style={{ marginBottom: "0px" }}
            >
                <IconBakground>
                    <Icon name={"Gear"} w="22" />
                </IconBakground>
                <StyledBox>
                    <Type>General Settings</Type>
                    <Type variant="subtitle">
                        Data retention,limit number of responses stc.
                    </Type>
                </StyledBox>
            </HeadingFlex>
            <Flex 
            dir={"column"}  
            ai="flex-start" 
           
            >
                <Content />
            </Flex>
        </Layout>
    )
}
export default Settings