import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Box, Button, Checkbox, Flex, InputText, Type } from "ui";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useAuth } from "services/auth";
import LayoutMasked from "layout/LayoutMasked";
import SocialMediaLogin from "./SocialMediaLogin";
import { omit } from "lodash";

const schema = Yup.object().shape({
  firstName: Yup.string().required("Required."),
  lastName: Yup.string().required("Required."),
  email: Yup.string().required("Required.").email("Invalid email."),
  password: Yup.string()
    .required("Required.")
    .min(4, "Too Short! at least 4 letters is required!"),
  acceptTerm: Yup.bool()
    .required()
    .oneOf([true], "Checkbox selection is required"),
});

function CrerateAcc() {
  const [showPass, setShowPass] = useState(false);
  const [error, setError] = useState([]);

  let navigate = useNavigate();
  const auth = useAuth();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (values) => {
    console.log("values", values);

    try {
      await auth.createAccount(omit(values, "acceptTerm"));
      reset();

      navigate("/accept");
    } catch (e) {
      window.scrollTo(0, 0);
      console.log("error", e);
      if (e.status === 400) setError(e.data.message);
      else if (e.status === 409) setError(["Email is already in use"]);
    }
  };

  return (
    <LayoutMasked
      title="Hi, let's get familiar."
      subtitle="Let's create forms and collect submissions"
    >
      <Box
        as="form"
        sx={{ w: [370, "_", "_", "100%"], br: 10, mb: "20px" }}
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <SocialMediaLogin />
        <Box sx={{ w: ["370", "_", "_", "90%"], mx: "auto" }}>
          <Flex
            role="alert"
            sx={{
              d: error.length ? "block" : "none",
              p: 5,
              bgc: "redBg",
              w: "100%",
              border: ["red", 10, "solid", 5, "r"],
              mb: 10,
            }}
          >
            {error.map((err) => (
              <Type>{err}</Type>
            ))}
          </Flex>

          <Flex jc="space-between" gap="10px">
            <InputText
              label="Name"
              error={!!errors.firstName}
              msg={errors.firstName?.message}
              inputProps={{
                ...register("firstName"),
                autoFocus: true,
                required: true,
              }}
            />
            <InputText
              label="SurName"
              error={!!errors.lastName}
              msg={errors.lastName?.message}
              inputProps={{
                ...register("lastName"),
                required: true,
              }}
            />
          </Flex>
          <InputText
            label="Email"
            error={!!errors.email}
            msg={errors.email?.message}
            inputProps={{
              ...register("email"),
              required: true,
            }}
          />
          <InputText
            label="Password"
            type={showPass ? "text" : "password"}
            iconRight={[
              showPass ? "eyeSlash" : "eye",
              () => setShowPass((showPass) => !showPass),
            ]}
            error={!!errors.password}
            msg={errors.password?.message}
            inputProps={{
              ...register("password"),
              required: true,
            }}
          />
          <Checkbox
            size="small"
            id="acceptTerm"
            error={!!errors.acceptTerm}
            msg={errors.acceptTerm?.message}
            label={
              <span>
                I have read and agreed to the Terms and Conditions, and Privacy
                Policy.
              </span>
            }
            inputProps={{
              ...register("acceptTerm"),
              required: true,
            }}
          />

          <Button type="submit" loading={isSubmitting} sx={{ w: "100%" }}>
            Register
          </Button>

          <Button
            variant="outlined"
            sx={{ w: "100%", mt: 15, mb: 80 }}
            onClick={() => navigate("/login")}
          >
            I have an account
          </Button>
        </Box>
      </Box>
    </LayoutMasked>
  );
}

export default CrerateAcc;
