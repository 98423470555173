import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Box, Button, InputText } from "../../ui";
import { useUtils } from "../../services/utilsProvider";
import { useAuth } from "../../services/auth";
import { useNavigate } from "react-router-dom";
import LayoutMasked from "layout/LayoutMasked";

const schema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email")
    .required("The email field is required"),
});

function ForgotPassForm() {
  const { notify } = useUtils();
  let auth = useAuth();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (values) => {
    try {
      const res = await auth.forgotPass(values);

      if (res.status === "success") {
        notify(res.message, "success");
      }
    } catch (error) {
      console.log("error", error);
      notify("An error has occured please try again.", "alert");
    }
  };

  return (
    <LayoutMasked
      title="Forgot your password, don't worry"
      subtitle="You'll get an email to reset your password"
    >
      <Box
        as="form"
        sx={{ w: 370, bgc: "white", br: 10, mt: 20 }}
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <InputText
          label="User Name or E-mail address"
          error={!!errors.email}
          msg={errors.email?.message}
          inputProps={{
            ...register("email"),
            autoFocus: true,
            required: true,
          }}
        />

        <Button
          type="submit"
          loading={isSubmitting}
          size="large"
          sx={{ w: "100%", mt: 15 }}
        >
          Send Recovery Email
        </Button>
        <Button
          variant="outlined"
          sx={{ w: "100%", mt: 15 }}
          onClick={() => navigate('/login')}
        >
          I think I remember my password
        </Button>
      </Box>
    </LayoutMasked>
  );
}

export default ForgotPassForm;
