import { useRef, useState } from "react";
import styled from "styled-components";
import { Box, Flex, Type, Button, Link, Loading } from "ui";
import { PrismLight as SyntaxHighlighter } from "react-syntax-highlighter";
import { solarizedlight } from "react-syntax-highlighter/dist/esm/styles/prism";
import javascript from "react-syntax-highlighter/dist/esm/languages/prism/javascript";
import "./SetupFormSection.css";
import { useUtils } from "services/utilsProvider";

SyntaxHighlighter.registerLanguage("javascript", javascript); // Register the language syntax style

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;

  p:first-of-type {
    font-size: 16px;
    font-weight: 600;
    color: ${(props) => props.theme.color.black};
    line-height: 24px;
  }
  p:nth-of-type(2) {
    font-size: 12px;
    color: ${(props) => props.theme.color.black};
    line-height: 20px;
  }
`;
const CustomLink = styled(Link)`
  color: ${(props) => props.theme.color.gray600};
  font-size: 14px;
  font-family: ${(props) => props.theme.fonts.primary};
  text-decoration: none;
  font-weight: 600;
  padding: 5px;
  &:hover {
    color: ${(props) => props.theme.color.darkBlue};
    background-color: ${(props) => props.theme.color.gray300};
    border-radius: 6px;
  }
  &.selected {
    color: ${(props) => props.theme.color.darkBlue};
    background-color: ${(props) => props.theme.color.gray300};
    border-radius: 6px;
  }
`;
const SType = styled(Type)`
  font-size: 12px;
  line-height: 20px;
`;
function SetupFormSection({ selectedForm }) {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const formRef = useRef(null);
  const [activeTab, setactiveTab] = useState(false);
  const [switchToCode, setSwitchToCode] = useState(false);
  const { notify } = useUtils();
  const codeSnippet = `
    <section class="hnndes form-container">
    <form action="https://api.forms-easy.com/f/${selectedForm}" method="POST" enctype="multipart/form-data">
        <div class="hnndes form-block">
        <label for="fc-generated-1-name">Full Name</label>
        <input type="text" name="name" id="fc-generated-1-name" placeholder="Your first and last name" />
        </div>
        <div class="hnndes form-block">
        <label for="fc-generated-1-email">Your Email Address</label>
        <input type="email" name="email" id="fc-generated-1-email" placeholder="alice@hnndes.com" />
        </div>
        <div class="hnndes form-block">
        <label for="fc-generated-1-message">Your message</label>
        <textarea name="message" name="message" id="fc-generated-1-message" placeholder="Enter your message..."></textarea>
        </div>
        <div class="hnndes form-block">
        <button type="submit">Send</button>
        </div>
    </form>
    </section>
    `;
  const customStyle = {
    ...solarizedlight,
    'pre[class*="language-"]': {
      // backgroundColor: "#f3f4f4",
    },
  };
  const url = `https://api.forms-easy.com/f/${selectedForm}`;
  const [copySuccess, setCopySuccess] = useState(false);
  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(url)
      .then(() => {
        setCopySuccess(true);
      })
      .catch((error) => {
        console.error("Error copying to clipboard:", error);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();

    // Add a text field
    formData.append("fullname", formRef.current.fullname.value);
    formData.append("email", formRef.current.email.value);
    formData.append("message", formRef.current.message.value);

    const url = `https://api.forms-easy.com/f/${selectedForm}`;

    try {
      const res = await fetch(url, {
        method: "POST",
        body: formData,
        headers: {
          'Accept': 'application/json'
      }
      });
      const responseBody = await res.json(); // Process the response body

      if ( responseBody.success === true ) {
        notify("form submitted successfully !","success")
        
      }else {
        setError(responseBody.error)
      }
      formRef.current.reset();
    } catch (error) {
      console.info(error + " url: " + url);
      setError(
        <span>
          An error occured while trying to submit the form. <br />
        </span>
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <Flex
        jc="space-between"
        dir={["_", "_", "column", "_"]}
        ai= {["center", "_", "flex-start", "_"]}
        sx={{
          gap: ["_", "_", "20px", "_"],
     
        }}
      >
        <StyledBox>
          <Type>Test Your Endpoint</Type>
          <Type variant="subtitle">
            This is the inique API URL for your form, you will be sending your
            form data to this URL.
          </Type>
        </StyledBox>
        <Flex
          ai="center"
          jc="space-between"
          sx={{
            br: "6px",
            w: ["300px", "_", "100%", "_"],
            p: "2px 2px 2px 10px",
          }}
          style={{ border: "2px solid #E0E5F2" }}
        >
          <Type
            var="subtitle"
            sx={{
              c: "type",
              fs: "12px",
              ff: "secondary",
              fw: "700",
              m: "0px",
              w: "70%",
              h: "100%",
            }}
            style={{
              wordWrap: "break-word",
              overflowWrap:"break-word"
            }}
          >
            {url}
          </Type>
          <Button sx={{ w: "70px" }} onClick={copyToClipboard}>
            {copySuccess ? "Copied!" : "Copy"}
          </Button>
        </Flex>
      </Flex>
      <SType variant="subtitle" sx={{ mt: "10px", c: "black" }}>
        If you already have a form, update the action attribute. Your form tag should look like this:
      </SType>
      <Flex
        gap="20"
        ai="center"
        jc="center"
        sx={{ bgc: "babyBlue", my: "20px", p: "10px", br: "6px" }}
      >
        <SType variant="subtitle" sx={{ c: "gray600" }}>
        {
          `<form accept-charset="UTF-8" action="https://www.forms-easy.com/f/${selectedForm}" method="POST>`
        }
        </SType>
      </Flex>
      <Flex dir="column" ai="center" jc="center" sx={{ w: "100%", pb: "20px" }}>
        <Type
          var="h5"
          sx={{
            c: "type",
            fw: "600",
            ff: "secondary",
            text: "none",
            lh: "30px",
          }}
        >
          Test Your Form
        </Type>
        <Type
          var="subtitle"
          sx={{
            c: "type",
            fs: "14px",
            ff: "secondary",
            w: "50%",
            ta: "center",
          }}
        >
          Submitting this form will add a submission to your form. Great way to
          test emails, webhooks and other integrations.
        </Type>
        <Flex sx={{ p: "4px", bgc: "gray200", br: "4px" }}>
          <CustomLink
            className={!activeTab ? "selected" : ""}
            onClick={() => {
              setactiveTab(false);
              setSwitchToCode(false);
            }}
          >
            Preview
          </CustomLink>
          <CustomLink
            className={activeTab ? "selected" : ""}
            onClick={() => {
              setactiveTab(true);
              setSwitchToCode(true);
            }}
          >
            Code
          </CustomLink>
        </Flex>
        {!switchToCode ? (
          <form
            ref={formRef}
            className="exampleForm"
            onSubmit={handleSubmit}
            // action={`https://api.forms-easy.com/form/${selectedForm}/submission`}
            // method="post"
          >
            <label htmlFor="fullname">Full Name:</label>
            <input
              type="text"
              id="fullname"
              name="fullname"
              placeholder="Your first and last name"
              required
            />

            <label htmlFor="email">E-mail Address:</label>
            <input
              type="text"
              id="email"
              name="email"
              placeholder="alice-test@gmail.com"
              required
            />

            <label htmlFor="message">Your Message:</label>
            <textarea
              type="text"
              id="message"
              name="message"
              placeholder="Enter your message..."
              required
            ></textarea>

            {loading ? <Box sx={{ta: 'center', bgc: 'gray400', py: 5}}>
              <Loading />
            </Box> :  <input type="submit" value="Send" disabled={loading} />}

            <Type sx={{ c: "red", mt: 20 }}>{error}</Type>
          </form>
        ) : (
          <Box sx={{ w: "100%", h: "500px", of: "scroll" ,bgc: "#f3f4f4",mt:"10px"}}>
            <SyntaxHighlighter language="javascript" style={customStyle} >
              {codeSnippet}
            </SyntaxHighlighter>
          </Box>
        )}
      </Flex>
    </Box>
  );
}
export default SetupFormSection;
