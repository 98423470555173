import styled from "styled-components";
import { Flex, Box } from "ui";



const ExpandedContent = styled(Box)`
  display: flex;
  flex-direction: column;

  background-color: ${(p) => p.theme.color.white};
  border: ${(p) => p.theme.color.gray200} 2px solid;
  width: 100%;
  overflow: hidden;
  max-height: 0;
  transition: all 0.5s ease;
  pointer-events: none;
  margin-top: -3px;
  &.active {
    max-height: 1000px;
    pointer-events: all;
  }
`;

const Arrows = styled(Flex)`
  position: absolute;
  right: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1px;
  background-color: ${(p) => p.theme.color.babyBlue};
  height: 30px;
  width: 25px;
  border-radius: 4px;
`;



export {
  ExpandedContent,
  Arrows,
};
