import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Box, Button, Checkbox, Flex, InputText, Type } from "ui";
import { useAuth } from "services/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useProfile } from "views/Account/provider";

const schema = Yup.object().shape({
  email: Yup.string().required("Required.").email("Invalid email."),
  password: Yup.string()
    .required("Required.")
    .min(4, "Too Short! at least 4 letters is required!"),
});

function LoginForm({ onSuccess }) {
  const [showPass, setShowPass] = useState(false);
  const [error, setError] = useState(false);

  let navigate = useNavigate();
  let location = useLocation();
  const auth = useAuth();
  const header = useProfile();
  let emailName = header.em;
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const paramsString = window.location.search;
  let searchParams = new URLSearchParams(paramsString);

  let from =
    location.state?.from?.pathname ||
    (searchParams.has("from") && searchParams.get("from")) ||
    "/";

  useEffect(() => {
    if (auth.user) navigate(from, { replace: true });
  }, [auth, from, navigate]);

  const onSubmit = async (values) => {
    setError(null);
    console.log("values", values);

    try {
      await auth.signin(values);
      reset();
      onSuccess();
    } catch (e) {
      window.scrollTo(0, 0);
      console.log("error", e);
      if (e.status === 401) setError(true);
    }
  };

  return (
    <Box
      as="form"
      sx={{ w: [370,"_","_","90%"], bgc: "white", br: 10 }}
      onSubmit={handleSubmit(onSubmit)}
      noValidate
    >
      <Box
        sx={{
          d: error ? "block" : "none",
          p: 8,
          bgc: "redBg",
          w: "100%",
          border: ["red", 10, "solid", 5, "r"],
          mb: 10,
          ta: 'left'
        }}
      >
        That combination of username and password did not work, please try
        again.
      </Box>
      <InputText
        label="E-mail address"
        error={!!errors.email}
        msg={errors.email?.message}
        inputProps={{
          ...register("email"),
          autoFocus: true,
          defaultValue: emailName,
        }}
      />
      <InputText
        label="Password"
        type={showPass ? "text" : "password"}
        iconRight={[
          showPass ? "eyeSlash" : "eye",
          () => setShowPass((showPass) => !showPass),
        ]}
        error={!!errors.password}
        msg={errors.password?.message}
        inputProps={{
          ...register("password"),
          required: true,
        }}
      />
      <Box sx={{ w: [370,"_","_","90%"], bgc: "white", br: 10 }}>
        <Flex>
          <Checkbox size="small" label="Remember me" id="remember" />
          <Type
            id="1"
            style={{ marginLeft: "auto", cursor: "pointer" }}
            var={"h6"}
            onClick={() => navigate("/forgot-password")}
          >
            Forget your Password?
          </Type>
        </Flex>
      </Box>
      <Button
        type="submit"
        loading={isSubmitting}
        sx={{ mx: "auto", w: "100%" }}
      >
        Login
      </Button>
      <Button
        variant="outlined"
        onClick={() => navigate("/create-account")}
        sx={{ w: "100%", mt: 20 }}
      >
        Create a new account
      </Button>
    </Box>
  );
}

export default LoginForm;
