import {
  useContext,
  useReducer,
  createContext,
  useCallback,
  useState,
  useRef,
} from "react";
import { useRequest } from "../../services/request";

const Context = createContext();

export function useSubmissionForm() {
  return useContext(Context);
}

const initState = {
  forms: {
    list: [],
    projectId: null,
  },
  submissionFormList: [],
  ExportsList: [],
  status: "idle",
  error: null,
  selected: null,
  count: 0,
  page: 0,
  limit: 10,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "add":
      const newList = [{ ...action.payload.resData }, ...state.forms.list];
      return {
        ...state,
        forms: {
          ...state.forms,
          list: newList,
          projectId: action.payload.projectId,
        },
      };
    case "set_list":
      return {
        ...state,
        forms: {
          ...state.forms,
          list: [...action.payload.items],
          projectId: action.payload.projectId,
        },
      };
    case "submission_Form_List":
      return { ...state, submissionFormList: [...action.payload.items] };
    case "delete_submission":
      const idsToDelete = action.payload;
      const updatedSubmissionList = state.submissionFormList.filter(
        (item) => !idsToDelete.includes(item.id)
      ); 
      return { ...state, submissionFormList: [...updatedSubmissionList] };
    case "edit_Submission":
      const editedSubmissionList = state.submissionFormList.map(record => {
        const updatedRecord = { ...record }; // Create a copy of the record
        action.payload.forEach(payloadItem => {
            if (updatedRecord.id === payloadItem.submissionId) {
                updatedRecord.isSpam = payloadItem.isSpam;
            }
        });
        return updatedRecord;
    });
      return { ...state, submissionFormList: [...editedSubmissionList] };
    case "delete":
      const updatedList = state.forms.list.filter(
        (item) => item.id !== action.payload
      );
      return { ...state, forms: { ...state.forms, list: updatedList } };
    case "status":
      return { ...state, status: action.payload };
    case "add_Export":
      const newExportsList = [
        { ...action.payload.resData },
        ...state.ExportsList,
      ]; // Remove unnecessary spread operator
      return { ...state, ExportsList: newExportsList };
    case "set_Exports_list":
      return { ...state, ExportsList: [...action.payload.items] };
    case "delete_Export":
      const updatedExportsList = state.ExportsList.filter(
        (item) => item.id !== action.payload
      );
      return { ...state,  ExportsList: [ ...updatedExportsList ] };
    case "set_selected":
      return { ...state, selected: { ...action.payload } };
    case "edit_Form":
      return { ...state, selected: { ...action.payload } };
    case "set_count":
      return { ...state, count: action.payload };
    case "set_page":
      return { ...state, page: action.payload };
    case "set_limit":
      return { ...state, limit: action.payload };
    default:
      throw new Error(`Invalid dispatch type: ${action.type}`);
  }
};

export default function SubmissionFormsProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initState);
  const [selectedForm, setSelectedForm] = useState();
  const req = useRequest();
  const lastParamsRef = useRef({});

  const create = useCallback(
    async (data, projectId) => {
      return new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: "status", payload: `creating` });
          const resData = await req(
            `project/${projectId}/form`,
            data,
            { method: "POST" },
            true
          );
          dispatch({ type: "add", payload: { resData, projectId } });
          resolve(resData);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );
  const deleteForm = useCallback(
    async (projectId, id) => {
      return new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: "status", payload: `deleting` });
          const resData = await req(
            `project/${projectId}/form/${id}`,
            {},
            { method: "DELETE" },
            true
          );
          dispatch({ type: "delete", payload: id });
          resolve(resData);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );
  const fetchList = useCallback(
    async (page = 0, limit = 10, projectId) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: "fetching" });
        try {
          const query = `page=${page + 1}&limit=${limit}`;
          const resData = await req(
            `project/${projectId}/form?${query}`,
            null,
            {},
            true
          );
          dispatch({
            type: "set_list",
            payload: { items: resData.items, projectId },
          });
          resolve(resData);
        } catch (error) {
          reject(error);
          console.log(error);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );
  const fetchSubmissionList = useCallback(
    async (
      page ,
      limit ,
      projectId,
      formId,
      filter ,
      order ,
      isSpam,
      hasAttachments
    ) => {
      return new Promise(async (resolve, reject) => {
        const { lastParams } = lastParamsRef.current; // Destructure lastParams from the ref
        dispatch({ type: "status", payload: "fetching" });
        try {
          filter =filter && filter!==null ?filter: lastParams?.filter||{};
          const filterString = Object.entries(filter)
            .map(
              ([key, value]) =>
                `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
            )
            .join("&");
          const filterQuery = filterString ? `&${filterString}` : ""; // Add this line
          let query = `page=${
            page + 1
          }&limit=${limit}${filterQuery}`;
          if (order!==null) {
            if(order!==""){
              query += `&order=${order}`;
            }else{
              query += `&order=${lastParams?.order!==undefined?lastParams.order:"desc"}`;
              order=lastParams?.order
            }
          }
          if (isSpam!==null) {
            if(isSpam!==""){
              query += `&isSpam=${isSpam}`;
            }else     
              {
              query += lastParams?.isSpam === false || lastParams?.isSpam ===true  ?`&isSpam=${lastParams?.isSpam} `:"";
              isSpam=lastParams?.isSpam
              }
            
  
          }
          if (hasAttachments!==null) {
            if(hasAttachments!==""){
              query += `&hasAttachments=${hasAttachments}`;
            }else     
              {
              query += lastParams?.hasAttachments === false || lastParams?.hasAttachments ===true  ?`&hasAttachments=${lastParams?.hasAttachments} `:"";
              hasAttachments=lastParams?.hasAttachments
              }
          }
          const resData = await req(
            `s/project/${projectId}/form/${formId}/submission?${query}`,
            null,
            {},
            true
          );
          dispatch({
            type: "submission_Form_List",
            payload: { items: resData.items },
          });
          dispatch({
            type: "set_count",
            payload: resData.meta.totalItems,
          });
          lastParamsRef.current = { lastParams: { filter, order,isSpam,hasAttachments} };
          resolve(resData);
        } catch (error) {
          reject(error);
          console.log(error);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );
  const deleteSubmission = useCallback(
    async (projectId, formId ,data) => {
      return new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: "status", payload: `deleting` });
          const resData = await req(
            `s/project/${projectId}/form/${formId}/submission`,
             data,
            { method: "DELETE" },
            true
          );
          dispatch({ type: "delete_submission", payload: data.submissionIds });
          resolve(resData);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );
  const CreateFormSubmissionsExport = useCallback(
    async (data, projectId, formId) => {
      return new Promise(async (resolve, reject) => {
        try {
          const { lastParams } = lastParamsRef.current; // Access lastParams from the refog
          
          if (lastParams?.filter!==undefined){
            data.filter=lastParams?.filter;
          }
          if (lastParams?.isSpam!==undefined&&lastParams?.isSpam!==null){
            data.isSpam=lastParams?.isSpam;
          }
          if (lastParams?.order!==undefined){
            data.order=lastParams?.order;
          }
          if (lastParams?.hasAttachments!==undefined){
            data.hasAttachments=lastParams?.hasAttachments;
          }
          
          dispatch({ type: "status", payload: `creating` });
          const resData = await req(
            `s/project/${projectId}/form/${formId}/submission-export`,
            data,
            { method: "POST" },
            true
          );
          dispatch({ type: "add_Export", payload: { resData } });
          resolve(resData);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );
  const FetchFormSubmissionExports = useCallback(
    async (page = 0, limit = 10, projectId, formId) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: "fetching" });
        try {
          const query = `page=${page + 1}&limit=${limit}`;
          const resData = await req(
            `s/project/${projectId}/form/${formId}/submission-export?${query}`,
            null,
            {},
            true
          );
          dispatch({
            type: "set_Exports_list",
            payload: { items: resData.items },
          });
          resolve(resData);
        } catch (error) {
          reject(error);
          console.log(error);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );
  const deleteExport = useCallback(
    async (projectId, formId , id) => {
      return new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: "status", payload: `deleting` });
          const resData = await req(
            `s/project/${projectId}/form/${formId}/submission-export/${id}`,
            {},
            { method: "DELETE" },
            true
          );
          dispatch({ type: "delete_Export", payload: id });
          resolve(resData);
          console.log(resData);
          
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );
  const fetchOne = useCallback(
    async (projectId, formId) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: "fetching" });
        try {
          const resData = await req(
            `project/${projectId}/form/${formId}`,
            null,
            {},
            true
          );
          dispatch({ type: "set_selected", payload: { items: resData } });
          resolve(resData);
        } catch (error) {
          reject(error);
          console.log(error);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );
  const EditForm = useCallback(
    async (data, projectId, formId) => {
      return new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: "status", payload: `editing` });
          const resData = await req(
            `project/${projectId}/form/${formId}`,
            data,
            { method: "PATCH" },
            true
          );
          dispatch({ type: "edit_Form", payload: { items: resData } });
          resolve(resData);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );
  const EditSubmission = useCallback(
    async (projectId, formId, submissionIds, data) => {
        return new Promise(async (resolve, reject) => {
            try {
                dispatch({ type: "status", payload: `editing` });

                // Array to store promises for each submission
                const promises = submissionIds.map(async submissionId => {
                    const resData = await req(
                        `s/project/${projectId}/form/${formId}/submission/${submissionId}`,
                        data,
                        { method: "PATCH" },
                        true
                    );
                    return { submissionId, isSpam: data.isSpam, resData };
                });

                // Use Promise.all to wait for all promises to resolve
                const results = await Promise.all(promises);

                // Dispatch the edit_Submission action with the results
                dispatch({ type: "edit_Submission", payload: results });

                resolve(results);
            } catch (e) {
                reject(e);
            } finally {
                dispatch({ type: "status", payload: `idle` });
            }
        });
    },
    [req]
);
  return (
    <Context.Provider
      value={{
        state,
        dispatch,
        create,
        fetchList,
        deleteForm,
        selectedForm,
        setSelectedForm,
        fetchSubmissionList,
        CreateFormSubmissionsExport,
        FetchFormSubmissionExports,
        fetchOne,
        EditForm,
        deleteExport,
        deleteSubmission,
        EditSubmission
      }}
    >
      {children}
    </Context.Provider>
  );
}
