import styled from "styled-components";
import { Box, Type } from "ui";
import SubmissionChart from "views/Dashboard/SubmissionChart";

const SType = styled(Type)`
    font-size: 16px;
    font-weight: 600;
    color: ${(props) => props.theme.color.black};
    line-height: 24px;
    margin-bottom: 10px;
    padding: 10px;
`;

function UserAnalytics () {
    return (
    <Box sx={{my:"30px"}}>
        <SType>Analytics User</SType>
        <Box 
        sx={{
            border: ["gray300", 1, "solid", 6, "tlrb"],
            p: [30, 30, 30, 20],
        }}>
            <SubmissionChart/>
        </Box>
    </Box>
    );
}
export default UserAnalytics