const theme = {
  color: {
    primary: "#061492",
    primaryFg: "#ffffff", // foreground: contrasting color for text mainly
    primaryBg: "#e7e8f5",
    secondary: "#000000",
    secondaryFg: "#ffffff",
    white: "#ffffff",
    whiteFg: "#000000",
    black: "#000000",
    blackFg: "#ffffff",
    bg: "#f9f9f9",
    redBg: "#FEF3F2",
    red: "#d50000",
    redFg: "#ffffff",
    greenBg: "#ECFDF3",
    green: "#054F31",
    greenFg: "#ffffff",
    blueBg: "#F5FAFF",
    blue: "#194185",
    blueFg: "#ffffff",
    bgSecondary: "#141414",
    yellow: "#f5d85b",
    yellowFg: "#000000",

    //gray shads
    gray50: "#f8f8f8",
    gray100: "#f5f5f5",
    gray200: "#eeeeee",
    gray300: "#dddddd",
    gray400: "#cccccc",
    gray500: "#999999",
    gray600: "#666666",
    gray700: "#444444",
    gray800: "#222222",
    gray900: "#1d1d1d",
    gray1k: "#111111",
    gray2k: "#090909",

    //added by ghewa
    transparent: "transparent",
    darkBlue: "#061492",
    darkBlueFg: "#ffffff",
    babyBlue: "#E0E5F2",
    babyBlueFg: "#E0E5F2",
    glassyEffect: "rgba(0, 0, 0, 0.5)",
  },
  fonts: {
    primary: "'Nunito', sans-serif;",
    secondary: "'Nunito', sans-serif;",
    body: "'Nunito', sans-serif;",
    display: "'Nunito', sans-serif;",
    mono: '"Roboto Mono", monospace',

    size: {
      body: "16px",
    },
  },
  pageWidth: 1350,
  borderRadius: 16,
  spaces: {
    blockY: 180,
  },
  media: {
    lg: 1300,
    md: 992,
    sm: 768,
    xs: 520,
  },
};

export default theme;
