import { useEffect, useRef, useState } from "react";
import theme from "theme";
import Box from "ui/Box";
import Flex from "ui/Flex";
import useMedia from "utilities/hooks/useMedia";

const pointerScroll = (elem) => {
  let isDrag = false;
  let prevScreenX = 0;

  const dragStart = (ev) => {
    prevScreenX = ev.screenX;
    isDrag = true;
  };
  const dragEnd = () => {
    prevScreenX = 0;
    isDrag = false;
  };
  const drag = (ev) => isDrag && (elem.scrollLeft -= ev.screenX - prevScreenX);
  elem.addEventListener("pointerdown", dragStart);
  window.addEventListener("pointerup", dragEnd);
  window.addEventListener("pointermove", drag);
};

export default function Slider({ slides, totalView }) {
  const listRef = useRef(null);
  const [responsiveTotalView, setResponsiveTotalView] = useState(totalView);

  const screenWidth = useMedia();

  useEffect(() => {
    if (screenWidth <= theme.media.xs) setResponsiveTotalView(1);
    else if (screenWidth <= theme.media.sm) setResponsiveTotalView(1);
    else if (screenWidth <= theme.media.md) setResponsiveTotalView(2);
    else setResponsiveTotalView(totalView);
  }, [screenWidth, totalView]);

  useEffect(() => {
    if (listRef.current) pointerScroll(listRef.current);
  }, [listRef]);

  return (
    <Box>
      <Flex
        gap={0}
        ref={listRef}
        jc={slides.length / responsiveTotalView < 1 ? "center" : "flex-start"}
        sx={{ ofx: "auto" }}
        style={{
          userSelect: "none",
          scrollBehavior: "smooth",
          scrollSnapType: "x mandatory",
          flexWrap: "nowrap",
          flex: "none",
          scrollbarWidth: "thin",
        }}
      >
        {slides.map((slide, i) => (
          <Box
            id={"slide-" + i}
            key={i}
            sx={{
              minw: `calc(${100 / responsiveTotalView}% )`,
              px: 10,
              cursor: "grab",
            }}
            style={{
              flex: "none",
              scrollSnapStop: "normal",
              scrollSnapAlign: "start",
            }}
          >
            {slide}
          </Box>
        ))}
      </Flex>

      {/* {Array.from(Array(Math.ceil(slides.length / totalView))).map((_, i) => (
        <a href={"#slide-" + 4 * i}>{i + 1}</a>
      ))} */}
    </Box>
  );
}
