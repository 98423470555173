import styled from "styled-components";
import Flex from "../Flex";
import Type from "../Type";
import Button from "../Button";
import Box from "../Box";
import { generatePagination } from "./generatePagination";

const Pager = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  nav {
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        display: inline;
        color: ${(props) => props.theme.color.black};
        height: 26px;
        width: 26px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-radius: 4px;
        outline: ${(props) => props.theme.color.gray300} 0px solid;
        font-size: 14px;
        transition: all 0.1s ease-out;
        &:hover:not(.ellipse):not(.active) {
          outline: ${(props) => props.theme.color.gray300} 1px solid;
          background-color: ${(props) => props.theme.color.gray100};
        }
        &.active {
          background-color: ${(props) => props.theme.color.black};
          color: ${(props) => props.theme.color.white};
          span {
            border-color: white;
          }
        }
        &.ellipse {
          border-width: 0;
          outline: none;
          cursor: auto;
        }
      }
    }
    button {
      svg {
        width: 11px;
      }
    }
  }
`;

const Page = ({ n, pageIndex, ...props }) => (
  <li
    className={n === pageIndex + 1 ? "active" : ""}
    aria-label={"page " + n + " link"}
    {...props}
  >
    {n}
  </li>
);

const Ellipse = () => <li className="ellipse">...</li>;

function Pagination({
  count = 0,
  page = 0,
  rowsPerPage = 10,
  onPageChange,
  pager = true,
  info = false,
  ...props
}) {

  const totalPages = Math.ceil(count / rowsPerPage);

  const list = generatePagination(page, totalPages);

  const handlePageClick = (p) => {
    if (p < 1) onPageChange(0);
    else if (p > totalPages) onPageChange(totalPages - 1);
    else onPageChange(p - 1);
  };

  if (count === 0 || totalPages < 2) return;
  return (
    <Pager {...props}>
      {info && (
        <Type aria-label="pagination info">
          {page + 1} of {totalPages}
          {/* {page * rowsPerPage +
            (availableRows < rowsPerPage ? availableRows : rowsPerPage)} */}
          {/* from <strong>{count}</strong> total row{count > 1 && "s"} */}
        </Type>
      )}
      <nav aria-label="pagination nav">
        <Flex gap={7}>
          <Type>Page</Type>
          <Button
            sx={{ w: "auto", mnw: "auto", h: "auto", p: 6 }}
            onClick={() => handlePageClick(page)}
            variant="text"

            iconName="arrowLeft"
            bg="gray700"
            aria-label="previous page link"
            disabled={page + 1 <= 1}
          />

          <Flex as="ul" gap={7}>
            {pager &&
              list.map((n, i) =>
                n !== "..." ? (
                  <Page
                    key={i}
                    n={n}
                    pageIndex={page}
                    onClick={() => handlePageClick(n)}
                  />
                ) : (
                  <Ellipse key={i} />
                )
              )}
          </Flex>

          <Button
            sx={{ w: "auto", mnw: "auto", h: "auto", p: 6 }}
            onClick={() => handlePageClick(page + 2)}
            variant="text"

            iconName="arrowRight"
            bg="gray700"
            aria-label="next page link"
            disabled={page + 1 >= totalPages}
          />
        </Flex>
      </nav>
    </Pager>
  );
}

export default Pagination;
