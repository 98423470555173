import { useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { Box, Flex, Icon, Link } from "ui";
import ToggleNav from "./ToggleNav";

const nav = [
  { name: "Dashboard", path: "/dashboard" },
  { name: "Projects", path: "/projects" },
];

const adminNav = [
  { name: "Dashboard", path: "/adminDashboard" },
  { name: "User", path: "/users" },
  { name: "Forms", path: "/forms" },
  { name: "Settings", path: "/settings" },
];

const SBox = styled(Box)`
  #xs-nav-toggle {
    display: none;
  }
  nav {
    #logo-sx {
      display: none;
    }
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      li {
        a {
          text-decoration: none;
          padding: 8px;
          color: ${(p) => p.theme.color.gray600};
          font-family: ${(props) => props.theme.fonts.primary};
          font-size: 16px;
          border-radius: 6px;
        }
        &:hover {
          a {
            background-color: ${(p) => p.theme.color.primary}0d;
          }
        }

        &.selected {
          a {
            background-color: ${(p) => p.theme.color.primary}1a;
            color: ${(p) => p.theme.color.gray900};
            font-weight: 700;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    position: absolute;
    top: 0;
    left: 0;
    background-color: ${(p) =>
      p.active ? p.theme.color.black + "55" : "transparent"};
    pointer-events: ${(p) => (p.active ? "default" : "none")};
    height: 100vh;
    width: 100%;
    z-index: 99999;
    transition: all .2s ease-in-out;
    nav {
      /* display: ${(p) => (p.active ? "block" : "none")}; */
      position: relative;
      left: ${(p) => (p.active ? "0px" : "-100%")};
      transition: all .2s ease-in-out;
      background-color: #fff;
      height: 100vh;
      width: 280px;
      padding: 15px;
      box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.2),
        0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12);
      #logo-sx {
        display: block;
      }
      ul {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding-top: 30px;
        gap: 0;
        li {
          width: 100%;
          padding: 5px 0;
          a {
            width: 100%;
            display: block;
            &:hover {
              background-color: ${(p) => p.theme.color.gray300};
            }
          }
        }
      }
    }
  }
`;

export default function SiteNav({ admin }) {
  // effective for small screen only
  const [active, setActive] = useState(false);
  const location = useLocation();

  const areWeHere = (path) => location.pathname === path;

  return (
    <>
      <ToggleNav {...{ active, setActive }} />
      <SBox active={active} onClick={() => setActive((active) => !active)}>
        <nav onClick={(e) => e.stopPropagation()}>
          <Box
            id="logo-sx"
            sx={{ mnw: [200, 200, 200, "auto"], ml: [0, 0, 0, 20] }}
          >
            <Icon name="FormEasyIcon" size={32} />
          </Box>

          <Flex as="ul" gap={20}>
            {(admin ? adminNav : nav).map((item) => (
              <li className={areWeHere(item.path) ? "selected" : undefined}>
                <Link to={item.path}>{item.name}</Link>
              </li>
            ))}
          </Flex>
        </nav>
      </SBox>
    </>
  );
}
