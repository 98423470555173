import { memo, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useUtils } from "services/utilsProvider";
import { useProject } from "./provider";
import { useForm } from "react-hook-form";
import { Box, Button, Flex, Icon, InputText, Type } from "ui";

const schema = Yup.object().shape({
  name: Yup.string().required("The project name field is required"),
  allowedDomain: Yup.string(),
});

function ProjectForm({ onSuccess, onBack }) {
  const [error, setError] = useState([]);
  const { create } = useProject();
  const { notify } = useUtils();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (values) => {
    if (!values.allowedDomain.length) delete values.allowedDomain;

    try {
      await create(values);
      reset();
      notify(`Project created successfully`);
      onSuccess();
    } catch (e) {
      console.log("error", e);
      if (e.status === 400) setError([e.data.message]);
    }
  };

  return (
    <Box as="form" onSubmit={handleSubmit(onSubmit)} noValidate>
      <Icon name="ArrowLeft" w="20" onClick={() => onBack()} />
      <Type
        var="h6"
        sx={{ c: "type", fw: "700", ff: "secondary", text: "none", lh: "30px" }}
      >
        Create a new project
      </Type>

      <Flex
        role="alert"
        sx={{
          d: error.length ? "block" : "none",
          p: 5,
          bgc: "redBg",
          w: "100%",
          border: ["red", 10, "solid", 5, "r"],
          my: 10,
        }}
      >
        {error.map((err) => (
          <Type>{err}</Type>
        ))}
      </Flex>

      <InputText
        label="Project name"
        error={!!errors.name}
        size="small"
        msg={
          errors.name?.message ??
          "The project name will not be displayed to visitors."
        }
        id="name"
        inputProps={{
          ...register("name"),
          autoFocus: true,
          required: true,
        }}
        sx={{ maxw: "100%" }}
      />

      <InputText
        label="Restrict to Domain"
        placeholder="https://www.example.com"
        error={!!errors.domain}
        msg={
          errors.domain?.message ??
          "Only submissions from this domain(and all subdomains) will be accepted."
        }
        size="small"
        inputProps={{
          ...register("allowedDomain"),
        }}
        sx={{ maxw: "100%" }}
      />

      <Button loading={isSubmitting} sx={{ fl: "right", mt: 20 }}>
        Save
      </Button>
    </Box>
  );
}

export default memo(ProjectForm);
