import styled from "styled-components";
import Card from "./Card";
import Close from "./Close";
// import Mask from "./Mask";
import Modal from "./Modal";

const StyledModal = styled(Modal)`
  .content {
    padding: 0px !important;
    overflow: hidden;
  }
`;
function Dialog({ children, show, setShow, onClose, closeIcon, padding }) {
  return (
    <>
      <StyledModal
        onClose={onClose}
        show={show}
        role="dialog"
        className={show ? "active" : ""}
      >
        <Card padding={padding || "0px 0px 0px 0px"}>
          {Boolean(closeIcon) && (
            <Close x={-12} y={-12} size="small" onClick={() => setShow(0)} />
          )}
          {children}
        </Card>
      </StyledModal>
    </>
  );
}
export default Dialog;
