import months from "../data/months.json";
import timeZoneCityToCountry from "../data/tz-cities-to-countries.json";
import countries from "../data/countries.json";

 /**
 * firstUp 
 * capitalize first char in a string 
 * @param  {string} s any string to capitalize
 * @return {string}   the capitalize string  
 */

 const firstUp = (s) => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};


 /**
 * toDateTime 
 * convert a utc date to formatted date string 
 * @param  {string} dateString utc date string to convert
 * @param  {object} options take two optional options {time , short}
 *                          1- {boolean} time : true to return formatted date time ,false to return formatted date without time
 *                          2- {boolean} short : true to return month as a full month name ,false to return month name as a symbol 
 * @return {string}   the formatted date string  
 */


function toDateTime(dateString, options = { time: true, short: false }) {
  console.log(dateString);
  const objectDate = new Date(Number(dateString) * 1000);
  let day = objectDate.getDate();
  let month = objectDate.getMonth();
  let year = objectDate.getFullYear();

  const date = `${
    months[month][options.short ? "short" : "long"]
  } ${day}, ${year}`;

  if (!options?.time) return date;

  let hours = objectDate.getHours();
  let minutes = objectDate.getMinutes();

  let ampm = hours <= 12 ? "AM" : "PM";

  return `${date}, ${("0" + (hours % 12)).slice(-2)}:${("0" + minutes).slice(
    -2
  )} ${ampm}`;
}



/**
 * objByStr 
 * extract value from nested object 
 * @param  {object} o the object to extract value from
 * @param  {string} s the value to extract as a string example : employee.info.fullname 
 * @return {any}      needed value  
 */



function objByStr(o, s) {
  if (!o) return;
  s = s.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
  s = s.replace(/^\./, ""); // strip a leading dot
  var a = s.split(".");
  for (var i = 0, n = a.length; i < n; ++i) {
    if (!o) return;
    var k = a[i];
    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o;
}


 /**
 * rmEmpty 
 * deleting the empty value from the passed object 
 * @param  {object} obj the object that needs to be removed empty value
 * @return {object}     object without empty values    
 */
function rmEmpty(obj) {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
}

function genYears(min = 1900, max = new Date().getFullYear()) {
  var years = [];

  for (var i = max; i >= min; i--) {
    years.push(i.toString());
  }
  return years;
}

// transform js date to yyy-mm-dd without time
function transformDate(date, time = false) {
  if (time) {
    var tzoffset = new Date().getTimezoneOffset() * 60000;
    return new Date(date.getTime() - tzoffset)
      .toISOString()
      .replace("T", " ")
      .replace(":00.000Z", "");
  }
  return date.toISOString().split("T")[0];
}


 /**
 * rmValueFromObj 
 * deleting the specific values from the passed object 
 * @param  {object} obj                 the object that needs to be removed empty value
 * @param  {array of string} valuesList array of strings containing the unwanted values 
 * @return {object}                     object without unwanted values    
 */

 function rmValueFromObj(obj, valuesList) {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => !valuesList.includes(v) ));
}



/**
 * addDays 
 * get the date after {x} day    
 * @param  {number} numOfYears    number of years to subtract 
 * @param  {date} date            the date to subtract from ,default date is now  
 * @return {date}                 date after subtract the years    
 */

function subtractYears(numOfYears, date = new Date()) {
  const dateCopy = new Date(date.getTime());

  dateCopy.setFullYear(dateCopy.getFullYear() - numOfYears);

  return dateCopy;
}




/**
 * addDays 
 * get the date after {x} day    
 * @param  {number} days          number of days to add 
 * @param  {date} date            the date to add to ,default date is now  
 * @return {date}                 date after add the days    
 */



function addDays(days, date = new Date()) {
  const dateCopy = new Date(date.getTime());
  dateCopy.setDate(dateCopy.getDate() + days);

  return dateCopy;
}


/**
 * addHours 
 * get the date after {x} day    
 * @param  {number} days          number of Hours  to add 
 * @param  {date} date            the date to add to ,default date is now  
 * @return {date}                 date after add the Hours     
 */


function addHours(hours, date = new Date()) {
  var dateCopy = new Date(date.getTime());
  dateCopy.setHours(dateCopy.getHours() + hours);

  return dateCopy;
}



/**
 * createThumb 
 * create the thumb of image with given width and height    
 * @param  {file} blobImage       number of days to add 
 * @param  {number} w             width of the thumb
 * @param  {number} h             height of the thumb   
 * @return {blob}                 the thumb    
 */


const createThumb = (blobImage, w, h) => {
  return new Promise((resolve, reject) => {
    try {
      var reader = new FileReader();
      //File reader is for some reason asynchronous
      reader.onloadend = function () {
        var img = document.createElement("img");
        img.onload = async function (e) {
          resolve(await _resize(img, w, h, blobImage.type));
        };
        img.onerror = (e) => {
          console.log(e);
        };
        img.src = reader.result;
      };
      //This starts the conversion
      reader.readAsDataURL(blobImage);
    } catch (e) {
      console.error(e);
      reject(e.message);
    }
  });

  function _resize(img, w, h, type) {
    var width = img.width;
    var height = img.height;

    if (width < height) {
      height = height * (w / width);
      width = w;
    } else {
      width = width * (h / height);
      height = h;
    }

    var canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;

    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0, width, height);

    return new Promise((resolve, reject) => {
      try {
        canvas.toBlob((blob) => {
          resolve(blob);
        }, type);
      } catch (e) {
        console.error(e);
        reject(e.message);
      }
    });
  }
};


/**
 * downloadFile 
 * download file from the server    
 * @param  {string} fileUrl       url of the file 
 * @param  {string} filename      the desired filename  
 * @return     
 */



function downloadFile(fileUrl, filename) {
  var link = document.createElement("a");
  link.setAttribute("href", fileUrl);
  link.setAttribute("download", filename);

  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  // delete link;
}


/**
 * currency 
 * return desired format to show currency    
 * @param  {string} v                value to work on
 * @param  {string} style            if you want to use currency design 132.25 $  
 * @param  {string} currency         to spicefy currency type 
 * @param  {number} intDigits        number of decimal before (",") 
 * @param  {number} fractionDigits   number of decimal after (",")
 * @return   formatted currency  
 */


function currency(
  v,
  style = "currency",
  currency = "USD",
  intDigits = 2,
  fractionDigits = 2
) {
  const opt = {
    minimumIntegerDigits: intDigits,
    minimumFractionDigits: fractionDigits,
  };
  if (style) opt.style = style;
  if (currency) opt.currency = currency;
  return new Intl.NumberFormat("en-US", opt).format(v);
}

/**
 * downloadFile 
 * download file from the server    
 * @param  {string} fileUrl       url of the file 
 * @param  {string} filename      the desired filename  
 * @return     
 */


/**
 * dateDiff 
 * calculate diff between2 dates    
 * @param  {date} pastDate      past date to calculate
 * @param  {date} futurDate     future date to calculate
 * @return {array} array of [day , hour , minute , second ]    
 */
 
function dateDiff(pastDate, futurDate) {
  return dateFromNow(futurDate, pastDate);
}



/**
 * dateFromNow 
 * calculate diff between now and specific    
 * @param  {date} date           date to calculate   
 * @param  {date} diffDate       use null to calculate diff between now and specific date (first argument)       
 * @return {array} array of [day , hour , minute , second ]    
 */
 
// this function has been updated date difference
function dateFromNow(date, diffDate) {
  var now = diffDate ? new Date(diffDate).getTime() : new Date().getTime();

  // Find the distance between now an the count down date
  var distance = new Date(date).getTime() - now;

  // Time calculations for days, hours, minutes and seconds
  var days = Math.floor(distance / (1000 * 60 * 60 * 24));
  var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  var seconds = Math.floor((distance % (1000 * 60)) / 1000);

  return [
    days.toString().length === 1 ? "0" + days : days,
    hours,
    minutes,
    seconds,
  ];
}


/**
 * getUserCountry
 *         
 * @return {array} array of [country info , city , region , time zone ]    
 */
 

function getUserCountry() {
  // var userRegion;
  var userCity;
  var userCountry;
  var userTimeZone;
  var userRegion;

  if (Intl) {
    userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    var tzArr = userTimeZone.split("/");
    userRegion = tzArr[0];
    userCity = tzArr[tzArr.length - 1];
    userCountry = timeZoneCityToCountry[userCity];
  }

  const countryDetails = countries.find(
    (c) => c.name.toLowerCase() === userCountry.toLowerCase()
  );

  return {
    country: userCountry,
    city: userCity,
    region: userRegion,
    timezone: userTimeZone,
    countryDetails
  };
}


/**
 * debounce 
 * delay the function number of milliseconds  
 * @param  {fn} fn           the function to delay   
 * @param  {number} ms       number of milliseconds to delay
 * @return   
 */
 

function debounce(fn, ms) {
  let timer;
  return (_) => {
    clearTimeout(timer);
    timer = setTimeout((_) => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}


function delay(callback, ms) {
  var timer = 0;
  return function () {
    var context = this,
      args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      callback.apply(context, args);
    }, ms || 0);
  };
}



/**
 * enforceMinMax
 * set value of an element to min and max values that specified before if the value is less than or greater than the specified values
 * @param  {any} e               the element to set the value    
 * @param  {fn} setValue         is a fun from YUP       
 * @return     
 */



function enforceMinMax(e, setValue) {
  const el = e.target;
  if (el.value !== "") {
    if (parseInt(el.value) < parseInt(el.getAttribute("min"))) {
      el.value = el.getAttribute("min");
      setValue(Number(el.getAttribute("min")));
    }
    if (parseInt(el.value) > parseInt(el.getAttribute("max"))) {
      el.value = el.getAttribute("max");
      setValue(Number(el.getAttribute("max")));
    }
  }
}


/**
 * truncatWork
 * trim any string from 0 to specified number of characters 
 * @param  {string} s            the string to trim    
 * @param  {number} max         max number of characters      
 * @return     
 */


function truncatWork(s = "", max) {
  if (!s) return;
  if (s.length <= max) return s;
  var trimmedString = s.substring(0, max);

  //re-trim if we are in the middle of a word
  return (
    trimmedString.substring(
      0,
      Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))
    ) + "..."
  );
}

function getStatus(id) {
  switch (id) {
    case 0:
      return "Under Review";
    case 1:
      return "NEW";
    case 2:
      return "UPCOMING";
    case 3:
      return "OPEN";
    case 4:
      return "CLOSED";
    default:
      return "";
  }
}

/**
 * getInitials
 * take first litter of every word and shift and pop the first and last characters 
 * @param  {string} name the string to take the initials from    
 * @return {string}      string of the initials
 */

function getInitials(name) {
  let rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");

  let initials = [...name.matchAll(rgx)] || [];

  return (
    (initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")
  ).toUpperCase();
}



/**
 * toThousand
 * give formatted number as a string like 5k = 5000 
 * @param  {number} n   the number to be formatted    
 * @return {number}     formatted number
 */


function toThousand(n) {
  if (!n || isNaN(n)) return 0;
  if (n < 1000) return currency(n, null, null);
  return currency(n / 1000, null, null, 1, 0) + "K";
}


/**
 * shuffle
 * randomly shuffle an array 
 * @param  {array} array   array to shuffle
 * @return {array}         shuffled array
 */


function shuffle(array) {
  var currentIndex = array.length,
    temporaryValue,
    randomIndex;
  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;
    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }
  return array;
}


/**
 * removeEmpty
 * remove empty elements and null values from an object
 * @param  {object} obj    object to remove empty elements from
 * @return {object}        object with empty elements removed
 */

const removeEmpty = (obj) => {
  Object.keys(obj).forEach(
    (k) => (obj[k] === null || obj[k] === "") && delete obj[k]
  );
  return obj;
};


const turncate = (s, len = 40) => {
  if (!s || s.length <= 40) return s;
  //trim the string to the maximum length
  var trimmedString = s.substr(0, len);

  //re-trim if we are in the middle of a word
  let trimmed = trimmedString.substr(
    0,
    Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))
  );
  return trimmed + "...";
};

/**
 * blob2bin
 * convert a blob to a binary array
 * @param  {blob} blob    file as a blob
 * @return {array}        array of bytes
 */


const blob2bin = async (blob) => {
  return new Promise((resolve, reject) => {
    try {
      var reader = new FileReader();
      //File reader is for some reason asynchronous
      reader.onloadend = function () {
        resolve(reader.result);
      };
      //This starts the conversion
      reader.readAsArrayBuffer(blob);
    } catch (e) {
      reject(e.message);
    }
  });
};

const percentageChange = (a, b) => {
  if(!a && b) return 100;
  if(a === b) return 0;
  return ( b / a * 100 ) - 100
};


export {
  firstUp,
  toDateTime,
  objByStr,
  rmEmpty,
  genYears,
  createThumb,
  downloadFile,
  currency,
  transformDate,
  subtractYears,
  addDays,
  addHours,
  debounce,
  getUserCountry,
  dateFromNow,
  delay,
  enforceMinMax,
  truncatWork,
  getStatus,
  getInitials,
  dateDiff,
  toThousand,
  shuffle,
  removeEmpty,
  turncate,
  blob2bin,
  percentageChange
};
