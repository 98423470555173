import styled, { css } from "styled-components";
import Type from "./Type";

export default styled(Type)`
  padding: 0 5px;
  font-size: 12;
  background-color: ${(p) => p.theme.color.primaryBg};
  border-radius: 999px;
  ${(p) =>
    p.onClick &&
    css`
      cursor: pointer;
      &:hover {
        background-color: ${p.theme.color.gray300};
      }
    `}
`;
