import { Button, Flex, Icon, Type } from "ui";
import { FlexStyled, SecondRowItem, StyledBox } from "../../../Components";
import { useSubmissionForm } from "../provider";
import { useNavigate, useParams } from "react-router-dom";
import { useUtils } from "services/utilsProvider";
import { useState } from "react";
import Dialog from "ui/Dialog";

function DangerZone({ selectedForm }) {
  const { notify } = useUtils();
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const { deleteForm } = useSubmissionForm();
  const { projectId, formId } = useParams();

  const DeleteForm = () => {
    deleteForm(projectId, formId);
    navigate(`/dashboard`);
    notify(`Form deleted successfully`, "success");
  };

  return (
    <>
      {!openDialog ? (
        <FlexStyled>
          <span />
          <StyledBox>
            <Type>Delete form</Type>
            <Type variant="subtitle">
              Deleting the form will erase all traces of this form, including
              all submissions and file uploads.
            </Type>
          </StyledBox>
          <SecondRowItem>
          <Icon
              name="trash2"
              w={32}
              c="red"
              hover="gray100"
              onClick={() => {
                setOpenDialog(true);
              }}
            />
            </SecondRowItem>
        </FlexStyled>
      ) : (
        <Dialog
          padding={"20px 30px 60px 30px"}
          show={openDialog}
          onClose={() => setOpenDialog(false)}
        >
          <Type
            var="h5"
            sx={{
              c: "type",
              fw: "700",
              ff: "secondary",
              text: "none",
              lh: "30px",
            }}
          >
            Delete {selectedForm.name} ?
          </Type>
          <Type var="subtitle" sx={{ c: "type", fs: "14px", ff: "secondary" }}>
            Are you sure about that?this can't be undone
          </Type>
          <Flex
            dir="row"
            ai="center"
            gap="10"
            jc="flex-end"
            sx={{
              p: "10px",
              w: "100%",
              pos: "absolute",
              bottom: "0px",
              right: "0px",
              bgc: "babyBlue",
              br: "0 0 4px 4px",
            }}
          >
            <Button variant="outlined" onClick={() => setOpenDialog(false)}>
              Cancel
            </Button>
            <Button onClick={() => DeleteForm()}>Approve</Button>
          </Flex>
        </Dialog>
      )}
    </>
  );
}
export default DangerZone;
