import { Box, Button, Flex, InputText, Type } from "ui";
import Dialog from "ui/Dialog";
import { useApi } from "./provider";
import { useUtils } from "services/utilsProvider";

export default function ShowDialog({ openShow, setOpenShow }) {
  const { notify } = useUtils();

  const {
    state: { selected },
    dispatch,
  } = useApi();

  return (
    <Dialog show={openShow} onClose={() => setOpenShow(0)}>
      <Box sx={{ p: 25, mxw: [600, 600, 600, "calc(100vw - 40px)"] }}>
        <Type sx={{ mb: 25 }} var="h4">
          {selected?.name}
        </Type>
        <Type sx={{ mb: 15 }}>
          Use this key in your application by passing it with the{" "}
          <Type var="code" as="span" sx={{ fs: 13 }}>
            key=API_KEY
          </Type>{" "}
          parameter.
        </Type>
        <InputText
          size="small"
          label="Your API key"
          iconRight={[
            "copy",
            () => {
              navigator.clipboard.writeText(selected?.key);
              notify("text copied to clipboard");
            },
            "Copy to clipboard",
          ]}
          inputProps={{
            defaultValue: selected?.key,
            readonly: true,
            style: { fontSize: 13 },
          }}
          sx={{ mb: 20 }}
        />
      </Box>

      <Box sx={{ bgc: "primaryBg", px: 25, py: 15 }}>
        <Flex jc="flex-end">
          <Button
            size="small"
            variant="outlined"
            type="button"
            onClick={() => {
              setOpenShow(0);
              dispatch({
                type: "set_selected",
                payload: null,
              });
            }}
          >
            Close
          </Button>
        </Flex>
      </Box>
    </Dialog>
  );
}
