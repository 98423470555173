import { Route } from "react-router-dom";
import Api from "./Api";
import List from "./List";
import { RequireAuth } from "services/auth";

export default (
  <Route
    path="api"
    element={
      <RequireAuth>
        <Api />
      </RequireAuth>
    }
  >
    <Route
      index
      element={
        <RequireAuth>
          <List />
        </RequireAuth>
      }
    />
  </Route>
);
