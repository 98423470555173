import { Box, Button, Checkbox, InputRadio, InputText, Type } from "ui";
import { useApi } from "./provider";
import { useEffect, useState } from "react";
import { omit } from "lodash";

export default function Edit({ onSuccess }) {
  const [name, setName] = useState("");
  const [enabled, setEnabled] = useState(true);
  const [error, setError] = useState(false);

  const {
    state: { status, selected },
    edit,
  } = useApi();

  useEffect(() => {
    if (!selected) return;
    setName(selected.name);
    setEnabled(selected.enabled);
  }, [selected]);

  async function handleSubmit() {
    if (name === "") {
      setError(true);
      return;
    }
    await edit({ id: selected.id, data: { name, enabled } });
    onSuccess();
  }

  const handleChange = (e) => {
    setEnabled(Boolean(e.target.value === "no" ? false : true));
  };

  if (!selected) return;

  return (
    <>
      <Box sx={{ p: 25, mxw: [600, 600, 600, "calc(100vw - 40px)"] }}>
        <Type var="h4" sx={{ mb: 17 }}>
          Edit API Key
        </Type>
        <InputText
          size="medium"
          label="Name *"
          placeholder="Type a name for your API Key"
          value={name}
          onChange={(e) => {
            setError(e.target.value === "" ? true : false);
            setName(e.target.value);
          }}
          inputProps={{
            style: { width: 260 },
          }}
          error={error}
          msg={error && "Name is required"}
        />
        {/* {error && (
          <Flex gap={4} jc="flex-start">
            <Icon name="warning" w={14} c="red" />
            <Type var="error">Name is required</Type>
          </Flex>
        )} */}

        <Box sx={{ mt: 24 }}>
          <Type var="h5" sx={{ mb: 10 }}>
            API key restriction
          </Type>
          <InputRadio
            label="Enable Key"
            // checked={enabled}
            inputProps={{
              value: "yes",
              onChange: handleChange,
              checked: enabled,
            }}
          />
          <InputRadio
            label="Disable Key"
            // checked={!enabled}
            inputProps={{
              value: "no",
              onChange: handleChange,
              checked: !enabled,
            }}
          />
        </Box>

        <Box sx={{ mt: 40, ta: "right" }}>
          <Button
            variant="text"
            bg="black"
            size="small"
            type="button"
            disabled={status === "updating_" + selected.id}
            onClick={onSuccess}
            sx={{ mr: 10 }}
          >
            Cancle
          </Button>
          <Button
            type="button"
            size="small"
            loading={status === "updating_" + selected.id}
            onClick={handleSubmit}
          >
            Save
          </Button>
        </Box>
      </Box>
      {/* <Box sx={{ bgc: "primaryBg", px: 25, py: 15, ta: "right" }}> */}
      {/* </Box> */}
    </>
  );
}
