import styled from "styled-components";
import Box from "./Box";

export default styled(Box).attrs(p => ({
    bg: 'black',
  }))`
    border-radius: 4px;
    opacity: 1;
    font-size: 12px;
    color: ${p => p.theme.color.white};
    background-color: ${p => p.theme.color.black}77;
    padding: 6px 7px;
    > div {
        padding: 10px 15px;
    }
`;