import { Box, Type } from "ui";
import ProgressBar from "ui/ProgressBar";

function Bar({ statement, progress, total, ofWhich }) {
  return (
    <Box>
      <ProgressBar {...{ ofWhich, total, progress }} style={{ height: 10 }} />
      <Type sx={{ mt: 10, fs: 14 }}>{statement}</Type>
    </Box>
  );
}
export default Bar;
