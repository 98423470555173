import { List, Type } from "ui";
import { FlexStyled, StyledBox } from "../../../Components";
import { useEffect, useState } from "react";

function Security({projectDetails}) {
  const [allowedDomains,setallowedDomains] = useState (projectDetails?.allowedDomains) 

  useEffect(()=>{
    setallowedDomains(projectDetails?.allowedDomains);
  },[allowedDomains,projectDetails])

  return (
    <FlexStyled>
      <span />
      <StyledBox>
        <Type>White listed Domains</Type>
        <List
        sx={{
          w:["40%","_","_","200px"],
        }}>
        {allowedDomains?.map((domain,i) => (
        <li key={i}>
          <span >{domain}</span>
        </li>
      ))}
  
        </List>
      </StyledBox>
    </FlexStyled>
  );
}
export default Security;
