import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { Flex, Link } from "ui";

export default function SectionNav({ nav }) {
  const location = useLocation();
  const areWeHere = (path) => location.pathname === path;

  return (
    <SFlex as="ul">
      {nav.map((item) => (
        <li className={areWeHere(item.path) ? "selected" : undefined}>
          <Link to={item.path}>{item.name}</Link>
        </li>
      ))}
    </SFlex>
  );
}

const SFlex = styled(Flex)`
  padding: 5px;
  margin: 20px 0 40px 0;
  list-style-type: none;
  gap: 10px;
  width: 100%;
  background-color: ${(p) => p.theme.color.primary}0d;
  border-radius: 10px;
  li {
    flex-grow: 1;
    a {
      text-decoration: none;
      display: block;
      width: 100%;
      text-align: center;
      padding: 13px 0;
      border-radius: 10px;
      font-weight: 500;
      color: ${(p) => p.theme.color.gray500};
      &:hover {
        background-color: ${(p) => p.theme.color.primary}1a;
      }
    }
    &.selected {
      a {
        background-color: ${(p) => p.theme.color.primary}1f;
        color: ${(p) => p.theme.color.primary};
      }
    }
  }
`;
