import React, { useState } from "react";
import Logo from "assests/lastLogo.svg";
import img2 from "assests/webContentImg1.svg";
import img4 from "assests/pasetedhere1.svg";
import img5 from "assests/pastedCode.svg";
import img6 from "assests/contactImg.svg";
import img7 from "assests/sendForm.svg";
import img8 from "assests/webContentImg2.svg";
import "./header.css";
import { useNavigate } from "react-router-dom";
import { useAuth } from "services/auth";
import { useProfile } from "views/Account/provider";

function HeaderLayout() {
  const [click, setClick] = useState(false);
  const [click1, setClick1] = useState(false);
  const [click2, setClick2] = useState(false);
  const [click3, setClick3] = useState(false);
  const [click4, setClick4] = useState(false);
  const [click5, setClick5] = useState(false);
  let navigate = useNavigate();
  let auth = useAuth();
  const [name, setName] = useState("");
  const { setEmail } = useProfile();

  const getStarted1 = (e) => {
    setEmail(name);
    e.preventDefault();
    auth.signout();
    navigate("/login");
  };
  const getStarted = () => {
    auth.signout();
    navigate("/login");
  };
  const wait = (ms) => {
    var start = new Date().getTime();
    var end = start;
    while (end < start + ms) {
      end = new Date().getTime();
    }
  };
  const setWait = () => {
    setClick(true);
  };
  const setStart = () => {
    wait(3000);
    setClick1(true);
    console.log("leave here");
  };
  const setStart1 = () => {
    
    wait(1000);
    setClick3(true);
    console.log("leave here");
  };

  return (
    <div className="Container">
      <div className="nav">
        <img src={Logo} alt="forms easy" className="logo" />
        <button onClick={getStarted} style={{ cursor: "pointer" }}>
          Get Started
        </button>
      </div>

      <div class="header-text">
        <span>Collecting forms online</span><br/>
        <span>made simple!</span>
      </div>

      <div className="header-text1">
        Our service makes capturing your custom forms easy.Simply copy your
        unique bucket URL into your HTML code. We'll collect the responses, send
        out notifications and more.
      </div>
      
      <form className="header-form" onSubmit={getStarted1}>
        <div class="wrapper">
          <input
            type="email"
            id="someName"
            onChange={(e) => setName(e.target.value)}
            required
            placeholder="Enter your email"
          />
          <button type="submit">Get Started</button>
        </div>
      </form>

      {!click && !click1 && !click2 && !click3 && !click4 && !click5 ? (
        <div className="header-img1">
          <img className="content-img" src={img2} alt=""/>
          <div className="div">
          <div className="click-magic">Click To See Magic ✨</div>
          <svg
            className="arrow-img"
            viewBox="0 0 114 108"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_2671_6020)">
              <path
                className="squiggle"
                fill="none"
                stroke="black"
                stroke-miterlimit="1"
                stroke-width="1"
                clip-path="url(#clip0_2671_6020)"
                d="M56.1484 39.2531C40.2917 40.6271 28.3851 55.0637 24.1085 77.8131C27.7814 75.9113 28.1824 69.9665 33.2283 70.4573C33.634 71.0455 34.1081 71.4676 34.0635 71.7019C31.4486 77.5317 28.9257 83.4059 26.0945 89.069C25.9063 89.5363 25.5994 89.9464 25.2041 90.2588C24.8089 90.5711 24.3389 90.7748 23.8407 90.8498C23.3425 90.9247 22.8334 90.8683 22.3637 90.6861C21.894 90.5039 21.4801 90.2022 21.1627 89.8109C17.8724 86.0326 14.369 82.3155 12.307 76.5616L18.4524 77.9069C20.2559 71.4513 21.4686 64.8884 23.8283 58.7656C29.3896 44.3656 39.5478 34.8977 55.2447 32.4017C57.7307 32.0131 58.8062 30.7391 59.9778 28.7595C62.1698 24.7869 65.3094 21.4174 69.1173 18.9504C72.9253 16.4834 77.2837 14.9954 81.8053 14.6186C85.2957 14.2613 89.1259 14.07 92.0955 18.6428C89.2521 17.8547 86.2812 17.6352 83.3528 17.9967C80.4244 18.3583 77.5961 19.2939 75.0298 20.7499C70.0279 23.4715 65.8458 26.9713 63.7866 32.3173C67.8531 35.5795 72.114 38.2285 75.3329 41.8025C78.5957 45.4661 81.2571 49.6238 83.218 54.1208C84.1333 56.124 84.3044 58.388 83.7004 60.5061C83.0965 62.6241 81.7572 64.4574 79.923 65.6767C76.4517 68.0957 72.0062 67.8457 67.9938 64.6252C61.9043 59.6976 57.9204 52.6356 56.8522 44.8753C56.4786 42.998 56.352 41.1187 56.1484 39.2531ZM61.7305 39.5435C61.6465 43.2696 62.3621 46.9703 63.829 50.3965C65.2958 53.8227 67.4799 56.8948 70.2341 59.4057C71.9983 61.0847 74.1147 62.4746 76.4744 60.6151C78.4932 59.0169 78.0072 56.7045 77.0881 54.7515C73.9178 48.0275 68.4601 42.6496 61.6901 39.5787L61.7305 39.5435Z"
              />
            </g>
            <defs>
              <clipPath id="clip0_2671_6020">
                <rect
                  width="109.089"
                  height="47.5432"
                  fill="white"
                  transform="translate(114 36) rotate(138.949)"
                />
              </clipPath>
            </defs>
          </svg>
          <button className="copy-button" onClick={setWait}>
            <svg  viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" className="copy-svg" >
              <path d="M18.5 12.379H29.375C31.1223 12.379 31.9959 12.379 32.6851 12.6617C33.6039 13.0386 34.3339 13.7616 34.7145 14.6716C35 15.354 35 16.2192 35 17.9496C35 19.6799 35 20.5451 34.7145 21.2276C34.3339 22.1376 33.6039 22.8605 32.6851 23.2374C31.9959 23.5201 31.1223 23.5201 29.375 23.5201C27.6277 23.5201 26.7541 23.5201 26.0649 23.8028C25.1461 24.1797 24.4161 24.9027 24.0355 25.8126C23.75 26.4951 23.75 27.3603 23.75 29.0907C23.75 30.821 23.75 31.6862 23.4645 32.3687C23.0839 33.2786 22.3539 34.0016 21.4351 34.3785C20.7459 34.6612 19.8723 34.6612 18.125 34.6612C16.3777 34.6612 15.5041 34.6612 14.8149 34.3785C13.8961 34.0016 13.1661 33.2786 12.7855 32.3687C12.5 31.6862 12.5 30.821 12.5 29.0907V18.321C12.5 16.2411 12.5 15.2012 12.9087 14.4068C13.2683 13.708 13.8419 13.1399 14.5475 12.7838C15.3497 12.379 16.3998 12.379 18.5 12.379Z" fill="#1E2330"/>
              <path d="M34.7145 21.2276C35 20.5451 35 19.6799 35 17.9496C35 16.2192 35 15.354 34.7145 14.6716C34.3339 13.7616 33.6039 13.0386 32.6851 12.6617C31.9959 12.379 31.1223 12.379 29.375 12.379H18.5C16.3998 12.379 15.3497 12.379 14.5475 12.7838C13.8419 13.1399 13.2683 13.708 12.9087 14.4068C12.5 15.2012 12.5 16.2411 12.5 18.321V29.0907C12.5 30.821 12.5 31.6862 12.7855 32.3687C13.1661 33.2786 13.8961 34.0016 14.8149 34.3785C15.5041 34.6612 16.3777 34.6612 18.125 34.6612C19.8723 34.6612 20.7459 34.6612 21.4351 34.3785M34.7145 21.2276C34.3339 22.1376 33.6039 22.8605 32.6851 23.2374C31.9959 23.5201 31.1223 23.5201 29.375 23.5201C27.6277 23.5201 26.7541 23.5201 26.0649 23.8028C25.1461 24.1797 24.4161 24.9027 24.0355 25.8126C23.75 26.4951 23.75 27.3603 23.75 29.0907C23.75 30.821 23.75 31.6862 23.4645 32.3687C23.0839 33.2786 22.3539 34.0016 21.4351 34.3785M34.7145 21.2276C32.894 27.4463 28.0422 32.3444 21.7988 34.2665L21.4351 34.3785M27.5 12.379L27.5 10.8936C27.5 8.81371 27.5 7.77377 27.0913 6.97937C26.7317 6.2806 26.1581 5.71247 25.4525 5.35643C24.6503 4.95166 23.6002 4.95166 21.5 4.95166H11C8.8998 4.95166 7.8497 4.95166 7.04754 5.35643C6.34193 5.71247 5.76825 6.2806 5.40873 6.97937C5 7.77377 5 8.8137 5 10.8936V21.2919C5 23.3718 5 24.4117 5.40873 25.2061C5.76825 25.9049 6.34193 26.473 7.04754 26.829C7.8497 27.2338 8.90017 27.2338 11.0011 27.2338H12.5" stroke="#1E2330" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            Copy
          </button>
         
          </div>
        </div>
      ) : click && !click1 && !click2 && !click3 && !click4 ? (
        <div  onMouseOver={setStart} className="header-img1">
        <img className="content-img" src={img2} alt=""/>
        <div className="div">
        <div className="click-magic">Click To See Magic ✨</div>
        <svg
          className="arrow-img"
          viewBox="0 0 114 108"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_2671_6020)">
            <path
              className="squiggle"
              fill="none"
              stroke="black"
              stroke-miterlimit="1"
              stroke-width="1"
              clip-path="url(#clip0_2671_6020)"
              d="M56.1484 39.2531C40.2917 40.6271 28.3851 55.0637 24.1085 77.8131C27.7814 75.9113 28.1824 69.9665 33.2283 70.4573C33.634 71.0455 34.1081 71.4676 34.0635 71.7019C31.4486 77.5317 28.9257 83.4059 26.0945 89.069C25.9063 89.5363 25.5994 89.9464 25.2041 90.2588C24.8089 90.5711 24.3389 90.7748 23.8407 90.8498C23.3425 90.9247 22.8334 90.8683 22.3637 90.6861C21.894 90.5039 21.4801 90.2022 21.1627 89.8109C17.8724 86.0326 14.369 82.3155 12.307 76.5616L18.4524 77.9069C20.2559 71.4513 21.4686 64.8884 23.8283 58.7656C29.3896 44.3656 39.5478 34.8977 55.2447 32.4017C57.7307 32.0131 58.8062 30.7391 59.9778 28.7595C62.1698 24.7869 65.3094 21.4174 69.1173 18.9504C72.9253 16.4834 77.2837 14.9954 81.8053 14.6186C85.2957 14.2613 89.1259 14.07 92.0955 18.6428C89.2521 17.8547 86.2812 17.6352 83.3528 17.9967C80.4244 18.3583 77.5961 19.2939 75.0298 20.7499C70.0279 23.4715 65.8458 26.9713 63.7866 32.3173C67.8531 35.5795 72.114 38.2285 75.3329 41.8025C78.5957 45.4661 81.2571 49.6238 83.218 54.1208C84.1333 56.124 84.3044 58.388 83.7004 60.5061C83.0965 62.6241 81.7572 64.4574 79.923 65.6767C76.4517 68.0957 72.0062 67.8457 67.9938 64.6252C61.9043 59.6976 57.9204 52.6356 56.8522 44.8753C56.4786 42.998 56.352 41.1187 56.1484 39.2531ZM61.7305 39.5435C61.6465 43.2696 62.3621 46.9703 63.829 50.3965C65.2958 53.8227 67.4799 56.8948 70.2341 59.4057C71.9983 61.0847 74.1147 62.4746 76.4744 60.6151C78.4932 59.0169 78.0072 56.7045 77.0881 54.7515C73.9178 48.0275 68.4601 42.6496 61.6901 39.5787L61.7305 39.5435Z"
            />
          </g>
          <defs>
            <clipPath id="clip0_2671_6020">
              <rect
                width="109.089"
                height="47.5432"
                fill="white"
                transform="translate(114 36) rotate(138.949)"
              />
            </clipPath>
          </defs>
        </svg>
        <button className="copy-button" onClick={setWait}>
          <svg  viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" className="copy-svg" >
            <path d="M18.5 12.379H29.375C31.1223 12.379 31.9959 12.379 32.6851 12.6617C33.6039 13.0386 34.3339 13.7616 34.7145 14.6716C35 15.354 35 16.2192 35 17.9496C35 19.6799 35 20.5451 34.7145 21.2276C34.3339 22.1376 33.6039 22.8605 32.6851 23.2374C31.9959 23.5201 31.1223 23.5201 29.375 23.5201C27.6277 23.5201 26.7541 23.5201 26.0649 23.8028C25.1461 24.1797 24.4161 24.9027 24.0355 25.8126C23.75 26.4951 23.75 27.3603 23.75 29.0907C23.75 30.821 23.75 31.6862 23.4645 32.3687C23.0839 33.2786 22.3539 34.0016 21.4351 34.3785C20.7459 34.6612 19.8723 34.6612 18.125 34.6612C16.3777 34.6612 15.5041 34.6612 14.8149 34.3785C13.8961 34.0016 13.1661 33.2786 12.7855 32.3687C12.5 31.6862 12.5 30.821 12.5 29.0907V18.321C12.5 16.2411 12.5 15.2012 12.9087 14.4068C13.2683 13.708 13.8419 13.1399 14.5475 12.7838C15.3497 12.379 16.3998 12.379 18.5 12.379Z" fill="#1E2330"/>
            <path d="M34.7145 21.2276C35 20.5451 35 19.6799 35 17.9496C35 16.2192 35 15.354 34.7145 14.6716C34.3339 13.7616 33.6039 13.0386 32.6851 12.6617C31.9959 12.379 31.1223 12.379 29.375 12.379H18.5C16.3998 12.379 15.3497 12.379 14.5475 12.7838C13.8419 13.1399 13.2683 13.708 12.9087 14.4068C12.5 15.2012 12.5 16.2411 12.5 18.321V29.0907C12.5 30.821 12.5 31.6862 12.7855 32.3687C13.1661 33.2786 13.8961 34.0016 14.8149 34.3785C15.5041 34.6612 16.3777 34.6612 18.125 34.6612C19.8723 34.6612 20.7459 34.6612 21.4351 34.3785M34.7145 21.2276C34.3339 22.1376 33.6039 22.8605 32.6851 23.2374C31.9959 23.5201 31.1223 23.5201 29.375 23.5201C27.6277 23.5201 26.7541 23.5201 26.0649 23.8028C25.1461 24.1797 24.4161 24.9027 24.0355 25.8126C23.75 26.4951 23.75 27.3603 23.75 29.0907C23.75 30.821 23.75 31.6862 23.4645 32.3687C23.0839 33.2786 22.3539 34.0016 21.4351 34.3785M34.7145 21.2276C32.894 27.4463 28.0422 32.3444 21.7988 34.2665L21.4351 34.3785M27.5 12.379L27.5 10.8936C27.5 8.81371 27.5 7.77377 27.0913 6.97937C26.7317 6.2806 26.1581 5.71247 25.4525 5.35643C24.6503 4.95166 23.6002 4.95166 21.5 4.95166H11C8.8998 4.95166 7.8497 4.95166 7.04754 5.35643C6.34193 5.71247 5.76825 6.2806 5.40873 6.97937C5 7.77377 5 8.8137 5 10.8936V21.2919C5 23.3718 5 24.4117 5.40873 25.2061C5.76825 25.9049 6.34193 26.473 7.04754 26.829C7.8497 27.2338 8.90017 27.2338 11.0011 27.2338H12.5" stroke="#1E2330" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          Copy
        </button>
        </div>
          <div
            className={click ? "click-magic1 copied1" : "click-magic1 copied"}
          >
            Endpoint copied to clipboard 👏
          </div>
        </div>

      ) : click && click1 && !click2 && !click3 && !click4 && !click5 ? (
        <div className="header-img1">
          <img className="content-img" src={img4} alt=""/>
          <button className="pastebutton" onClick={(e) => setClick2(true)}>
            "click to paste here"
          </button>
        </div>


      ) : click && click1 && click2 && !click3 && !click4 && !click5 ? (
         <div  className="header-img1" >
            <img className="content-img" src={img5} alt=""  />
            <div className="click-magic1" onClick={setStart1}>Pasted into the code 🔥</div>
          </div>    
      ) : click && click1 && click2 && click3 && !click4 && !click5 ? (
        <div className="header-img1">
          <img
            className="content-img1"
            onClick={(e) => setClick4(true)}
            src={img6}
            alt=""
          />
          <div className="div1">
          <div className="click-magic">Click to fill form ✍️</div>
          <svg
            className="arrow"
            width="118"
            height="98"
            viewBox="0 0 118 98"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_2671_6218)">
              <path
                className="squiggle1"
                fill="none"
                stroke="black"
                stroke-miterlimit="1"
                stroke-width="1"
                d="M45.2014 43.6377C51.1691 28.8827 68.4646 21.7379 91.4672 24.3269C88.5711 27.2798 82.7705 25.9182 81.7586 30.886C82.2019 31.4465 82.4662 32.0236 82.7032 32.0497C89.0438 31.2611 95.3998 30.5735 101.644 29.5291C102.146 29.4864 102.629 29.3134 103.043 29.0272C103.458 28.741 103.79 28.3516 104.008 27.8973C104.226 27.4431 104.322 26.9398 104.285 26.4374C104.249 25.9349 104.082 25.4506 103.801 25.0324C101.155 20.778 98.63 16.3379 93.7347 12.6778L93.217 18.9474C86.5164 18.7767 79.8866 18.0097 73.3409 18.4683C57.9429 19.5581 45.9104 26.49 38.9171 40.7628C37.8159 43.0253 36.2823 43.6794 34.0461 44.2184C29.6051 45.1479 25.4625 47.1602 21.9864 50.0763C18.5104 52.9924 15.8087 56.7222 14.1213 60.934C12.7553 64.1658 11.4482 67.7712 14.9479 71.9521C15.0291 69.0026 15.6913 66.0981 16.8964 63.4049C18.1016 60.7116 19.8261 58.2825 21.9713 56.2565C26.0411 52.2737 30.6143 49.3031 36.3292 48.9037C38.2541 53.7487 39.5358 58.5993 42.0076 62.7255C44.5522 66.9199 47.7456 70.6844 51.469 73.8789C53.1153 75.3419 55.2294 76.1699 57.4314 76.2142C59.6334 76.2586 61.7791 75.5163 63.483 74.1208C66.8143 71.5124 67.8802 67.1894 65.9793 62.4084C63.056 55.1409 57.4744 49.2596 50.3695 45.9607C48.6845 45.0525 46.9251 44.3799 45.2014 43.6377ZM43.8407 49.0592C47.4272 50.0726 50.755 51.8428 53.5997 54.2507C56.4444 56.6586 58.7401 59.6482 60.332 63.0181C61.4193 65.1973 62.1268 67.6285 59.6566 69.3385C57.5362 70.7993 55.4683 69.656 53.8711 68.2041C48.3738 63.1999 44.8347 56.4041 43.8862 49.0309L43.8407 49.0592Z"
              />
            </g>
            <defs>
              <clipPath id="clip0_2671_6218">
                <rect
                  width="109.089"
                  height="47.5432"
                  fill="white"
                  transform="matrix(0.849147 -0.528156 -0.528156 -0.849147 25.1094 97.9873)"
                />
              </clipPath>
            </defs>
          </svg>
          </div>
        </div>



      ) : click && click1 && click2 && click3 && click4 && !click5 ? (
        <div className="header-img1">
          <img
            className="content-img"
            onClick={(e) => setClick5(true)}
            src={img7}
            alt=""
          />
         <div className="div2">
         <div className="click-magic">Click to Submit ✅</div>
          <svg
            className="arrow-img"
            width="108"
            height="114"
            viewBox="0 0 108 114"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_2671_6214)">
              <path
                className="squiggle2"
                stroke="black"
                stroke-miterlimit="1"
                stroke-width="1"
                d="M68.5421 57.8895C67.1907 73.7481 52.7712 85.6753 30.028 89.9846C31.9245 86.3089 37.8687 85.8994 37.3707 80.8542C36.7819 80.4493 36.3592 79.9758 36.1249 80.0208C30.2988 82.644 24.4282 85.1754 18.7693 88.0147C18.3022 88.2035 17.8925 88.5109 17.5807 88.9067C17.269 89.3024 17.0659 89.7727 16.9917 90.271C16.9174 90.7692 16.9746 91.2783 17.1575 91.7477C17.3403 92.2171 17.6426 92.6307 18.0343 92.9474C21.8174 96.2323 25.5395 99.7305 31.2964 101.784L29.9423 95.6407C36.3953 93.828 42.9564 92.6059 49.0759 90.2375C63.4678 84.6556 72.9212 74.4838 75.3947 58.7835C75.7798 56.2968 77.0523 55.2196 79.0302 54.0451C82.9996 51.8474 86.3647 48.703 88.8262 44.8916C91.2877 41.0801 92.7695 36.7195 93.1399 32.1974C93.4921 28.7065 93.678 24.876 89.101 21.9129C89.8931 24.7552 90.1169 27.7258 89.7595 30.6547C89.4021 33.5836 88.4706 36.4133 87.0183 38.9817C84.3038 43.9875 80.81 48.1746 75.467 50.2414C72.1989 46.1795 69.5439 41.9225 65.9653 38.7086C62.297 35.4511 58.1355 32.7956 53.6357 30.8412C51.6312 29.9287 49.3669 29.7609 47.2498 30.3679C45.1326 30.9748 43.3012 32.3168 42.0845 34.1527C39.6705 37.6275 39.9268 42.0726 43.153 46.0804C48.0894 52.1628 55.1571 56.1366 62.9188 57.1937C64.7967 57.5647 66.6762 57.6886 68.5421 57.8895ZM68.2437 52.3078C64.5177 52.3971 60.816 51.6868 57.3877 50.2249C53.9594 48.7629 50.8842 46.5832 48.3694 43.8326C46.6879 42.0709 45.2949 39.9565 47.151 37.5941C48.7464 35.573 51.0594 36.0557 53.0137 36.972C59.7423 40.1327 65.128 45.5827 68.2085 52.3483L68.2437 52.3078Z"
                fill="#1E2330"
              />
            </g>
            <defs>
              <clipPath id="clip0_2671_6214">
                <rect
                  width="109.089"
                  height="47.5432"
                  fill="white"
                  transform="matrix(-0.655647 0.755068 0.755068 0.655647 71.7109 0.0322266)"
                />
              </clipPath>
            </defs>
          </svg>
         </div>
        </div>
      ) : click && click1 && click2 && click3 && click4 && click5 ? (
        <div className="header-img1">
          <img className="content-img1" src={img8} alt="" />
        </div>
      ) : undefined}
    </div>
  );
}

export default HeaderLayout;
