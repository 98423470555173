import { createContext, useCallback, useContext, useReducer } from "react";
import { useRequest } from "services/request";

const Context = createContext(null);

export const useApi = () => {
  return useContext(Context);
};

const initState = {
  list: [],
  selected: null,
  status: "idle",
  errors: [],
  count: 0,
  perPage: 10,
  page: 0,
};

function reducer(state, action) {
  switch (action.type) {
    case "set_list":
      return { ...state, list: [...action.payload] };
    case "add":
      const newList = [{ ...action.payload }, ...state.list];
      return { ...state, list: newList };
    case "edit":
      const modified = state.list.map((p) =>
        p.id === action.payload.id ? { ...p, ...action.payload } : p
      );
      return { ...state, list: modified };
    case "delete":
      const filtered = state.list.filter((p) => p.id !== action.payload);
      return { ...state, list: filtered };
    case "set_selected":
      return { ...state, selected: { ...action.payload } };
    case "set_count":
    case "set_status":
    case "set_page":
    case "set_perPage":
    case "set_errors":
      return { ...state, [action.type.split("_")[1]]: action.payload };
    default:
      throw new Error(
        `Action ${action.type} is not available in useApi context`
      );
  }
}

export default function ApiProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initState);
  const req = useRequest();

  const fetchList = useCallback(
    ({ page = 0, perPage = 10 }) =>
      new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: "set_status", payload: "fetching" });
          const query = `page=${page + 1}&limit=${perPage}`;
          const res = await req("auth/api-key?" + query, null, {}, true);
          dispatch({ type: "set_list", payload: res });
          resolve(res);
        } catch (e) {
          console.log(e);
          reject(e);
        } finally {
          dispatch({ type: "set_status", payload: "idle" });
        }
      }),
    [req]
  );

  const create = useCallback(
    (name) =>
      new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: "set_status", payload: "creating" });
          const res = await req(
            "auth/api-key",
            { name },
            { method: "POST" },
            true
          );
          dispatch({ type: "add", payload: res });
          resolve(null);
        } catch (e) {
          console.log(e);
          reject(e);
        } finally {
          dispatch({ type: "set_status", payload: "idle" });
        }
      }),
    [req]
  );

  const edit = useCallback(
    ({ id, data }) =>
      new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: "set_status", payload: "updating_" + id });
          const res = await req(
            "auth/api-key/" + id,
            data,
            { method: "PATCH" },
            true
          );
          dispatch({ type: "edit", payload: res });
          resolve(null);
        } catch (e) {
          console.log(e);
          reject(e);
        } finally {
          dispatch({ type: "set_status", payload: "idle" });
        }
      }),
    [req]
  );

  const remove = useCallback(
    (id) =>
      new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: "set_status", payload: "deleting_" + id });
          await req("auth/api-key/" + id, null, { method: "DELETE" }, true);
          dispatch({ type: "delete", payload: id });
          resolve(null);
        } catch (e) {
          console.log(e);
          reject(e);
        } finally {
          dispatch({ type: "set_status", payload: "idle" });
        }
      }),
    [req]
  );

  return (
    <Context.Provider
      value={{ state, dispatch, fetchList, create, remove, edit }}
    >
      {children}
    </Context.Provider>
  );
}
