// import facebookIcon from "../../assests/facebook_icon.svg";
import googleIcon from "../../assests/google_icon.svg";
import { Box, Flex, Type } from "ui";
import { useAuth } from "services/auth";
import { useEffect, useState } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import { useLocation, useNavigate } from "react-router-dom";

export default function SocialMediaLogin() {
  let { signinByGoogle } = useAuth();
  const [user, setUser] = useState(null);

  const login = useGoogleLogin({
    flow: "auth-code",
    onSuccess: (codeResponse) => {
      console.log("codeResponse", codeResponse);
      setUser(codeResponse);
    },
    onError: (error) => console.log("Login Failed:", error),
  });

  const paramsString = window.location.search;
  let searchParams = new URLSearchParams(paramsString);
  let navigate = useNavigate();
  let location = useLocation();

  let from =
    location.state?.from?.pathname ||
    (searchParams.has("from") && searchParams.get("from")) ||
    "/dashboard";

  useEffect(() => {
    if (user) {
      signinByGoogle(user.code);

      navigate(from, { replace: true });
    }
  }, [user, signinByGoogle, from, navigate]);

  return (
    <>
      <Box
        sx={{ w: [370,"_","_","90%"], bgc: "white", br: 10, mx: "auto", mt: 20 }}
        style={{
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        {/* <Flex
          gap={10}
          style={{
            padding: "10px",
            backgroundColor: "#F4F7FE",
            width: "100%",
            marginBottom: "10px",
            borderRadius: "5px",
          }}
        >
          <button onClick={login}>Sign in with Google 🚀 </button>
        </Flex> */}

        <Flex
          gap={7}
          style={{
            padding: "10px",
            backgroundColor: "#F4F7FE",
            width: "100%",
            marginBottom: "25px",
            borderRadius: "5px",
            cursor:"pointer",
          }}
          onClick={login}
        >
          <img
            style={{ width: "20px", height: "20px" }}
            alt=""
            src={googleIcon}
          />
          <Type var="h5">Continue With Google</Type>
        </Flex>
        {/* <Flex
          gap={7}
          style={{
            padding: "10px",
            backgroundColor: "#F4F7FE",
            width: "100%",
            marginBottom: "25px",
            borderRadius: "5px",
            cursor:"pointer",
          }}
        >
          <img
            style={{ width: "20px", height: "20px" }}
            alt=""
            src={facebookIcon}
          />
          <Type var="h5">Continue With Facebook</Type>
        </Flex> */}
      </Box>
      <div
        style={{
          borderBottom: "#efefef 1px solid",
          marginBottom: "50px",
          marginTop: "10px",
          position: "relative",
          width: "100%",
        }}
      >
        <p
          style={{
            width: "160px",
            fontSize: "12px",
            textAlign: "center",
            backgroundColor: "white",
            top: "-21px",
            margin: "0 auto -11px auto",
            padding: 5,
          }}
        >
          Or Sign up with email
        </p>
      </div>
    </>
  );
}
