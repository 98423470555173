import { useCallback, useEffect, useState } from "react";

import {
  FlexStyled,
  StyledBox,
  AlignedButton,
  SecondRowItem,
} from "../../../Components";

import {
  Button,
  Flex,
  InputRadio,
  InputText,
  Link,
  Select,
  Type,
} from "ui";
import { useProfile } from "views/Account/provider";
import formal from "assests/formal.svg";

function Processing({ edit, selectedForm ,notify}) {
  const [emailNotifications, setEmailNotifications] = useState(
    selectedForm?.enableNotification
  );
  const [autoReply, setAutoReply] = useState(false);
  const {state:{selected:selectedUser},fetchProfile} = useProfile();
  const [redirectUrl, setRedirectUrl] = useState(selectedForm?.redirectURL);
  const [error, setError] = useState([]);
  const [ customEmail ,setCustomEmail ] = useState (false);

  useEffect(()=>{
    fetchProfile();
    setRedirectUrl(selectedForm?.redirectURL)
  },[selectedForm,fetchProfile])

  const handleRedirectUrlChange = useCallback((event) => {
    setRedirectUrl(event.target.value);
  }, []);

  function isValidUrl(string) {
    let url;
    
    try {
      url = new URL(string);
    } catch (_) {
      return false;  
    }
  
    return url.protocol === "http:" || url.protocol === "https:";
  }

  const handleEdit =async(e) => {  
    e.preventDefault();
      const data = {
        enableNotification: emailNotifications,
      };
    if (isValidUrl(redirectUrl)||redirectUrl===""||redirectUrl==null){
      redirectUrl!==""?data.redirectURL=redirectUrl:data.redirectURL=null 
      try {
        await edit(data);
        setError([])
      } catch (e) {
        setError(e.data.message)
      }
    }else{
      setError(['enter a valid url'])
    }


  };

  return (
    <>
      <FlexStyled>
        <InputRadio
          toggleButton={true}
          checked={emailNotifications}
          inputProps={{
            checked: emailNotifications,
            onChange: () => setEmailNotifications(!emailNotifications),
          }}
        />
        <StyledBox>
          <Type>Email Notifications</Type>
          <Type variant="subtitle">
            Enable or disable sending notification emails. Submissions will
            still be saved to the archive and dispatched to plugins.
          </Type>
        </StyledBox>
      </FlexStyled>

      <FlexStyled>
        <span />
        <StyledBox>
          <Type>Email notification template</Type>
          <Type variant="subtitle">Customize email template</Type>
        </StyledBox>
        <SecondRowItem>
          <Flex dir="column" sx={{ai:["flex-end","_","_","flex-start"]}} gap={10}>
            <Type>Selected Theme</Type>
            <Flex
              dir="column"
              gap="2px"
              sx={{
                br: 10,
                px: 20,
                py :10,
                bgc: "babyBlue",
              }}
            >
              <img src={formal} alt=""/>
              <Type>Formal</Type>
            </Flex>
            <Button 
            size="small" 
            iconName="Customize"
            onClick={()=>{setCustomEmail(!customEmail)}} >
              Customize
            </Button>
          </Flex>
        </SecondRowItem>
      </FlexStyled>

      <FlexStyled>
        <span />
        <StyledBox>
          <Type>Target Email</Type>
          <Type variant="subtitle">
            Where to send submissions. To add a new email address, visit
            the <Link to = '/account'>account page.</Link>
          </Type>
        </StyledBox>
        <SecondRowItem>
          <Select
              data={selectedUser?.emails}
              mapData={{ value: "id", label: "email" }}
              name="target emails"
              size="medium"
              search={false}
              sx={{
                w:"250px",
              }}
            />
        </SecondRowItem>
      </FlexStyled>

      <FlexStyled>
        <span />
        <StyledBox>
          <Type>Redirect</Type>
          <Type variant="subtitle">
            After successful submit, where should the user be redirected? 
          </Type>
        </StyledBox>
        <SecondRowItem>
        <InputText
          type="text"
          value={redirectUrl}
          placeholder={selectedForm?.redirectURL}
          size="medium"
          sx={{
            w: "250px",
            mb: "0px",
          }}
          onChange={handleRedirectUrlChange}
        />
        </SecondRowItem>
      </FlexStyled>

      <FlexStyled style={{ justifyContent: "flex-start" }}>
        <InputRadio
          toggleButton={true}
          checked={autoReply}
          inputProps={{
            checked: autoReply,
            onChange: () => setAutoReply(!autoReply),
          }}
        />
        <StyledBox>
          <Type>Auto reply</Type>
          <Type variant="subtitle">
            Send a customized email to respondents after successful form
            submission
          </Type>
        </StyledBox>
      </FlexStyled>
      <AlignedButton onClick={(e) => handleEdit(e)}>save</AlignedButton>
      <Flex
        role="alert"
        sx={{
          d: error.length ? "block" : "none",
          p: 5,
          bgc: "redBg",
          w: "100%",
          border: ["red", 10, "solid", 5, "r"],
          my: 10,
        }}
      >
        {error.map((err,index) => (
          <Type key={index}>{err}</Type>
        ))}
      </Flex>
    </>
  );
}
export default Processing;
