import styled, { css } from "styled-components";
import Box from "./Box";
import isPropValid from "@emotion/is-prop-valid";

export default styled(Box).withConfig({
  shouldForwardProp: (prop) => isPropValid(prop),
})`
  display: grid;
  ${(props) => {
    if (!props.cols) return null;
    if (typeof props.cols === "string") {
      return css`
        grid-template-columns: ${props.cols};
      `;
    } else {
      return css`
        grid-template-columns: ${props.cols[0]};
        @media only screen and (max-width: 1200px) {
          grid-template-columns: ${props.cols[1]};
        }
        @media only screen and (max-width: 992px) {
          grid-template-columns: ${props.cols[2]};
        }
        @media only screen and (max-width: 768px) {
          grid-template-columns: ${props.cols[3]};
        }
        /* grid-template-columns: ${props.cols}; */
      `;
    }
  }}
  ${(props) => {
    if (!props.rows) return null;
    if (typeof props.cols === "string") {
      return css`
        grid-template-rows: ${props.rows};
      `;
    } else {
      return css`
        grid-template-rows: ${props.rows[0]};
        @media only screen and (max-width: 1200px) {
          grid-template-rows: ${props.cols[1]};
        }
        @media only screen and (max-width: 992px) {
          grid-template-rows: ${props.rows[2]};
        }
        @media only screen and (max-width: 768px) {
          grid-template-rows: ${props.rows[3]};
        }
        /* grid-template-columns: ${props.cols}; */
      `;
    }
  }}
    ${(props) =>
    props.rowGap &&
    css`
      row-gap: ${props.rowGap}px;
    `};
  ${(props) =>
    props.colGap &&
    css`
      column-gap: ${props.colGap}px;
    `};
  ${(props) =>
    props.gap &&
    css`
      gap: ${props.gap}px;
    `};
`;
