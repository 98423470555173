import { createContext, useCallback, useContext, useReducer } from "react";
import { useRequest } from "services/request";

const Context = createContext();

export function useSubmission() {
  return useContext(Context);
}

const initState = {
  list: [],
  statistics: [],
  totals: null,
  parentId: null,
  status: "idle",
  error: null,
  count: 0,
  page: 0,
  perPage: 10,
};

function reducer(state, action) {
  switch (action.type) {
    case "set_list":
      return { ...state, list: [...action.payload] };
    case "set_totals":
      return { ...state, totals: { ...action.payload } };
    case "set_statistics":
      return { ...state, statistics: [...action.payload] };
    case "set_error":
    case "set_status":
    case "set_count":
    case "set_page":
    case "set_perPage":
    case "set_parentId":
      return { ...state, [action.type.split("_")[1]]: action.payload };

    default:
      throw new Error(`Invalid dispatch type: ${action.type}`);
  }
}

export default function SubmissionPRovider({ children }) {
  const [state, dispatch] = useReducer(reducer, initState);
  const req = useRequest();

  const fetchStats = useCallback(
    async ({ periodType = "week", startDate, endDate, formId, clientId }) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "set_status", payload: "fetching" });
        const data = {
          statisticsPeriodType: periodType,
          startDate,
          endDate,
        };
        if (formId) data.formId = formId;
        if (clientId) data.clientId = clientId;
        try {
          const query = new URLSearchParams(data);
          const resData = await req(`s/statistics?${query}`, null, {}, true);
          dispatch({
            type: "set_statistics",
            payload: resData,
          });
          resolve(resData);
        } catch (error) {
          reject(error);
          console.log(error);
        } finally {
          dispatch({ type: "set_status", payload: `idle` });
        }
      });
    },
    [req]
  );

  const fetchTotals = useCallback(
    async ({
      formId,
      projectId,
      thisMonth = true,
      monthlyRate = true,
      allTime = true,
      since,
    }) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "set_status", payload: "fetching" });

        const data = {
          thisMonth,
          monthlyRate,
          allTime,
        };
        if (since && Date.parse(since)) data.since = since;

        try {
          const query = new URLSearchParams(data);
          const resData = await req(
            `s/project/${projectId}/form/${formId}/submission/count?${query}`,
            null,
            {},
            true
          );
          dispatch({
            type: "set_totals",
            payload: resData,
          });
          resolve(resData);
        } catch (error) {
          reject(error);
          console.log(error);
        } finally {
          dispatch({ type: "set_status", payload: `idle` });
        }
      });
    },
    [req]
  );

  return (
    <Context.Provider
      value={{
        state,
        dispatch,
        fetchStats,
        fetchTotals,
      }}
    >
      {children}
    </Context.Provider>
  );
}
